import { useMemo } from 'react';
import classNames from 'classnames';

import { Select } from '@/components/form/select';
import { SearchInput } from '@/components/form/search-input';

import { Fields, Grouping, timeAggregationPeriodZod } from '../../types';
import { getGroupableFields, timePrecisionOptions } from '../../utils/grouping';
import { fieldToOption } from '../../edit-pipeline/utils';

import styles from './grouping-select.module.scss';

type GroupingSelectProps = {
  grouping?: Grouping;
  groupings: Grouping[];
  fields: Fields;
  onChange: (grouping: Grouping) => void;
  autoFocus?: boolean;
  fullWidth?: boolean;
};

export const GroupingSelect = ({
  grouping,
  groupings,
  fields,
  autoFocus,
  fullWidth = false,
  onChange,
}: GroupingSelectProps) => {
  const handleFieldKeyChange = (fieldKey: string) => {
    const field = fields.find((f) => f.key === fieldKey);

    if (field === undefined) {
      return;
    }

    field.type === 'Date'
      ? onChange({
          key: fieldKey,
          precision: grouping?.precision ?? 'month',
        })
      : onChange({
          key: fieldKey,
        });
  };

  const handlePrecisionChange = (precision: string) => {
    if (grouping === undefined) {
      return;
    }

    onChange({
      ...grouping,
      precision: timeAggregationPeriodZod.parse(precision),
    });
  };

  const options = useMemo(
    () =>
      getGroupableFields(
        fields,
        groupings.filter(({ key }) => key !== grouping?.key),
      ).map(fieldToOption),
    [fields, groupings, grouping],
  );

  return (
    <div className={classNames(styles.groupingSelect, { [styles.fullWidth]: fullWidth })}>
      <SearchInput
        options={options}
        value={grouping?.key}
        onChange={handleFieldKeyChange}
        autoFocus={autoFocus}
      />
      {grouping?.precision ? (
        <div>
          <Select
            options={timePrecisionOptions}
            value={grouping.precision}
            onChange={handlePrecisionChange}
            fullWidth={fullWidth}
          />
        </div>
      ) : null}
    </div>
  );
};
