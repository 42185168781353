import { isEqual } from 'lodash';

import { Visualisation } from '@/explore/types';

export const regenerateVisualisationIfNotDirty = ({
  visualisation,
  defaultVisualisation,
  nextDefaultVisualisation,
}: {
  visualisation: Visualisation;
  defaultVisualisation: Visualisation;
  nextDefaultVisualisation: Visualisation;
}): Visualisation => {
  const isDirty = !isEqual(visualisation, defaultVisualisation);

  if (!isDirty) {
    return nextDefaultVisualisation;
  }

  return visualisation;
};
