import { ForwardedRef, forwardRef } from 'react';
import classNames from 'classnames';

import { IconButton } from '@/components/button';

import { useExplorationCellContext } from '../exploration-cell-context';

import * as style from './collapsible-cell.module.scss';

interface CollapsibleContainerProps {
  className?: string;
  children: React.ReactNode;
}

export const CollapsibleContainer = forwardRef(function CollapsibleContainer(
  props: CollapsibleContainerProps,
  ref?: ForwardedRef<HTMLDivElement | null>,
) {
  const { isCollapsed } = useExplorationCellContext();

  return (
    <div
      className={classNames(props.className, style.collapsibleContainer, {
        [style.collapsed]: isCollapsed,
      })}
      ref={ref}>
      {props.children}
    </div>
  );
});

export const CollapseButton = () => {
  const { isCollapsed, toggleCollapsed } = useExplorationCellContext();

  const handleCollapseClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    toggleCollapsed();
  };

  return (
    <IconButton
      icon="ChevronUp"
      title={isCollapsed ? 'Expand' : 'Collapse'}
      className={style.collapseButton}
      onClick={handleCollapseClick}
    />
  );
};

type CollapsibleContentProps = { children: React.ReactNode };

export const CollapsibleContent = (props: CollapsibleContentProps) => (
  <div className={style.collapsibleContent}>
    <div>{props.children}</div>
  </div>
);
