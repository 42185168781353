import { useMetricsQuery, timePrecisionToGql } from '@/graphql';

import { MetricTile } from '../../components/metric-tile';
import {
  ExplorationParameters,
  Metric,
  MetricCell,
  booleanParameter,
  dateRangeParameter,
} from '../types';

import { Loader } from '../../components/loader';

import styles from './metrics-cell.module.scss';

interface MetricCellViewProps {
  cell: MetricCell;
  accountId: string;
  parameters: ExplorationParameters;
}

export const MetricCellView = (props: MetricCellViewProps) => {
  const { cell, accountId, parameters } = props;

  const dateRange = dateRangeParameter.parse(parameters['date_range']);
  const isCumulative = booleanParameter.parse(parameters['is_cumulative']);

  const { precision, start, end } = dateRange;

  const { data, error, loading } = useMetricsQuery({
    variables: { accountId, aggregation: timePrecisionToGql(precision) },
    nextFetchPolicy: 'cache-first',
  });

  const metrics = data?.account?.metrics ?? [];

  if (loading) {
    return <Loader />;
  }

  if (error) {
    <div>error: {JSON.stringify(error)}</div>;
  }

  const metric = metrics.find((m) => m.metricId === cell.metricId);

  return (
    <div className={styles.metricCell}>
      <MetricTile
        key={cell.metricId}
        metric={metric as Metric}
        accountId={accountId}
        startDate={new Date(start)}
        endDate={new Date(end)}
        aggPeriod={precision}
        isCumulative={isCumulative}
        allMetrics={metrics as Metric[]}
        modelNames={[]}
      />
    </div>
  );
};
