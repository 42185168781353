import { MouseEventHandler, useRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { useBuildAccountUrl } from '@/lib/accounts/context';
import { NavigableListItemOutput } from '@/lib/hooks/use-navigable-list';
import { useFocus } from '@/lib/hooks/use-focus';
import { useEventListener } from '@/lib/hooks/use-event-listener';

import { Icon } from '../../components/icon';
import { DefaultHiddenLabels, formatLabels, isModel } from './utils';
import {
  buildModelExplorationUrl,
  buildSavedExplorationUrl,
  getExplorationType,
  getModelIdFromModelExploration,
} from '../utils';
import { ExplorationListItemData, ListItemData } from '.';
import { CellList } from './cell-list';

import styles from './exploration-search.module.scss';

interface ExplorationListProps {
  listItems: NavigableListItemOutput<ExplorationListItemData>[];
  hideSectionLabels?: boolean;
}

export const ExplorationList = (props: ExplorationListProps) => {
  const { listItems, hideSectionLabels } = props;
  return (
    <ol className={styles.explorationList}>
      {listItems.map((item, i) => (
        <li key={i}>
          <ListItem listItem={item} onClick={item.onClick} hideSectionLabel={hideSectionLabels} />
        </li>
      ))}
    </ol>
  );
};

interface ListItemProps {
  listItem: NavigableListItemOutput<ListItemData>;
  onClick?: MouseEventHandler;
  hideSectionLabel?: boolean;
}

const ListItem = (props: ListItemProps) => {
  const { listItem, onClick, hideSectionLabel = false } = props;
  const { getIsFocused, setIsFocused } = listItem;

  const buildAccountUrl = useBuildAccountUrl();

  const { exploration } = listItem.getData<ExplorationListItemData>();
  const { name, description, labels } = exploration;
  const type = getExplorationType(exploration);

  const url =
    type === 'model'
      ? buildAccountUrl(buildModelExplorationUrl(getModelIdFromModelExploration(exploration)))
      : buildAccountUrl(buildSavedExplorationUrl(exploration.explorationId, null));

  const ref = useRef<HTMLAnchorElement>(null);

  useFocus(getIsFocused() ?? false, ref);
  useEventListener('focus', () => setIsFocused(true), ref);

  const handleMouseUp = () => {
    // Temp fix for both hover & focus existing side by side. Expanding an item with the mouse would leave it focused.
    ref.current?.blur();
  };

  return (
    <>
      <Link
        to={url}
        className={styles.listItem}
        onClick={onClick}
        onMouseUp={handleMouseUp}
        ref={ref}>
        <Icon name={type === 'model' ? 'Model' : 'Exploration'} size={32} className={styles.icon} />
        <div className={styles.name}>{name}</div>
        {description !== undefined && (
          <div className={styles.description} title={description ?? undefined}>
            {description}
          </div>
        )}
        {isModel(labels) ? (
          <ul className={styles.labels}>
            {formatLabels(
              labels,
              hideSectionLabel ? [...DefaultHiddenLabels, 'section'] : DefaultHiddenLabels,
            ).map((label, idx) => (
              <li key={idx}>{label}</li>
            ))}
          </ul>
        ) : (
          <div className={styles.sectionCount}>Blocks: {exploration.view.cells.length}</div>
        )}
        {listItem.getChildren().length > 0 && (
          <div className={styles.expandIcon}>
            <Icon name={listItem.getIsCollapsed() ? 'ChevronRight' : 'X'} size={24} />
          </div>
        )}
      </Link>
      {listItem.getChildren().length > 0 && (
        <div
          className={classNames(styles.cellList, {
            [styles.collapsed]: listItem.getIsCollapsed(),
          })}
          inert={listItem.getIsCollapsed() ? '' : undefined}>
          <CellList exploration={exploration} listItems={listItem.getChildren()} />
        </div>
      )}
    </>
  );
};
