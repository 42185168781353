import { z } from 'zod';
import { omitBy } from 'lodash';

import { Exploration, MetricV2, Model, Pipeline, RecordsCell, Relation } from '@/explore/types';
import { getExplorationVariables, restoreInvalidOperation } from '@/explore/utils';
import { dereferencePipeline, formatRelationLabel } from '@/explore/pipeline/utils';
import { getFinalStateOrThrow } from '@/explore/pipeline/state';
import { getCellByPipelineIdOrThrow, isRecordsCell } from '@/explore/exploration/utils';
import { getJoinKeys, getModelOrThrow } from '@/explore/model/utils';
import { Option } from '@/components/form/types';

import { isRecursiveOperation } from '.';

const optionValueSchema = z.object({
  relationKey: z.string().optional(),
  pipelineId: z.string().optional(),
});

export const constructRelationOptionValue = (
  relationKey: string | undefined,
  pipelineId: string | undefined,
) => JSON.stringify(omitBy({ relationKey, pipelineId }, (value) => value === undefined));

export const deconstructRelationOptionValue = (pipelineRelationKey: string) => {
  return optionValueSchema.parse(JSON.parse(pipelineRelationKey));
};

export const parseRelationOptionValue = (model: Model, value: string) => {
  const { relationKey, pipelineId } = deconstructRelationOptionValue(value);
  const selectedRelation = model.relations.find(({ key }) => key === relationKey);
  return { selectedRelation, pipelineId };
};

export const getValidRelationTypesForOperation = (
  operation: 'relationAggregate' | 'addRelatedColumn' | undefined,
) => {
  switch (operation) {
    case 'relationAggregate':
      return ['hasMany', 'manyToMany'];
    case 'addRelatedColumn':
      return ['hasOne', 'hasOneThrough'];
    default:
      return ['hasMany', 'manyToMany', 'hasOne', 'hasOneThrough'];
  }
};

const getCellFinalState = (
  cell: RecordsCell,
  exploration: Exploration,
  models: Model[],
  metrics: MetricV2[],
) => {
  const fullPipeline = dereferencePipeline(cell.pipeline, exploration);
  return getFinalStateOrThrow(fullPipeline.baseModelId, fullPipeline.operations, {
    models,
    variables: getExplorationVariables(exploration),
    metrics,
  });
};

const pipelineContainsReference = (
  pipelineId: string,
  pipeline: Pipeline,
  exploration: Exploration,
): boolean => {
  return (
    ('parentId' in pipeline && pipeline.parentId === pipelineId) ||
    pipeline.operations.some((operation) => {
      operation = restoreInvalidOperation(operation);
      if (!isRecursiveOperation(operation) || operation.parameters.pipelineId === undefined) {
        return false;
      }
      if (operation.parameters.pipelineId === pipelineId) {
        return true;
      }
      const cell = getCellByPipelineIdOrThrow(operation.parameters.pipelineId, exploration);
      return (
        cell !== undefined &&
        'pipeline' in cell &&
        pipelineContainsReference(pipelineId, cell.pipeline, exploration)
      );
    })
  );
};

/**
 * For a given cell get the matching relations for the current model.
 */
const findRelationsForCell = (
  model: Model,
  models: Model[],
  metrics: MetricV2[],
  cell: RecordsCell,
  exploration: Exploration,
) => {
  const finalState = getCellFinalState(cell, exploration, models, metrics);
  return model.relations
    .filter((relation) => relation.modelId === finalState.model.modelId)
    .filter((relation) => {
      const joinKeys = getJoinKeys(relation, model, models);
      return finalState.fields.some(({ key }) => key === joinKeys?.joinKeyOnRelated);
    });
};

/**
 * Create select field option for a normal relation.
 */
export const createRelationOption = (
  relation: Relation,
  baseModel: Model,
  models: Model[],
): Option => ({
  value: constructRelationOptionValue(relation.key, undefined),
  label: formatRelationLabel({
    relationName: relation.name,
    relatedModelName: getModelOrThrow(models, relation.modelId).name,
    baseModelName: baseModel.name,
  }),
});

/**
 * Create select field options for normal relations.
 */
export const createRelationOptions = (
  relations: Relation[],
  baseModel: Model,
  models: Model[],
): Option[] => relations.map((relation) => createRelationOption(relation, baseModel, models));

/**
 * Create select field option for a cell.
 */
const createOnThisPageRelationOption = (
  relation: Relation,
  relations: Relation[],
  cell: RecordsCell,
  currentCell: RecordsCell,
  exploration: Exploration,
) => {
  if (cell.pipeline.pipelineId === undefined) {
    throw new Error(`Unable to create On This Page relation: cell does not have a pipelineId`);
  }

  const isCircularReference =
    'pipelineId' in currentCell.pipeline &&
    currentCell.pipeline.pipelineId !== undefined &&
    pipelineContainsReference(currentCell.pipeline.pipelineId, cell.pipeline, exploration);

  return {
    value: constructRelationOptionValue(relation.key, cell.pipeline.pipelineId),
    label:
      relations.length === 1
        ? (cell.title ?? '(Untitled)')
        : `${cell.title ?? '(Untitled)'} as ${relation.name}`,
    disabled: isCircularReference,
    title: isCircularReference
      ? 'Adding data from this table would create a circular reference'
      : '',
  };
};

const createCustomRelationOption = (
  cell: RecordsCell,
  currentCell: RecordsCell,
  exploration: Exploration,
) => {
  if (cell.pipeline.pipelineId === undefined) {
    throw new Error(`Unable to create On This Page relation: cell does not have a pipelineId`);
  }

  const isCircularReference =
    'pipelineId' in currentCell.pipeline &&
    currentCell.pipeline.pipelineId !== undefined &&
    pipelineContainsReference(currentCell.pipeline.pipelineId, cell.pipeline, exploration);

  return {
    value: constructRelationOptionValue(undefined, cell.pipeline.pipelineId),
    label: cell.title ?? '(Untitled)',
    disabled: isCircularReference,
    title: isCircularReference
      ? 'Adding data from this table would create a circular reference'
      : '',
  };
};

export const getOnThisPageRelationOptions = (
  model: Model,
  models: Model[],
  metrics: MetricV2[],
  exploration: Exploration,
  currentCell: RecordsCell,
) =>
  exploration.view.cells.filter(isRecordsCell).reduce(
    (acc, cell) => {
      const relations = findRelationsForCell(model, models, metrics, cell, exploration);

      return acc.concat(
        relations.map((relation) =>
          createOnThisPageRelationOption(relation, relations, cell, currentCell, exploration),
        ),
      );
    },
    [] as { value: string; label: string }[],
  );

export const getCustomRelationOptions = (exploration: Exploration, currentCell: RecordsCell) =>
  exploration.view.cells.reduce<{ value: string; label: string }[]>((acc, cell) => {
    if (isRecordsCell(cell) && cell.id !== currentCell.id) {
      acc.push(createCustomRelationOption(cell, currentCell, exploration));
      return acc;
    }
    return acc;
  }, []);
