import { useState } from 'react';
import classNames from 'classnames';

import {
  AccountSettingsDocument,
  UserRole,
  useDeleteUserFromAccountMutation,
  useUpdateUserInAccountMutation,
} from '@/graphql';

import { useToastContext } from '../../components/toast';
import { ConfirmationModal } from '../../components/confirmation-modal';
import { Button } from '../../components/button';
import { Icon } from '../../components/icon';
import { Modal } from '../../components/modal';
import { Panel } from '../../components/panel';
import { Input } from '../../components/form/input';
import { RoleSelect } from './role-select';
import { mapUserRole } from './utils';

import formStyles from '../../components/form/form.module.scss';
import styles from './user-form.module.scss';

interface EditFormProps {
  accountId: string;
  user: {
    userId: string;
    email: string;
    role: { roleId: string };
  };
  enableDelete: boolean;
  onClose: () => void;
}

export const EditForm = (props: EditFormProps) => {
  const toast = useToastContext();
  const [role, setRole] = useState<UserRole>(mapUserRole(props.user.role.roleId));
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [deleteUser, deleteState] = useDeleteUserFromAccountMutation({
    refetchQueries: [AccountSettingsDocument],
  });
  const [updateUser, updateState] = useUpdateUserInAccountMutation({
    refetchQueries: [AccountSettingsDocument],
  });

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      await updateUser({
        variables: { accountId: props.accountId, userId: props.user.userId, role },
      });

      toast({
        kind: 'success',
        title: 'User updated',
        content: () => (
          <div>
            User <b>{props.user.email}</b> has been updated
          </div>
        ),
      });

      props.onClose();
    } catch {
      toast({
        kind: 'error',
        title: 'Error updating user',
        content: () => (
          <div>
            An unknown error occurred while updating user <b>{props.user.email}</b>.
          </div>
        ),
      });
    }
  };

  const handleStartDeleting = () => setShowConfirmationModal(true);

  const handleStopDeleting = () => setShowConfirmationModal(false);

  const handleDelete = async () => {
    try {
      await deleteUser({
        variables: { accountId: props.accountId, userId: props.user.userId },
      });

      toast({
        kind: 'success',
        title: 'User deleted',
        content: () => (
          <div>
            User <b>{props.user.email}</b> has been deleted
          </div>
        ),
      });

      props.onClose();
    } catch {
      toast({
        kind: 'error',
        title: 'Error deleting user',
        content: () => (
          <div>
            An unknown error occurred while deleting user <b>{props.user.email}</b>.
          </div>
        ),
      });
    }
  };

  if (showConfirmationModal) {
    return (
      <ConfirmationModal
        title="Delete user"
        description={`Are you sure you want to delete user ${props.user.email}?`}
        submitLabel="Delete"
        onSubmit={handleDelete}
        onClose={handleStopDeleting}
        loading={deleteState.loading}
      />
    );
  }

  return (
    <Modal onClose={() => props.onClose()}>
      <Panel className={styles.userFormPanel}>
        <h1>Edit user</h1>

        <form
          className={classNames(formStyles.formHorizontal, formStyles.sparse)}
          onSubmit={handleSubmit}>
          <div className={classNames(formStyles.formRow, formStyles.rowVertical)}>
            <label className={formStyles.formLabel}>Email</label>
            <Input
              required
              autoFocus
              type="email"
              placeholder="Enter value..."
              value={props.user.email}
              size="regular"
              aria-required
              disabled
            />
          </div>

          <div className={classNames(formStyles.formRow, formStyles.rowVertical)}>
            <label className={formStyles.formLabel}>Role</label>
            <RoleSelect value={role} onChange={(value) => setRole(mapUserRole(value))} />
          </div>

          <div className={formStyles.formControls}>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              loading={updateState.loading}
              disabled={deleteState.loading}>
              Save
            </Button>

            <Button
              type="secondary"
              size="large"
              onClick={props.onClose}
              disabled={updateState.loading || deleteState.loading}>
              Cancel
            </Button>

            {props.enableDelete && (
              <Button
                size="large"
                type="outlined"
                color="warning"
                icon={<Icon name="Trash2" size={16} />}
                onClick={handleStartDeleting}
                loading={deleteState.loading}
                disabled={updateState.loading}
                className={formStyles.floatRight}>
                Delete
              </Button>
            )}
          </div>
        </form>
      </Panel>
    </Modal>
  );
};
