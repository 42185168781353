import { useState } from 'react';
import classNames from 'classnames';
import { isNil, first, omit, isEqual } from 'lodash';

import { common } from '@gosupersimple/types';

import { Form } from '@/components/form';
import { Button, IconButton, InlineButton } from '@/components/button';
import { TagSelect } from '@/components/form/tag-select';
import { Icon } from '@/components/icon';
import { Toggle } from '@/components/form/toggle';

import { SearchInput } from '@/components/form/search-input';

import { Option } from '@/components/form/types';

import type {
  Model,
  RelationAggregateOperation,
  AddRelatedColumnOperation,
  FilterCondition,
  Exploration,
  Fields,
  VariableDefinition,
  Aggregation,
  RecordsCell,
  MetricV2,
  Relation,
} from '../types';
import { dereferencePipeline } from '../pipeline/utils';
import {
  getCellByPipelineIdOrThrow,
  getCellIdByPipelineId,
  getPipelineById,
  isRecordsCell,
} from '../exploration/utils';
import { useExplorationContext } from '../exploration/exploration-context';

import { Operation } from './operation';
import { ensureLegalIdentifier, nameToKey } from './utils';
import { getCompositeConditionKeys, isKeyedAggregation } from '../pipeline/operation';
import { getPipelineFields } from '../pipeline/state';
import { FilterFormInner, getEmptyFilterParameters } from './edit-filter';
import { AggregationsEditor } from './aggregations-editor';
import { useMetadataContext } from '../metadata-context';
import { getModelOrThrow, getRelatedModel, hasModelRelation } from '../model/utils';
import { getExplorationVariables } from '../utils';
import { SlicingEditor } from './slicing-editor';
import { getDefaultSlice } from '../utils/slicing';
import { getRelatedFieldName } from './utils/format';
import { getDefaultAggregateName } from '../utils/aggregation';
import { useEnsureFieldsExist } from './hooks/use-ensure-fields-exist';

import { EditJoinStrategyFields } from './edit-join-strategy-fields';

import {
  constructRelationOptionValue,
  parseRelationOptionValue,
  getCustomRelationOptions,
  getOnThisPageRelationOptions,
  getValidRelationTypesForOperation,
  createRelationOptions,
} from './utils/relation';

import { useDirtyContext } from '../dirty-context';

import panelStyles from '@/components/panel/panel.module.scss';
import form from '@/components/form/form.module.scss';

const CustomRelationKey = '__custom';

interface RelatedColumnProps {
  model: Model;
  models: Model[];
}

type Operation = AddRelatedColumnOperation | RelationAggregateOperation;
type SetOperationFn = (operation: Operation) => void;
type AggregationFilter = { parameters: FilterCondition };

const getCellTitle = (pipelineId: string, exploration: Exploration) => {
  const cell = getCellByPipelineIdOrThrow(pipelineId, exploration);
  return 'title' in cell ? cell.title : undefined;
};

function handleFilterChange(
  operation: Operation,
  setOperation: SetOperationFn,
  filters: AggregationFilter[],
) {
  if (operation?.operation === 'relationAggregate') {
    setOperation({
      ...operation,
      parameters: {
        ...operation.parameters,
        filters: filters,
      },
    });
  }
}

const validateOperation = (operation: Operation) => {
  if (operation.operation === 'relationAggregate') {
    const isAggregationsValid = operation.parameters.aggregations.every((aggregation) => {
      if (isKeyedAggregation(aggregation)) {
        return !isNil(aggregation.property.name) && !isNil(aggregation.key);
      }
      return !isNil(aggregation.property.name);
    });

    const slice = 'slice' in operation.parameters ? operation.parameters.slice : undefined;

    const isSliceValid =
      slice === undefined ||
      (slice.limit !== undefined &&
        slice.limit >= 1 &&
        (slice.offset === undefined || slice.offset >= 0));

    return isAggregationsValid && isSliceValid;
  }

  return true;
};

const createDefaultOperation = (
  model: Model,
  models: Model[],
  exploration: Exploration,
  currentCell: RecordsCell,
  fields: Fields,
  metrics: MetricV2[],
  relations: Relation[],
) => {
  const relationOptions = createRelationOptions(relations, model, models);
  const onThisPageRelationOptions = getOnThisPageRelationOptions(
    model,
    models,
    metrics,
    exploration,
    currentCell,
  );
  const customRelationOptions = getCustomRelationOptions(exploration, currentCell);

  const firstOption =
    first(relationOptions) ?? first(onThisPageRelationOptions) ?? first(customRelationOptions);

  if (firstOption === undefined) {
    throw new Error(
      'No relations available and no linkable blocks found in the current exploration',
    );
  }

  const { selectedRelation: firstRelation, pipelineId: firstPipelineId } = parseRelationOptionValue(
    model,
    firstOption.value,
  );

  if (firstRelation === undefined && firstPipelineId === undefined) {
    throw new Error(
      'No relations available and no linkable blocks found in the current exploration',
    );
  }

  if (
    firstRelation !== undefined &&
    getValidRelationTypesForOperation('addRelatedColumn').includes(firstRelation.type)
  ) {
    return {
      operation: 'addRelatedColumn',
      parameters: {
        relation: { key: firstRelation?.key, modelId: model.modelId },
        columns: [],
      },
    } as AddRelatedColumnOperation;
  }

  return {
    operation: 'relationAggregate',
    parameters: {
      relation: { key: firstRelation?.key, modelId: model.modelId },
      ...(firstPipelineId !== undefined ? { pipelineId: firstPipelineId } : {}),
      aggregations: [],
      filters: [],
      ...(firstRelation === undefined && firstPipelineId !== undefined
        ? {
            joinStrategy: createJoinStrategy(firstPipelineId, exploration, models, fields, metrics),
          }
        : {}),
    },
  } as RelationAggregateOperation;
};

const createJoinStrategy = (
  pipelineId: string,
  exploration: Exploration,
  models: Model[],
  fields: Fields,
  metrics: MetricV2[],
) => {
  const pipeline = getPipelineById(pipelineId, exploration);
  if (pipeline.pipelineId === undefined) {
    throw new Error('Pipeline does not have a pipelineId');
  }

  const relatedFields = getPipelineFields(pipeline, exploration, {
    models,
    variables: getExplorationVariables(exploration),
    metrics,
  });

  return {
    joinKeyOnBase: fields[0].key,
    joinKeyOnRelated: relatedFields[0].key,
  };
};

interface RelationInputProps {
  fields: Fields;
  model: Model;
  models: Model[];
  options: Option[];
  operation: Operation;
  exploration: Exploration;
  setOperation(operation: Operation): void;
  resetOperationOnChange?: boolean;
}

const RelationInput = (props: RelationInputProps) => {
  const {
    operation,
    exploration,
    model,
    models,
    options,
    fields,
    setOperation,
    resetOperationOnChange = false,
  } = props;
  const { selectedCell } = useExplorationContext();
  const { metricsV2: metrics } = useMetadataContext();

  if (selectedCell === null || !isRecordsCell(selectedCell)) {
    throw new Error(`RelationSelectInput can only be rendered in a RecordsCell`);
  }

  const onThisPageRelationOptions = getOnThisPageRelationOptions(
    model,
    models,
    metrics,
    exploration,
    selectedCell,
  );
  const customRelationOptions = getCustomRelationOptions(exploration, selectedCell);

  const pipelineId = operation.parameters.pipelineId;

  const value =
    'joinStrategy' in operation.parameters
      ? CustomRelationKey
      : constructRelationOptionValue(operation.parameters.relation.key, pipelineId);

  const handleRelationSelect = (model: Model, value: string) => {
    if (value === CustomRelationKey) {
      const { pipelineId: firstRelatablePipelineId } = parseRelationOptionValue(
        model,
        first(customRelationOptions)?.value ?? '',
      );

      if (firstRelatablePipelineId === undefined) {
        throw new Error('Unable to find another pipeline to relate to');
      }

      setOperation({
        operation: 'relationAggregate',
        parameters: {
          pipelineId: firstRelatablePipelineId,
          joinStrategy: createJoinStrategy(
            firstRelatablePipelineId,
            exploration,
            models,
            fields,
            metrics,
          ),
          aggregations: [],
          filters: [],
        },
      });
      return;
    }
    const { selectedRelation, pipelineId } = parseRelationOptionValue(model, value);
    if (selectedRelation === undefined) {
      throw new Error(`Invalid relation key ${value}`);
    }
    const isAddRelatedColumn = getValidRelationTypesForOperation('addRelatedColumn').includes(
      selectedRelation.type,
    );
    const wasAddRelatedColumn = operation?.operation === 'addRelatedColumn';
    const columns = wasAddRelatedColumn ? (operation?.parameters.columns ?? []) : [];
    const aggregations = wasAddRelatedColumn ? [] : (operation?.parameters.aggregations ?? []);
    const filters = wasAddRelatedColumn ? [] : (operation?.parameters.filters ?? []);
    const slice = wasAddRelatedColumn ? undefined : operation?.parameters.slice;
    setOperation({
      ...operation,
      operation: isAddRelatedColumn ? 'addRelatedColumn' : 'relationAggregate',
      parameters: {
        ...omit(operation?.parameters, 'joinStrategy', 'slice'),
        relation: { key: selectedRelation.key, modelId: model.modelId },
        pipelineId,
        ...(isAddRelatedColumn
          ? { columns: resetOperationOnChange ? [] : columns }
          : {
              ...(resetOperationOnChange ? {} : { slice }),
              aggregations: resetOperationOnChange ? [] : aggregations,
              filters: resetOperationOnChange ? [] : filters,
            }),
      },
    } as Operation);
  };

  const combinedOptions = [
    ...(onThisPageRelationOptions.length > 0
      ? [
          {
            label: 'Relations',
            options,
          },
          {
            label: 'On This Page',
            options: onThisPageRelationOptions,
          },
        ]
      : options),
    ...(customRelationOptions.length > 0
      ? [
          {
            value: CustomRelationKey,
            label: 'Custom',
          },
        ]
      : []),
  ];

  const isValueValid = combinedOptions
    .flatMap((optionOrGroup) =>
      'options' in optionOrGroup ? optionOrGroup.options : [optionOrGroup],
    )
    .some((option) => option.value === value);

  return (
    <SearchInput
      value={isValueValid ? value : ''}
      onChange={(value) => handleRelationSelect(model, value)}
      options={[
        ...(isValueValid ? [] : [{ value: '', label: 'Choose a new relation...' }]),
        ...combinedOptions,
      ]}
      autoFocus
    />
  );
};

interface RelatedColumnFormProps extends RelatedColumnProps {
  fields: Fields;
  operation?: Operation;
  exploration: Exploration;
  onSubmit(operation: Operation): void;
  onClose: () => void;
  onSwitchToCustomFormula?: () => void;
  variables: VariableDefinition[];
  relations: Relation[];
}

export const RelationColumnForm = (props: RelatedColumnFormProps) => {
  const { operation, exploration, model, models, fields, relations } = props;
  const { selectedCell } = useExplorationContext();

  if (selectedCell === null || !isRecordsCell(selectedCell)) {
    throw new Error(`RelationColumnForm can only be rendered in a RecordsCell`);
  }

  // Used to override the operation with a new one if the relation or cell is missing
  const [newOperation, setNewOperation] = useState<Operation | undefined>(undefined);

  const relationKey =
    operation !== undefined && 'relation' in operation.parameters
      ? operation.parameters.relation.key
      : undefined;

  const isRelationMissing =
    relationKey !== undefined && !hasModelRelation(props.model, relationKey);

  const isCellMissing =
    operation !== undefined &&
    operation.parameters.pipelineId !== undefined &&
    getCellIdByPipelineId(operation.parameters.pipelineId, exploration) === undefined;

  const relationOptions = createRelationOptions(relations, model, models);
  const hasAvailableRelations = relationOptions.length > 0;
  const hasAvailableCustomRelations = exploration.view.cells.some(
    (cell) => isRecordsCell(cell) && cell.id !== selectedCell.id,
  );

  if (!hasAvailableRelations && !hasAvailableCustomRelations) {
    return (
      <div className={form.formHorizontal}>
        <p className={form.helpText}>
          {operation === undefined
            ? `No relations available and no linkable blocks found in the current exploration`
            : `Model ${model.name} does not have relations and no linkable blocks found in the current exploration`}
        </p>
        <div>
          <Button size="small" onClick={props.onClose}>
            Close
          </Button>
        </div>
      </div>
    );
  }

  if (
    operation !== undefined &&
    newOperation === undefined &&
    (isRelationMissing || isCellMissing)
  ) {
    return (
      <div className={form.formHorizontal}>
        <div className={form.formRow}>
          <p className={form.helpText}>
            {isRelationMissing
              ? `Relation missing. Please restore relation '${relationKey}' on model '${model.modelId}' or select a new relation.`
              : `The referenced block has been removed from the exploration. Please select a new relation.`}
          </p>
        </div>
        <div className={form.formRow}>
          <RelationInput
            fields={fields}
            model={model}
            models={models}
            options={relationOptions}
            operation={operation}
            exploration={exploration}
            setOperation={setNewOperation}
          />
          <Button size="small" type="outlined" onClick={props.onClose}>
            {props.operation ? 'Cancel' : 'Back'}
          </Button>
        </div>
      </div>
    );
  }

  return <RelationColumnFormInner {...props} operation={newOperation ?? props.operation} />;
};

export const RelationColumnFormInner = (props: RelatedColumnFormProps) => {
  const {
    fields,
    models,
    model,
    relations,
    exploration,
    onSubmit,
    onClose,
    onSwitchToCustomFormula,
    variables,
  } = props;
  const { selectedCell } = useExplorationContext();
  const { metricsV2: metrics } = useMetadataContext();

  if (selectedCell === null || !isRecordsCell(selectedCell)) {
    throw new Error(`RelationColumnForm can only be rendered in a RecordsCell`);
  }

  const initialOperation =
    props.operation ??
    createDefaultOperation(model, models, exploration, selectedCell, fields, metrics, relations);
  const [operation, setOperation] = useState<Operation>(initialOperation);
  const { setDirty } = useDirtyContext();

  const { parameters } = operation;
  const { pipelineId } = parameters;
  const relationKey = 'relation' in parameters ? parameters.relation.key : CustomRelationKey;
  const isOnThisPageRelation = pipelineId !== undefined;

  const explorationVariables = getExplorationVariables(exploration);
  const relatedPipeline =
    'pipelineId' in parameters && parameters.pipelineId !== undefined
      ? getPipelineById(parameters.pipelineId, exploration)
      : {
          baseModelId: getRelatedModel(models, model.modelId, relationKey).modelId,
          operations: [],
        };

  const relatedModel = getModelOrThrow(
    models,
    dereferencePipeline(relatedPipeline, exploration).baseModelId,
  );

  const relatedFields = getPipelineFields(relatedPipeline, exploration, {
    models,
    variables: explorationVariables,
    metrics,
  });

  const slice = 'slice' in parameters ? parameters.slice : undefined;
  // Store previous state so values don't get lost when toggling slicing on/off
  const [previousSlice, setPreviousSlice] = useState<common.Slice | undefined>(slice);

  const selectedRelation =
    'joinStrategy' in parameters
      ? {
          key: CustomRelationKey,
          name: 'Custom',
        }
      : model.relations.find(({ key }) => key === relationKey);
  const selectedRelationOption = isOnThisPageRelation
    ? 'joinStrategy' in parameters
      ? CustomRelationKey
      : constructRelationOptionValue(relationKey, pipelineId)
    : relationKey;

  if (selectedRelation === undefined) {
    throw new Error(`Invalid relation '${relationKey}' for model ${model.modelId}`);
  }

  const relationName = isOnThisPageRelation
    ? (getCellTitle(pipelineId, exploration) ?? relatedModel.name)
    : operation.operation === 'relationAggregate'
      ? selectedRelation.name
      : relatedModel.name;

  const setOperationDefaults = (operation: Operation) => {
    if (operation.operation === 'relationAggregate') {
      return {
        ...operation,
        parameters: {
          ...operation.parameters,
          aggregations: operation.parameters.aggregations.map((aggregation) => {
            const defaultPropertyName = getDefaultAggregateName(
              aggregation,
              relatedFields,
              metrics,
              relationName,
            );
            return {
              ...aggregation,
              property: {
                key: aggregation.property.key || nameToKey(defaultPropertyName),
                name: aggregation.property.name || defaultPropertyName,
              },
            };
          }),
        },
      };
    }
    return operation;
  };

  const handleChange = (operation: Operation) => {
    setOperation(operation);
    const isDirty = !isEqual(initialOperation, operation);
    setDirty(isDirty);
  };

  const handleSetAggregations = (aggregations: Aggregation[]) => {
    if (operation.operation !== 'relationAggregate') {
      throw new Error('Attempt to set aggregations on addRelatedColumn');
    }

    handleChange({
      ...operation,
      parameters: { ...operation.parameters, aggregations },
    });
  };

  const handleToggleSlice = (isEnabled: boolean) => {
    if (operation.operation !== 'relationAggregate') {
      throw new Error('Attempt to set slice on addRelatedColumn');
    }
    handleChange({
      ...operation,
      parameters: {
        ...operation.parameters,
        slice: isEnabled ? getDefaultSlice(relatedFields, previousSlice) : undefined,
      },
    });
  };

  const handleSetSlice = (slice: common.Slice) => {
    if (operation.operation !== 'relationAggregate') {
      throw new Error('Attempt to set slice on addRelatedColumn');
    }
    setPreviousSlice(slice);
    handleChange({
      ...operation,
      parameters: {
        ...operation.parameters,
        slice,
      },
    });
  };

  const handleSubmit = () => {
    setDirty(false);
    const sanitizedOperation = setOperationDefaults(operation);

    if (!validateOperation(sanitizedOperation)) {
      return false;
    }

    onSubmit(sanitizedOperation);
  };

  const handleCancel = () => {
    setDirty(false);
    onClose();
  };

  const relationOptions = createRelationOptions(relations, model, models);

  return (
    <Form className={form.formHorizontal} onSubmit={handleSubmit}>
      <div className={form.formRow}>
        <label className={form.formLabel}>Based on</label>
        <RelationInput
          fields={fields}
          model={model}
          models={models}
          options={relationOptions}
          operation={operation}
          exploration={exploration}
          setOperation={setOperation}
          resetOperationOnChange
        />
        {onSwitchToCustomFormula && (
          <IconButton
            icon="CustomFormula"
            title="Use custom formula"
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              onSwitchToCustomFormula();
            }}
          />
        )}
      </div>
      {operation.operation === 'relationAggregate' && 'joinStrategy' in parameters ? (
        <>
          <hr className={panelStyles.fullWidth} />
          <EditJoinStrategyFields
            data={{
              pipelineId: parameters.pipelineId,
              joinStrategy: parameters.joinStrategy,
            }}
            basePipelineId={selectedCell.pipeline.pipelineId ?? ''}
            exploration={exploration}
            models={models}
            metrics={metrics}
            onChange={(data) =>
              handleChange({
                ...operation,
                parameters: {
                  ...parameters,
                  pipelineId: data.pipelineId,
                  joinStrategy: data.joinStrategy,
                },
              })
            }
          />
        </>
      ) : null}
      <hr className={panelStyles.fullWidth} />

      {operation.operation === 'addRelatedColumn' && (
        <ColumnsEditor
          key={`columns_${selectedRelationOption}`}
          fields={relatedFields}
          relationKey={isOnThisPageRelation ? pipelineId.substring(0, 5) : relationKey}
          relationName={relationName}
          operation={operation}
          setOperation={handleChange}
        />
      )}

      {operation.operation === 'relationAggregate' && (
        <>
          <AggregationsEditor
            key={`aggregations_${selectedRelationOption}`}
            aggregations={operation.parameters.aggregations}
            fields={relatedFields}
            metrics={metrics.filter((metric) => metric.definition.modelId === relatedModel.modelId)}
            relationName={relationName}
            setAggregations={handleSetAggregations}
            showNameInput
            excludeAggregationTypes={['last']}
          />
          <hr className={panelStyles.fullWidth} />
          <div className={form.formHorizontal}>
            <Toggle checked={slice !== undefined} onChange={handleToggleSlice} size="small">
              Slice
            </Toggle>
            {slice !== undefined && (
              <SlicingEditor fields={relatedFields} slice={slice} setSlice={handleSetSlice} />
            )}
          </div>
          <hr className={panelStyles.fullWidth} />
          <RelationAggregateFilterEditor
            key={`filters_${selectedRelationOption}`}
            {...props}
            fields={fields}
            relatedFields={relatedFields}
            model={model}
            relationKey={relationKey}
            relationName={relationName}
            operation={operation}
            onFilterChange={(filters) => handleFilterChange(operation, setOperation, filters)}
            variables={variables}
          />
        </>
      )}

      <div className={form.formControls}>
        <Button size="small" htmlType="submit" disabled={!validateOperation(operation)}>
          {props.operation ? 'Save' : 'Create column'}
        </Button>
        <Button size="small" type="outlined" onClick={handleCancel}>
          {props.operation ? 'Cancel' : 'Back'}
        </Button>
      </div>
    </Form>
  );
};

type RelationAggregateFilterEditorProps = RelatedColumnFormProps & {
  fields: Fields;
  relatedFields: Fields;
  model: Model;
  relationKey: string;
  relationName: string;
  onFilterChange: (filters: AggregationFilter[]) => void;
  operation: RelationAggregateOperation | undefined;
  variables: VariableDefinition[];
};

const RelationAggregateFilterEditor = (props: RelationAggregateFilterEditorProps) => {
  const { onFilterChange, operation, fields, model, relationKey, relationName, variables } = props;

  const relatedFields = useEnsureFieldsExist(
    props.relatedFields,
    operation?.parameters.filters?.flatMap((filter) =>
      getCompositeConditionKeys(filter.parameters),
    ) ?? [],
  );
  const propFilters = operation?.parameters.filters;
  const appliedFilters = propFilters !== undefined && propFilters.length > 0 ? propFilters : [];

  const handleAddFilter = () =>
    onFilterChange([
      ...appliedFilters,
      { parameters: getEmptyFilterParameters(first(relatedFields)) },
    ]);

  const handleFilterChange = (index: number, condition: FilterCondition) => {
    onFilterChange([
      ...appliedFilters.slice(0, index),
      { ...appliedFilters[index], parameters: condition },
      ...appliedFilters.slice(index + 1),
    ]);
  };

  return (
    <div>
      {appliedFilters.length > 0 && <div className={panelStyles.title}>Filter</div>}
      {appliedFilters.map((filter, i) => (
        <div style={{ marginTop: 8, marginBottom: 8 }} className={form.formHorizontal} key={i}>
          {i > 0 && <hr className={form.dashed} />}
          <div className={classNames(form.formRow, form.alignTop)}>
            <div className={form.formHorizontal}>
              <FilterFormInner
                fields={relatedFields}
                model={model}
                fieldsForExpression={[
                  {
                    name: model.name,
                    key: model.modelId,
                    fields: fields,
                  },
                  {
                    name: relationName,
                    key: relationKey,
                    fields: relatedFields,
                  },
                ]}
                condition={filter.parameters}
                setCondition={(parameters) => {
                  return handleFilterChange(i, parameters);
                }}
                onRemove={() => onFilterChange(appliedFilters.filter((_, index) => index !== i))}
                variables={variables}
              />
            </div>
          </div>
        </div>
      ))}
      <div>
        <InlineButton onClick={handleAddFilter} size="small">
          <Icon name="Plus" size={15} /> Filter
        </InlineButton>
      </div>
    </div>
  );
};

interface ColumnsEditorProps {
  fields: Fields;
  relationKey: string;
  relationName: string;
  operation: AddRelatedColumnOperation;
  setOperation: (operation: AddRelatedColumnOperation) => void;
}

const ColumnsEditor = (props: ColumnsEditorProps) => {
  const { relationKey, relationName, operation, setOperation } = props;
  const { parameters } = operation;

  const fields = useEnsureFieldsExist(
    props.fields,
    operation.parameters.columns.map((column) => column.key),
  );

  const handleChange = (selectedColumns: string[]) => {
    setOperation({
      operation: 'addRelatedColumn',
      parameters: {
        ...parameters,
        columns: Array.from(selectedColumns).map((column) => {
          const field = fields.find(({ key }) => key === column);
          if (field === undefined) {
            throw new Error(`Selected unknown field ${column}`);
          }
          return {
            key: column,
            property: {
              key: ensureLegalIdentifier(`${relationKey}_${field.key}`),
              name: getRelatedFieldName(field.name, relationName),
            },
          };
        }),
      },
    });
  };

  const options = fields.map(({ key, name }) => ({ value: key, label: name }));

  return (
    <div>
      <label className={form.formLabel}>Columns</label>
      <TagSelect
        options={options}
        value={parameters.columns.map(({ key }) => key)}
        onChange={handleChange}
        isMultiSelect
      />
    </div>
  );
};
