import { useState } from 'react';
import classNames from 'classnames';

import { useToastContext } from '../../components/toast';
import { Select } from '../../components/form/select';
import { Input } from '../../components/form/input';
import { VariableDefinition } from '../types';
import { FormField } from '../../components/form/form-field';
import { generateUniqueVariableKey, isUniqueVariableKey } from '../utils';
import { InlineButton } from '../../components/button';
import { Icon } from '../../components/icon';

import formStyles from '../../components/form/form.module.scss';

interface VariableDefinitionFormProps {
  definition: VariableDefinition;
  existingKeys: VariableDefinition['key'][];
  onSubmit: (definition: VariableDefinition) => void;
}

const KindOptions: { value: VariableDefinition['kind']; label: string }[] = [
  { value: 'string', label: 'String' },
  { value: 'number', label: 'Number' },
  { value: 'boolean', label: 'Boolean' },
  { value: 'enum', label: 'Select' },
  { value: 'date', label: 'Date' },
];

export const VariableDefinitionForm = (props: VariableDefinitionFormProps) => {
  const { definition, existingKeys, onSubmit } = props;

  const addToast = useToastContext();
  const [key, setKey] = useState<VariableDefinition['key']>(definition.key);

  const handleKindChange = (value: VariableDefinition['kind']) => {
    switch (value) {
      case 'string':
        onSubmit({ key: definition.key, kind: value, defaultValue: '' });
        break;
      case 'number':
        onSubmit({ key: definition.key, kind: value, defaultValue: 0 });
        break;
      case 'boolean':
        onSubmit({ key: definition.key, kind: value, defaultValue: false });
        break;
      case 'enum':
        onSubmit({
          key: definition.key,
          kind: value,
          options: [{ value: '' }, { value: '' }, { value: '' }],
          defaultValue: '',
        });
        break;
      case 'date':
        onSubmit({ key: definition.key, kind: value, defaultValue: '' });
        break;
    }
  };

  const handleKeyChange = () => {
    if (isUniqueVariableKey(key, existingKeys)) {
      return onSubmit({ ...definition, key });
    }
    const { uniqueKey, stepsTaken } = generateUniqueVariableKey(key, existingKeys);

    addToast({
      title: `Variable renamed to “${uniqueKey}”`,
      content: () => `Variable names need to be unique. “-${stepsTaken}” was added to the name.`,
      kind: 'success',
    });

    onSubmit({
      ...definition,
      key: uniqueKey,
    });
  };

  return (
    <form className={classNames(formStyles.formHorizontal)}>
      <div className={formStyles.formRow}>
        <FormField
          label="Name"
          error={isUniqueVariableKey(key, existingKeys) ? undefined : 'Name must be unique'}>
          <Input
            required
            type="text"
            placeholder="Enter text.."
            value={key}
            onBlur={handleKeyChange}
            onChange={(event) => setKey(event.currentTarget.value)}
          />
        </FormField>
      </div>
      <div className={formStyles.formRow}>
        <FormField label="Type">
          <Select options={KindOptions} value={definition.kind} onChange={handleKindChange} />
        </FormField>
      </div>
      {definition.kind === 'enum' && (
        <>
          {(definition.options ?? []).map((option, idx) => (
            <FormField
              key={idx}
              label={`Option ${idx + 1}`}
              onRemove={() =>
                onSubmit({
                  ...definition,
                  options: [
                    ...definition.options.slice(0, idx),
                    ...definition.options.slice(idx + 1),
                  ],
                })
              }>
              <Input
                required
                type="text"
                placeholder="Enter name"
                value={option.value}
                onChange={(e) => {
                  const updatedOptions = definition.options.map((option, optionIdx) =>
                    optionIdx === idx ? { ...option, value: e.currentTarget.value } : option,
                  );
                  onSubmit({
                    ...definition,
                    defaultValue: updatedOptions[0].value ?? '',
                    options: updatedOptions,
                  });
                }}
              />
            </FormField>
          ))}
          <div>
            <InlineButton
              onClick={() => {
                onSubmit({
                  ...definition,
                  options: [...definition.options, { value: '' }],
                });
              }}>
              <Icon name="Plus" size={15} /> Option
            </InlineButton>
          </div>
        </>
      )}
    </form>
  );
};
