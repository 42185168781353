import { Outlet } from 'react-router-dom';

import { ErrorBoundary, GenericFallback } from '@/lib/error';

import { ContextMenuContextProvider } from '../context-menu/context-menu-context';
import { OverlayContextProvider } from '../overlay/context';
import { MainHeader } from '../header';

import styles from './header-layout.module.scss';

export const HeaderLayout = () => {
  return (
    <ContextMenuContextProvider>
      <div className={styles.layout}>
        <OverlayContextProvider>
          <MainHeader />
          <div className={styles.main}>
            <div className={styles.content}>
              <ErrorBoundary
                fallback={({ resetError }) => (
                  <GenericFallback fullscreen resetOnNavigate resetError={resetError} />
                )}>
                <Outlet />
              </ErrorBoundary>
            </div>
          </div>
        </OverlayContextProvider>
      </div>
    </ContextMenuContextProvider>
  );
};
