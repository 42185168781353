import { isSameYear, parseISO } from 'date-fns';
import { isBoolean, isArray, isObject } from 'lodash';

import { formatDate, getIntervalFormat, getWeekdayName } from '@/lib/date';

import { Exploration, Model, Pipeline, TimeAggregationPeriod } from '../types';
import { getModelOrThrow } from '../model/utils';
import { getCellByPipelineIdOrThrow } from '../exploration/utils';

export const getPipelineTitle = (models: Model[], exploration: Exploration, pipeline: Pipeline) => {
  if ('baseModelId' in pipeline) {
    return getModelOrThrow(models, pipeline.baseModelId).name;
  }

  if ('parentId' in pipeline) {
    const parentCell = getCellByPipelineIdOrThrow(pipeline.parentId, exploration);
    if (parentCell.kind === 'records') {
      return parentCell.title ?? '';
    }
  }

  return '';
};

export interface PropertyFormatOptions {
  maxLength?: number;
  timezone?: string;
  precision?: TimeAggregationPeriod;
}

export function formatPropertyValue(
  value: any,
  type?: string,
  { maxLength, timezone, precision }: PropertyFormatOptions = {},
): string {
  if (value === null || value === '') {
    // TODO: better handle nully Model properties
    return '-';
  }

  if (type === 'Integer' && precision === 'day_of_week') {
    return getWeekdayName(value);
  }

  if (type === 'Date' && typeof value === 'string') {
    try {
      const d = parseISO(value);
      const shouldShowYear = !isSameYear(new Date(), d);
      const fmt =
        precision !== undefined
          ? getIntervalFormat(precision)
          : `MMMM do${shouldShowYear ? ' yyyy' : ''} HH:mm`;
      return formatDate(d, fmt, timezone);
    } catch {
      return limitLength(String(value), maxLength);
    }
  }
  if (type === 'Boolean' || isBoolean(value)) {
    if (value === true || value === 'true') {
      return 'Yes';
    }
    if (value === false || value === 'false') {
      return 'No';
    }
  }
  if (isNumberType(type) && !isNaN(value)) {
    return round6Digits(Number(value)).toLocaleString();
  }
  if (type === 'Object') {
    if (isObject(value)) {
      return limitLength(JSON.stringify(value, null, 2), maxLength);
    }
    return value;
  }
  if (typeof value === 'string') {
    return limitLength(value, maxLength);
  }
  if (type === 'Array' || (type === '' && Array.isArray(value))) {
    if (isArray(value) && value.some((item) => isObject(item))) {
      return limitLength(JSON.stringify(value, null, 2), maxLength);
    }
    return limitLength(value.join(', '), maxLength);
  }
  if (
    type === 'Interval' ||
    (type === 'String' &&
      typeof value === 'object' &&
      Object.keys(value).every((key) =>
        ['days', 'hours', 'minutes', 'seconds', 'milliseconds'].includes(key),
      ))
  ) {
    // Handle date intervals returned by Postgres
    if (value['days'] !== undefined) {
      return `${value['days']} days`;
    }
    if (value['hours'] !== undefined) {
      return `${value['hours']} hours`;
    }
    if (value['minutes'] !== undefined) {
      return `${value['minutes']} minutes`;
    }
    if (value['seconds'] !== undefined) {
      return `${value['seconds']} seconds`;
    }
    if (value['milliseconds'] !== undefined) {
      return `${value['milliseconds']} milliseconds`;
    }
    if (Object.keys(value).length === 0) {
      return '-';
    }
  }
  return limitLength(String(value), maxLength);
}

function round6Digits(num: number) {
  // 6 digits should be enough to get rid of random noise #magic
  const numDigits = 6;
  return Math.round(num * 10 ** numDigits) / 10 ** numDigits;
}

// TODO: This belongs to model field logic
const isNumberType = (type: string | null | undefined) =>
  ['Number', 'Float', 'Integer'].includes(type ?? '');

export const limitLength = (value: string, maxLength: number | undefined) =>
  maxLength !== undefined && value.length > maxLength
    ? `${value.substring(0, maxLength - 3)}...`
    : value;
