import { first, get, isNumber, isObject, isString } from 'lodash';
import classNames from 'classnames';

import { Json } from '@/lib/types';
import { formatNumberToShort } from '@/lib/utils/number';
import { Icon } from '@/components/icon';

import { ListRecord } from '@/explore/grouping';
import { Field } from '@/explore/types';

import styles from './big-number.module.scss';

interface BigNumberProps {
  field: Field;
  comparisonField?: Field;
  data: ListRecord[];
  comparisonType?: 'absolute' | 'percentage';
  direction?: 'higher' | 'lower';
  showLabel?: boolean;
  compact?: boolean;
}

const getDelta = ({ field, comparisonField, data, comparisonType }: BigNumberProps) => {
  if (
    comparisonField === undefined ||
    data.length === 0 ||
    (field.key === comparisonField.key && data.length < 2)
  ) {
    return undefined;
  }

  const displayPercentage = comparisonType === 'percentage';
  const currentValue = get(first(data), field.key, 0);
  const comparisonValue =
    field.key === comparisonField.key
      ? get(data.at(1), comparisonField.key, 0)
      : get(data.at(0), comparisonField.key, 0);

  if (
    !isNumber(currentValue) ||
    !isNumber(comparisonValue) ||
    (displayPercentage && comparisonValue === 0)
  ) {
    return undefined;
  }

  return displayPercentage
    ? ((currentValue - comparisonValue) / comparisonValue) * 100
    : currentValue - comparisonValue;
};

interface GetDeltaLabelProps extends BigNumberProps {
  deltaValue?: number;
}

const getDeltaLabel = ({
  field,
  comparisonField,
  data,
  showLabel,
  deltaValue,
}: GetDeltaLabelProps) => {
  if (comparisonField === undefined) {
    return undefined;
  }
  if ((data.length < 2 && field.key === comparisonField.key) || deltaValue === undefined) {
    return 'No comparison available';
  }
  if (showLabel === true) {
    return comparisonField?.name;
  }

  return undefined;
};

const getFormattedValue = (value: Json) => {
  if (isNumber(value)) {
    return formatNumberToShort({ num: value });
  }
  if (value === null || value === '') {
    return '-';
  }
  if (!isString(value)) {
    return JSON.stringify(value);
  }
  return value;
};

export const BigNumber = ({
  field,
  comparisonField,
  comparisonType,
  direction,
  showLabel,
  data,
  compact,
}: BigNumberProps) => {
  const value = get(first(data), field.key, 'N/A');

  const deltaValue = getDelta({ field, comparisonField, data, comparisonType });
  const deltaLabel = getDeltaLabel({ field, comparisonField, data, showLabel, deltaValue });

  const deltaSuffix = comparisonType === 'percentage' ? '%' : '';
  const reversedDeltaColors = direction === 'lower';
  const normalizedDelta =
    deltaValue !== undefined ? formatNumberToShort({ num: deltaValue }) : null;
  const formattedDelta = `${deltaValue !== undefined && deltaValue > 0 ? '+' : ''}${normalizedDelta}${deltaSuffix !== undefined ? deltaSuffix : ''}`;
  const formattedValue = getFormattedValue(value);

  const isDeltaPositive =
    deltaValue !== undefined && (reversedDeltaColors ? deltaValue < 0 : deltaValue > 0);

  return (
    <div
      className={classNames(styles.bigNumber, {
        [styles.compact]: compact === true,
      })}>
      {isObject(value) ? (
        <pre className={styles.bigNumberValue}>{JSON.stringify(value, null, 2)}</pre>
      ) : (
        <div className={styles.bigNumberValue}>{formattedValue}</div>
      )}
      <div className={styles.bigNumberLabel}>{field.name}</div>
      {(deltaValue !== undefined || deltaLabel !== undefined) && (
        <div className={classNames(styles.delta, styles.bigNumberLabel)}>
          {deltaValue !== undefined && (
            <div
              className={classNames(styles.deltaValue, {
                [styles.positive]: deltaValue !== 0 && isDeltaPositive,
                [styles.negative]: deltaValue !== 0 && !isDeltaPositive,
              })}>
              {deltaValue !== 0 && (
                <Icon
                  name="ArrowUp"
                  size={14}
                  className={classNames(styles.deltaArrow, {
                    [styles.arrowFlipped]: deltaValue < 0,
                  })}
                />
              )}
              {formattedDelta}
            </div>
          )}
          {deltaLabel !== undefined && <div>{deltaLabel}</div>}
        </div>
      )}
    </div>
  );
};
