import { useState } from 'react';
import classNames from 'classnames';

import { Input } from '@/components/form/input';
import { Button } from '@/components/button';
import { Icon } from '@/components/icon';

import formStyles from '@/components/form/form.module.scss';

export type ExplorationDetailsFormData = {
  name: string;
  description?: string;
};

interface ExplorationDetailsFormProps {
  data: ExplorationDetailsFormData;
  onSubmit: (data: ExplorationDetailsFormData) => Promise<void>;
  onCancel: () => void;
  onDelete?: () => void;
}

const isValid = (data: ExplorationDetailsFormData) => data.name.trim().length > 0;

export const ExplorationDetailsForm = ({
  onSubmit,
  onCancel,
  onDelete,
  data: initialData,
}: ExplorationDetailsFormProps) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialData);

  const handleChange =
    (key: keyof ExplorationDetailsFormData) => (e: React.ChangeEvent<HTMLInputElement>) =>
      setData({
        ...data,
        [key]: e.target.value,
      });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isValid(data)) {
      setLoading(true);
      await onSubmit(data);
      setLoading(false);
    }
  };

  return (
    <form
      className={classNames(formStyles.formHorizontal, formStyles.sparse)}
      onSubmit={handleSubmit}>
      <div className={formStyles.formRow}>
        <label className={formStyles.formLabel} style={{ minWidth: '16%' }}>
          Name
        </label>
        <Input
          required
          autoFocus
          type="text"
          placeholder="Name your exploration"
          value={data.name}
          size="large"
          onChange={handleChange('name')}
        />
      </div>
      <div className={formStyles.formRow}>
        <label className={formStyles.formLabel} style={{ minWidth: '16%' }}>
          Description
        </label>
        <Input
          type="text"
          placeholder="What’s it about?"
          value={data.description}
          size="large"
          onChange={handleChange('description')}
        />
      </div>
      <div className={formStyles.formControls}>
        <Button htmlType="submit" size="large" disabled={!isValid(data)} loading={loading}>
          Save
        </Button>
        <Button type="secondary" size="large" onClick={onCancel}>
          Cancel
        </Button>
        {onDelete !== undefined && (
          <Button
            icon={<Icon name="Trash2" size={16} />}
            type="outlined"
            size="large"
            color="warning"
            className={formStyles.floatRight}
            onClick={onDelete}>
            Delete exploration
          </Button>
        )}
      </div>
    </form>
  );
};
