import { useRef, useState } from 'react';
import { nth } from 'lodash';
import classNames from 'classnames';

import { useTrackEvent } from '@/lib/analytics';

import { Cell, Exploration } from '../../types';
import { useExplorationContext } from '../exploration-context';
import { getCellRow, getCellRows, isCellInMultiRow, updateCellRowHeight } from './utils';
import { CellResizeArea } from './cell-resize-area';

import styles from './exploration-layout.module.scss';

const CellIndexKey = `application/x-sup-drag-cell`;
const RowIdKeyPrefix = `application/x-sup-drag-cell-row`;

const MinCellHeight = 60;
const MaxCellHeight = 5000;

const getRowIdKey = (cell: Cell) =>
  `${RowIdKeyPrefix}-${cell.viewOptions?.rowId ?? ''}`.toLowerCase();

const isCellDragEvent = (event: React.DragEvent) =>
  event.dataTransfer.types.indexOf(CellIndexKey) !== -1;

const isDraggedCellOnSameRow = (event: React.DragEvent, cell: Cell) =>
  event.dataTransfer.types.indexOf(getRowIdKey(cell)) !== -1;

const canDropOnRow = (event: React.DragEvent, cells: Cell[], cellIndex: number, cell: Cell) =>
  isDraggedCellOnSameRow(event, cell) || getCellRow(getCellRows(cells), cellIndex).length < 3;

const trackDropEvent = (
  exploration: Exploration,
  droppedCellIndex: number,
  targetIndex: number,
  droppedCell: Cell | undefined,
  afterTarget: boolean,
  mergeIntoRow: boolean,
  trackEvent: (eventName: string, properties?: { [key: string]: unknown }) => void,
) => {
  const isFromMultiCellRow =
    getCellRow(getCellRows(exploration.view.cells), droppedCellIndex).length > 1;
  const eventName =
    mergeIntoRow === true && !isFromMultiCellRow
      ? 'Exploration Cell Merged Into Row'
      : mergeIntoRow === false && isFromMultiCellRow
        ? 'Exploration Cell Detached From Row'
        : 'Exploration Cell Moved';
  trackEvent(eventName, {
    exploration: exploration,
    cell: droppedCell,
    cellIndex: droppedCellIndex,
    targetIndex,
    afterTarget,
  });
};

interface DraggableCellProps {
  index: number;
  isDraggable: boolean;
  height: number | undefined;
  selected?: boolean;
  children: React.ReactNode;
}

export const DraggableCell = (props: DraggableCellProps) => {
  const { isDraggable, selected = false, index, height } = props;
  const [isDragged, setIsDragged] = useState(false);
  const [isResized, setIsResized] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [heightOverride, setHeightOverride] = useState<number | undefined>(undefined);
  const [dragStartHeight, setDragStartHeight] = useState<number | undefined>(undefined);
  const [side, setSide] = useState<'left' | 'right'>('left');
  const elementRef = useRef<HTMLDivElement>(null);

  const { exploration, moveCell, setExploration } = useExplorationContext();
  const trackEvent = useTrackEvent();

  const cell = nth(exploration.view.cells, index);

  if (cell === undefined) {
    return props.children;
  }

  const appliedHeight = heightOverride ?? height;

  const setCellHeight = (newHeight: number | undefined) => {
    setExploration({
      ...exploration,
      view: {
        ...exploration.view,
        cells: updateCellRowHeight(exploration.view.cells, index, newHeight),
      },
    });
  };

  return (
    <div
      style={{
        height: appliedHeight === undefined ? 'auto' : `${appliedHeight}px`,
      }}
      className={classNames(styles.draggableCell, {
        [styles.isDragOver]: isDragOver,
        [styles.isResized]: isResized,
        [styles.droppableLeft]: isDragOver && !isDragged && side === 'left',
        [styles.droppableRight]: isDragOver && !isDragged && side === 'right',
      })}
      ref={elementRef}
      draggable={isDraggable}
      onDragStart={(event) => {
        event.stopPropagation(); // Allow nested draggables
        event.dataTransfer.clearData();
        event.dataTransfer.setData(CellIndexKey, index.toString());
        event.dataTransfer.setData(getRowIdKey(cell), 'true');
        event.dataTransfer.effectAllowed = 'all';
        setIsDragged(true);
      }}
      onDragEnd={() => {
        setIsDragOver(false);
        setIsDragged(false);
      }}
      onDragOver={(event) => {
        if (!isCellDragEvent(event) || !canDropOnRow(event, exploration.view.cells, index, cell)) {
          event.dataTransfer.dropEffect = 'none';
          return;
        }
        event.dataTransfer.dropEffect = 'move';
        event.preventDefault();
        const rect = event.currentTarget.getBoundingClientRect();
        const xPos = (event.clientX - rect.left) / rect.width;
        const currentSide = xPos > 0.5 ? 'right' : 'left';
        if (currentSide !== side) {
          setSide(currentSide);
        }
      }}
      onDragEnter={(event) => {
        if (!isCellDragEvent(event) || !canDropOnRow(event, exploration.view.cells, index, cell)) {
          return;
        }
        event.preventDefault();
        setIsDragOver(true);
      }}
      onDragLeave={(event) => {
        if (elementRef.current && !elementRef.current.contains(event.relatedTarget as Node)) {
          setIsDragOver(false);
        }
      }}
      onDrop={(event) => {
        if (!isCellDragEvent(event) || !canDropOnRow(event, exploration.view.cells, index, cell)) {
          return;
        }
        setIsDragOver(false);
        setIsDragged(false);
        const droppedCellIndex = parseInt(event.dataTransfer.getData(CellIndexKey));
        if (droppedCellIndex === index) {
          return;
        }
        event.preventDefault();
        event.stopPropagation();
        moveCell(droppedCellIndex, index, side === 'right', true, elementRef.current?.clientHeight);
        trackDropEvent(
          exploration,
          droppedCellIndex,
          index,
          nth(exploration.view.cells, droppedCellIndex),
          side === 'right',
          true,
          trackEvent,
        );
      }}>
      {props.children}
      <CellResizeArea
        isCellSelected={selected}
        onResizeStart={() => {
          setIsResized(true);
          setDragStartHeight(elementRef.current?.clientHeight ?? undefined);
        }}
        onResize={(dy) => {
          setHeightOverride(
            Math.max(MinCellHeight, Math.min(MaxCellHeight, (dragStartHeight ?? 500) + dy)),
          );
        }}
        onResizeEnd={() => {
          setIsResized(false);
          if (elementRef.current === null || heightOverride === undefined) {
            return;
          }
          setCellHeight(heightOverride);
          setHeightOverride(undefined);
        }}
        onResetSize={() => {
          if (!isCellInMultiRow(exploration.view.cells, index)) {
            setCellHeight(undefined);
          }
        }}
      />
    </div>
  );
};

interface CellDropZoneProps {
  index: number;
  mergeIntoRow?: boolean;
  addBefore?: boolean;
  vertical?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const CellDropZone = (props: CellDropZoneProps) => {
  const { index, mergeIntoRow, addBefore } = props;
  const [isDragOver, setIsDragOver] = useState(false);
  const element = useRef<HTMLDivElement>(null);

  const { exploration, moveCell } = useExplorationContext();
  const trackEvent = useTrackEvent();

  const cell = nth(exploration.view.cells, index);

  if (cell === undefined) {
    return props.children;
  }

  return (
    <div
      className={classNames(styles.cellDropZone, props.className, {
        [styles.isDragOver]: isDragOver,
        [styles.vertical]: props.vertical,
      })}
      ref={element}
      onDragEnd={() => {
        setIsDragOver(false);
      }}
      onDragOver={(event) => {
        if (
          !isCellDragEvent(event) ||
          (mergeIntoRow === true && !canDropOnRow(event, exploration.view.cells, index, cell))
        ) {
          event.dataTransfer.dropEffect = 'none';
          return;
        }
        event.dataTransfer.dropEffect = 'move';
        event.preventDefault();
      }}
      onDragEnter={(event) => {
        if (
          !isCellDragEvent(event) ||
          (mergeIntoRow === true && !canDropOnRow(event, exploration.view.cells, index, cell))
        ) {
          return;
        }
        event.preventDefault();
        setIsDragOver(true);
      }}
      onDragLeave={(event) => {
        if (element.current && !element.current.contains(event.relatedTarget as Node)) {
          setIsDragOver(false);
        }
      }}
      onDrop={(event) => {
        if (
          !isCellDragEvent(event) ||
          (mergeIntoRow === true && !canDropOnRow(event, exploration.view.cells, index, cell))
        ) {
          return;
        }
        event.preventDefault();
        event.stopPropagation();
        setIsDragOver(false);
        const droppedCellIndex = parseInt(event.dataTransfer.getData(CellIndexKey));
        moveCell(droppedCellIndex, index, addBefore !== true, mergeIntoRow);
        trackDropEvent(
          exploration,
          droppedCellIndex,
          index,
          nth(exploration.view.cells, droppedCellIndex),
          addBefore !== true,
          mergeIntoRow === true,
          trackEvent,
        );
      }}>
      {props.children}
    </div>
  );
};
