import { createContext, useCallback, useContext, useReducer } from 'react';
import { get, nth, omit } from 'lodash';

import { Breakpoint, useScreenSize } from '@/lib/hooks/use-screen-size';

import {
  Cell,
  Exploration,
  ExplorationParameter,
  ExplorationParameters,
  Field,
  Fields,
  FilterOperation,
  GroupAggregateOperation,
  MetricV2,
  Model,
  QueryVariables,
} from '../types';
import { getVariableDefinitions, getVariablesFromParameters } from '../utils';
import {
  importExplorationCellsToExploration,
  createExplorationCellInstanceAtIdx,
  deleteExplorationCellById,
  duplicateExplorationCellAtIdx,
  getCellIndex,
  getFieldsByCellId,
} from './utils';
import {
  ensureValidLayout,
  getRowEndIndex,
  getRowStartIndex,
  isCellFirstInRow,
  isCellInMultiRow,
  isCellLastInRow,
  moveCell,
  swapCells,
  updateCellRowHeight,
} from './exploration-layout/utils';
import { addDrillDownCellToExploration } from '../utils/drilldown';
import { useDirtyContext } from '../dirty-context';

export type FormId = 'addFilter' | 'addGroupAggregate' | 'addSwitchToRelation' | 'addNewColumn';

export interface QueryMeta {
  recordType?: Fields;
  grouping?: { key: string }[];
}

type ExplorationState = {
  selectedCellIndex: number | null;
  isEditorOpen: boolean;
  scrollToIndex: number | null;
  addFormData:
    | {
        index: number;
        formId: 'addFilter';
        parameters?: FilterOperation['parameters'];
      }
    | {
        index: number;
        formId: 'addGroupAggregate';
        parameters?: GroupAggregateOperation['parameters'];
      }
    | {
        index: number;
        formId?: 'addSwitchToRelation' | 'addNewColumn';
      }
    | null;
  editFormIndex: number | null;
  queryMeta: { [cellId: string]: QueryMeta | null };
};

type OpenEditorAction = {
  type: 'OPEN_EDITOR';
  payload: {
    cellIndex: number;
  };
};

type CloseEditorAction = {
  type: 'CLOSE_EDITOR';
};

type SelectCellAction = {
  type: 'SELECT_CELL';
  payload: {
    cellIndex: number | null;
  };
};

type DeselectCellAction = {
  type: 'DESELECT_CELL';
};

type ScrollToCellAction = {
  type: 'SCROLL_TO_CELL';
  payload: {
    cellIndex: number | null;
  };
};

type OpenAddMenuAction = {
  type: 'OPEN_ADD_MENU';
  payload: {
    index: number;
  };
};

type OpenAddFormAction = {
  type: 'OPEN_ADD_FORM';
  payload:
    | {
        index: number;
        formId: 'addFilter';
        parameters: FilterOperation['parameters'];
      }
    | {
        index: number;
        formId: 'addGroupAggregate';
        parameters: GroupAggregateOperation['parameters'];
      }
    | {
        index: number;
        formId: FormId;
      };
};

type CloseAddFormAction = {
  type: 'CLOSE_ADD_FORM';
};

type OpenEditFormAction = {
  type: 'OPEN_EDIT_FORM';
  payload: {
    index: number;
  };
};

type CloseEditFormAction = {
  type: 'CLOSE_EDIT_FORM';
};

type SetQueryMetaAction = {
  type: 'SET_QUERY_META';
  payload: { cellId: string; meta: QueryMeta | null };
};

type Action =
  | OpenEditorAction
  | CloseEditorAction
  | SelectCellAction
  | DeselectCellAction
  | ScrollToCellAction
  | OpenAddMenuAction
  | OpenAddFormAction
  | CloseAddFormAction
  | OpenEditFormAction
  | CloseEditFormAction
  | SetQueryMetaAction;

const getInitialState = (exploration: Exploration, screenSize: Breakpoint): ExplorationState => ({
  selectedCellIndex: exploration.view.cells.length === 1 && screenSize > Breakpoint.md ? 0 : null,
  isEditorOpen: exploration.view.cells.length === 1 && screenSize > Breakpoint.md,
  scrollToIndex: null,
  addFormData: null,
  editFormIndex: null,
  queryMeta: {},
});

const reducer = (state: ExplorationState, action: Action): ExplorationState => {
  switch (action.type) {
    case 'OPEN_EDITOR':
      return {
        ...state,
        isEditorOpen: true,
        selectedCellIndex: action.payload?.cellIndex ?? state.selectedCellIndex,
        addFormData:
          action.payload?.cellIndex !== state.selectedCellIndex ? null : state.addFormData,
        editFormIndex:
          action.payload?.cellIndex !== state.selectedCellIndex ? null : state.editFormIndex,
      };
    case 'CLOSE_EDITOR':
      return {
        ...state,
        isEditorOpen: false,
        scrollToIndex: null,
        selectedCellIndex: null,
        addFormData: null,
      };
    case 'SELECT_CELL':
      return {
        ...state,
        selectedCellIndex: action.payload.cellIndex,
        addFormData: null,
        editFormIndex: null,
      };
    case 'DESELECT_CELL':
      return {
        ...state,
        selectedCellIndex: null,
        isEditorOpen: false,
      };
    case 'OPEN_ADD_MENU':
    case 'OPEN_ADD_FORM':
      return {
        ...state,
        addFormData: action.payload,
        editFormIndex: null,
      };
    case 'CLOSE_ADD_FORM':
      return {
        ...state,
        addFormData: null,
      };
    case 'OPEN_EDIT_FORM':
      return {
        ...state,
        editFormIndex: action.payload.index,
        addFormData: null,
      };
    case 'CLOSE_EDIT_FORM':
      return {
        ...state,
        editFormIndex: null,
      };
    case 'SCROLL_TO_CELL':
      return {
        ...state,
        scrollToIndex: action.payload.cellIndex,
      };
    case 'SET_QUERY_META':
      return {
        ...state,
        queryMeta: {
          ...state.queryMeta,
          [action.payload.cellId]: action.payload.meta,
        },
      };
    default:
      return state;
  }
};

export interface ExplorationContextValue {
  exploration: Exploration;
  cellCount: number;
  selectedCellIndex: ExplorationState['selectedCellIndex'];
  selectedCell: Cell | null;
  isEditorOpen: ExplorationState['isEditorOpen'];
  addFormData: ExplorationState['addFormData'];
  editFormIndex: ExplorationState['editFormIndex'];
  scrollToIndex: ExplorationState['scrollToIndex'];
  openEditor: (payload: OpenEditorAction['payload']) => void;
  closeEditor: () => void;
  selectCell: (index: number | null) => void;
  deselectCell: () => void;
  openAddMenu: (payload: OpenAddMenuAction['payload']) => void;
  openAddForm: (payload: OpenAddFormAction['payload']) => void;
  closeAddForm: () => void;
  openEditForm: (payload: OpenEditFormAction['payload']) => void;
  closeEditForm: () => void;
  scrollToCell(index: number | null): void;
  setExploration: (exploration: Exploration, parameters?: ExplorationParameters) => void;
  upsertExploration: (
    exploration: Exploration,
    parameters: ExplorationParameters | null,
  ) => Promise<void>;
  deleteExploration: (explorationId: string) => Promise<void>;
  resetExploration: (parameters: ExplorationParameters | null) => void;
  setCellById: (cell: Cell, cellId: string) => void;
  getCellById: (cellId: string) => Cell | undefined;
  duplicateCell: (index: number) => void;
  addCells: (cell: Cell[], index: number) => void;
  createCellInstance: (index: number) => void;
  deleteCell: (cellId: string) => void;
  moveCell: (
    fromIndex: number,
    toIndex: number,
    after?: boolean,
    mergeIntoRow?: boolean,
    height?: number,
  ) => void;
  moveCellUp: (index: number) => void;
  moveCellDown: (index: number) => void;
  setQueryMeta: (payload: SetQueryMetaAction['payload']) => void;
  getQueryMeta: (cellId: string) => QueryMeta | null;
  getFieldsByCellId: (cellId: string) => Fields;
  parameters: ExplorationParameters;
  setParameter: (key: keyof ExplorationParameters, value: ExplorationParameter) => void;
  getParameter: (key: keyof ExplorationParameters) => ExplorationParameter | undefined;
  getParameters: () => ExplorationParameters;
  getVariables: () => QueryVariables;
  clearParameter: (key: keyof ExplorationParameters) => void;
  isDirty: boolean;
  drillDownByProperty: (
    record: Record<string, unknown>,
    field: Field,
    cellId: string,
    timezone: string,
  ) => void;
}

const defaultContextValue = Symbol();

const ExplorationContext = createContext<ExplorationContextValue | typeof defaultContextValue>(
  defaultContextValue,
);

// Taken from https://github.com/DefinitelyTyped/DefinitelyTyped/pull/24509#issuecomment-1545820830
export const useExplorationContext = () => {
  const context = useContext(ExplorationContext);
  if (context === defaultContextValue) {
    throw new Error('useExplorationContext must be used within a ExplorationContextProvider');
  }
  return context;
};

export const ExplorationContextProvider = ({
  exploration,
  models,
  metrics,
  parameters,
  isDirty,
  setExploration,
  upsertExploration,
  deleteExploration,
  resetExploration,
  children,
}: {
  exploration: Exploration;
  models: Model[];
  metrics: MetricV2[];
  parameters: ExplorationParameters;
  isDirty: boolean;
  setExploration: (
    exploration: Exploration | null,
    parameters: ExplorationParameters | null,
  ) => void;
  upsertExploration: (
    exploration: Exploration,
    parameters: ExplorationParameters | null,
  ) => Promise<void>;
  deleteExploration: (explorationId: string) => Promise<void>;
  resetExploration: (parameters: ExplorationParameters | null) => void;
  children: React.ReactNode;
}) => {
  const screenSize = useScreenSize();
  const [state, dispatch] = useReducer(
    reducer,
    getInitialState(exploration, screenSize.breakpoint),
  );
  const { confirmUnsavedChagesIfNeeded } = useDirtyContext();

  const cells = exploration.view.cells;

  const getCellByIndex = (index: number) => nth(cells, index);

  const selectedCell =
    state.selectedCellIndex !== null ? (getCellByIndex(state.selectedCellIndex) ?? null) : null;

  const getCellById = (cellId: string) => cells.find((cell) => cell.id === cellId);

  const openEditor = (payload: OpenEditorAction['payload']) => {
    dispatch({ type: 'OPEN_EDITOR', payload });
  };

  const closeEditor = () => {
    confirmUnsavedChagesIfNeeded({
      onConfirm: () => {
        dispatch({ type: 'CLOSE_EDITOR' });
      },
    });
  };

  const selectCell = (cellIndex: number | null) => {
    dispatch({ type: 'SELECT_CELL', payload: { cellIndex } });
  };

  const deselectCell = () => {
    dispatch({ type: 'DESELECT_CELL' });
  };

  const openAddMenu = (payload: OpenAddMenuAction['payload']) => {
    confirmUnsavedChagesIfNeeded({
      onConfirm: () => {
        dispatch({ type: 'OPEN_ADD_MENU', payload });
      },
    });
  };

  const openAddForm = (payload: OpenAddFormAction['payload']) => {
    dispatch({ type: 'OPEN_ADD_FORM', payload });
  };

  const closeAddForm = () => {
    dispatch({ type: 'CLOSE_ADD_FORM' });
  };

  const openEditForm = (payload: OpenEditFormAction['payload']) => {
    confirmUnsavedChagesIfNeeded({
      onConfirm: () => {
        dispatch({ type: 'OPEN_EDIT_FORM', payload });
      },
    });
  };

  const closeEditForm = () => {
    dispatch({ type: 'CLOSE_EDIT_FORM' });
  };

  const scrollToCell = (cellIndex: number | null) => {
    dispatch({ type: 'SCROLL_TO_CELL', payload: { cellIndex } });
  };

  const setCellById = (updatedCell: Cell, cellId: string) => {
    const updatedExploration = {
      ...exploration,
      view: { cells: cells.map((cell) => (cell.id === cellId ? updatedCell : cell)) },
    };

    setExploration(updatedExploration, parameters);
  };

  const duplicateCell = (index: number) =>
    setExploration(duplicateExplorationCellAtIdx(exploration, index), parameters);

  const addCells = (cells: Cell[], index: number) =>
    setExploration(importExplorationCellsToExploration(exploration, cells, index), parameters);

  const createCellInstance = (index: number) =>
    setExploration(createExplorationCellInstanceAtIdx(exploration, index), parameters);

  const deleteCell = (cellId: string) =>
    setExploration(deleteExplorationCellById(exploration, cellId), parameters);

  const moveCellUp = (index: number) => {
    let newCells = cells;

    if (!isCellInMultiRow(cells, index)) {
      // Jump before first cell in previous row
      newCells = moveCell(cells, index, getRowStartIndex(cells, index - 1), false, false);
    } else if (isCellFirstInRow(cells, index)) {
      // Detach from row = jump before itself (with mergeIntoRow = false)
      newCells = moveCell(cells, index, index, false, false);
    } else {
      // Cell is non-first in multi-row: move within row
      newCells = swapCells(cells, index, index - 1);
    }
    setExploration(
      {
        ...exploration,
        view: {
          ...exploration.view,
          cells: ensureValidLayout(newCells),
        },
      },
      parameters,
    );
    if (selectedCell !== null) {
      selectCell(newCells.findIndex((cell) => cell.id === selectedCell.id));
    }
  };

  const moveCellDown = (index: number) => {
    let newCells = cells;

    if (!isCellInMultiRow(cells, index)) {
      // Jump after last cell in next row
      newCells = moveCell(cells, index, getRowEndIndex(cells, index + 1), true, false);
    } else if (isCellLastInRow(cells, index)) {
      // Detach from row = jump after itself (with mergeIntoRow = false)
      newCells = moveCell(cells, index, index, true, false);
    } else {
      // Cell is non-last in multi-row: move within row
      newCells = swapCells(cells, index, index + 1);
    }
    setExploration(
      {
        ...exploration,
        view: {
          ...exploration.view,
          cells: ensureValidLayout(newCells),
        },
      },
      parameters,
    );
    if (selectedCell !== null) {
      selectCell(newCells.findIndex((cell) => cell.id === selectedCell.id));
    }
  };

  const getQueryMeta = useCallback(
    (cellId: string) => get(state.queryMeta, cellId, null),
    [state.queryMeta],
  );

  const setQueryMeta = useCallback(
    (payload: SetQueryMetaAction['payload']) => dispatch({ type: 'SET_QUERY_META', payload }),
    [],
  );

  const setParameter = (key: keyof ExplorationParameters, value: ExplorationParameter) =>
    setExploration(isDirty ? exploration : null, { ...parameters, [key]: value });

  const getParameter = (key: keyof ExplorationParameters) => parameters[key];

  const clearParameter = (key: keyof ExplorationParameters) =>
    setExploration(isDirty ? exploration : null, omit(parameters, key));

  const getVariables = () =>
    getVariablesFromParameters(getVariableDefinitions(exploration), parameters);

  const drillDownByProperty = (
    record: Record<string, unknown>,
    field: Field,
    cellId: string,
    timezone: string,
  ) => {
    const fields = getQueryMeta(cellId)?.recordType ?? [];

    const updatedExploration = addDrillDownCellToExploration({
      record,
      properties: fields,
      property: field,
      exploration,
      cellId,
      timezone,
    });

    setExploration(updatedExploration, parameters);

    const cellIdx = getCellIndex(cellId, updatedExploration);

    scrollToCell(cellIdx + 1);
    selectCell(cellIdx + 1);
  };

  const cellCount = cells.length;

  // Validate selected cell index
  if (
    state.selectedCellIndex !== null &&
    (state.selectedCellIndex > cellCount - 1 ||
      !['records', 'funnel', 'cohort', 'variable', 'text', 'invalid'].includes(
        getCellByIndex(state.selectedCellIndex)?.kind ?? '',
      ))
  ) {
    deselectCell();
    return null;
  }

  return (
    <ExplorationContext.Provider
      value={{
        exploration,
        cellCount,
        selectedCellIndex: state.selectedCellIndex,
        selectedCell,
        isEditorOpen: state.isEditorOpen,
        scrollToIndex: state.scrollToIndex,
        addFormData: state.addFormData,
        editFormIndex: state.editFormIndex,
        openEditor,
        closeEditor,
        selectCell,
        deselectCell,
        openAddMenu,
        openAddForm,
        closeAddForm,
        openEditForm,
        closeEditForm,
        scrollToCell,
        setExploration: (exploration, newParameters) =>
          setExploration(exploration, newParameters === undefined ? parameters : newParameters),
        upsertExploration,
        deleteExploration,
        resetExploration,
        setCellById,
        getCellById,
        duplicateCell,
        addCells,
        createCellInstance,
        deleteCell,
        moveCell: (fromIndex, toIndex, after, mergeIntoRow = false, cellHeight) => {
          const targetCell = nth(cells, toIndex);
          let newCells = moveCell(cells, fromIndex, toIndex, after, mergeIntoRow);

          cellHeight = mergeIntoRow ? (cellHeight ?? targetCell?.viewOptions?.height) : undefined;
          newCells = updateCellRowHeight(newCells, toIndex, cellHeight);

          setExploration(
            {
              ...exploration,
              view: {
                ...exploration.view,
                cells: newCells,
              },
            },
            parameters,
          );

          if (selectedCell !== null) {
            selectCell(newCells.findIndex((cell) => cell.id === selectedCell.id));
          }
        },
        moveCellUp,
        moveCellDown,
        setQueryMeta,
        getQueryMeta,
        getFieldsByCellId: (cellId: string) =>
          getFieldsByCellId(cellId, exploration, models, getVariables(), metrics),
        parameters,
        setParameter,
        getParameter,
        getParameters: () => parameters,
        getVariables,
        clearParameter,
        isDirty,
        drillDownByProperty,
      }}>
      {children}
    </ExplorationContext.Provider>
  );
};
