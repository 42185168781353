import classNames from 'classnames';

import { Button } from '../button';

import styles from './form.module.scss';

export const TagSelect = ({
  options,
  value,
  onChange,
  isMultiSelect,
}: {
  options: { label: string; value: string }[];
  value: string[];
  onChange: (value: string[]) => void;
  isMultiSelect?: boolean;
}) => {
  const handleClickOption = (v: string) => {
    onChange(
      (isMultiSelect ?? false)
        ? options
            .filter(
              (o) =>
                (o.value !== v && value.includes(o.value)) || (o.value === v && !value.includes(v)),
            )
            .map((o) => o.value)
        : [v],
    );
  };
  return (
    <ul className={styles.tagSelect} aria-multiselectable={isMultiSelect}>
      {options.map(({ label, value: v }) => {
        const isSelected = value.includes(v);
        return (
          <li key={v} aria-selected={(isMultiSelect ?? false) ? isSelected : undefined}>
            <Button
              type="outlined"
              size="small"
              onClick={() => handleClickOption(v)}
              className={classNames(styles.tagButton, {
                [styles.active]: isSelected || !(isMultiSelect ?? false),
              })}>
              {label}
            </Button>
          </li>
        );
      })}
    </ul>
  );
};
