import { Option, SelectOption } from '@/components/form/types';

import {
  AddRelatedColumnOperation,
  Exploration,
  Field,
  Fields,
  Model,
  PipelineOperation,
  RelationAggregateOperation,
  Pipeline,
  MetricV2,
} from '../../types';
import { dereferencePipeline, FieldGroup, isFieldGroup } from '../../pipeline/utils';
import { getPipelineStateAtIndexOrThrow } from '../../pipeline/state';
import { getIconForField } from '../../model/utils';
import { getCellPipeline, getExplorationVariables, restoreInvalidCell } from '../../utils';

export const ensureLegalIdentifier = (name: string) => {
  return name.replace(/[^a-zA-Z0-9_]/g, '_').replace(/^([^a-zA-Z])/, '_$1');
};

/**
 * Make names safe-ish as column keys
 */
export const nameToKey = (name: string) => {
  return ensureLegalIdentifier(name).toLowerCase();
};

export const fieldToOption = (field: Field): Option => {
  return { label: field.name, value: field.key, icon: getIconForField(field) };
};

export const fieldToOptionGrouped = (field: Field | FieldGroup): SelectOption => {
  if (isFieldGroup(field)) {
    return {
      value: field.key,
      label: field.name ?? '',
      options: field.fields.map(fieldToOption),
    };
  }
  return { label: field.name, value: field.key, icon: getIconForField(field) };
};

export const isRecursiveOperation = (
  operation: PipelineOperation,
): operation is AddRelatedColumnOperation | RelationAggregateOperation =>
  operation.operation === 'addRelatedColumn' || operation.operation === 'relationAggregate';

export const replacePipeline = (
  exploration: Exploration,
  cellIndex: number,
  pipeline: Pipeline,
): Exploration => {
  const cell = restoreInvalidCell(exploration.view.cells[cellIndex]);
  if (!('pipeline' in cell)) {
    throw new Error('Attempting to update an operation on a cell without a pipeline');
  }
  const before = exploration.view.cells.slice(0, cellIndex);
  const after = exploration.view.cells.slice(cellIndex + 1);
  return {
    ...exploration,
    view: {
      ...exploration.view,
      cells: [
        ...before,
        {
          ...cell,
          pipeline,
        },
        ...after,
      ],
    },
  };
};

export const replaceOperations = (
  exploration: Exploration,
  cellIndex: number,
  operations: PipelineOperation[],
) => {
  const cell = exploration.view.cells[cellIndex];
  const pipeline = getCellPipeline(cell);
  if (pipeline === undefined) {
    throw new Error('Attempting to update an operation on a cell without a pipeline');
  }
  return replacePipeline(exploration, cellIndex, { ...pipeline, operations });
};

const applyOperationToPipeline = (
  exploration: Exploration,
  cellIndex: number,
  operationIndex: number,
  operation: PipelineOperation,
  models: Model[] = [],
  metrics: MetricV2[] = [],
  replace?: boolean,
): Exploration => {
  const cell = exploration.view.cells[cellIndex];
  const pipeline = getCellPipeline(cell);
  if (pipeline === undefined) {
    throw new Error('Attempting to update an operation on a cell without a pipeline');
  }

  const dereferencedPipeline = dereferencePipeline(
    {
      ...pipeline,
      operations: pipeline.operations.slice(0, operationIndex),
    },
    exploration,
  );
  const { fields } = getPipelineStateAtIndexOrThrow(
    dereferencedPipeline.baseModelId,
    dereferencedPipeline.operations,
    operationIndex,
    { models, variables: getExplorationVariables(exploration), metrics },
  );

  operation = ensureUniquePropertyKeysInOperation(operation, fields);

  const operations = pipeline.operations;
  const before = operations.slice(0, operationIndex);
  const after = operations.slice(operationIndex + (replace === true ? 1 : 0));
  return replaceOperations(exploration, cellIndex, [...before, operation, ...after]);
};

export const addOperation = (
  exploration: Exploration,
  models: Model[],
  metrics: MetricV2[],
  cellIndex: number,
  operationIndex: number,
  operation: PipelineOperation,
): Exploration => {
  return applyOperationToPipeline(
    exploration,
    cellIndex,
    operationIndex,
    operation,
    models,
    metrics,
  );
};

export const updateOperation = (
  exploration: Exploration,
  models: Model[],
  metrics: MetricV2[],
  cellIndex: number,
  operationIndex: number,
  operation: PipelineOperation,
): Exploration => {
  return applyOperationToPipeline(
    exploration,
    cellIndex,
    operationIndex,
    operation,
    models,
    metrics,
    true,
  );
};

export const removeOperation = (
  exploration: Exploration,
  cellIndex: number,
  operationIndex: number,
): Exploration => {
  const cell = exploration.view.cells[cellIndex];
  const pipeline = getCellPipeline(cell);
  if (pipeline === undefined) {
    throw new Error('Attempting to remove an operation from a cell without a pipeline');
  }

  return replaceOperations(exploration, cellIndex, [
    ...pipeline.operations.slice(0, operationIndex),
    ...pipeline.operations.slice(operationIndex + 1),
  ]);
};

export const moveOperation = (
  exploration: Exploration,
  cellIndex: number,
  fromIndex: number,
  toIndex: number,
): Exploration => {
  const cell = exploration.view.cells[cellIndex];
  if (!('pipeline' in cell)) {
    throw new Error('Attempting to remove an operation from a cell without a pipeline');
  }

  const tempPipeline = [
    ...cell.pipeline.operations.slice(0, fromIndex),
    ...cell.pipeline.operations.slice(fromIndex + 1),
  ];

  return replaceOperations(exploration, cellIndex, [
    ...tempPipeline.slice(0, toIndex),
    cell.pipeline.operations[fromIndex],
    ...tempPipeline.slice(toIndex),
  ]);
};

// Change the key argument so it does not conflict with existing keys in the fields.
export const ensureUniqueKey = (fields: Fields, key: string) => {
  let result = key;
  let iteration = 0;
  const originalKey = result;
  while (fields.map(({ key }) => key).includes(result) && iteration < 1000) {
    result = `${originalKey}_${iteration}`;
    iteration += 1;
  }

  return result;
};

export const ensureUniquePropertyKeysInOperation = (
  operation: PipelineOperation,
  fields: Field[],
) => {
  switch (operation.operation) {
    case 'addRelatedColumn':
      return {
        ...operation,
        parameters: {
          ...operation.parameters,
          columns: operation.parameters.columns.map((column) => {
            return {
              ...column,
              property: {
                ...column.property,
                key: ensureUniqueKey(fields, column.property.key),
              },
            };
          }),
        },
      };
    case 'groupAggregate':
      return {
        ...operation,
        parameters: {
          ...operation.parameters,
          aggregations: operation.parameters.aggregations.map((aggregation) => {
            return {
              ...aggregation,
              property: {
                ...aggregation.property,
                key: ensureUniqueKey(fields, aggregation.property.key),
              },
            };
          }),
        },
      };
    case 'relationAggregate':
      return {
        ...operation,
        parameters: {
          ...operation.parameters,
          aggregations: operation.parameters.aggregations.map((aggregation) => {
            return {
              ...aggregation,
              property: {
                ...aggregation.property,
                key: ensureUniqueKey(fields, aggregation.property.key),
              },
            };
          }),
        },
      };
    case 'deriveField':
      return {
        ...operation,
        parameters: {
          ...operation.parameters,
          key: ensureUniqueKey(fields, operation.parameters.key),
        },
      };
  }
  return operation;
};
