import { get, last } from 'lodash';

import { getNodes, useExplorationDataQuery } from '@/graphql';
import { GenericFallback } from '@/lib/error';
import { useQueryLoadCondition } from '@/lib/hooks';
import { flattenNestedList } from '@/explore/grouping';
import {
  DereferencedPipeline,
  Field,
  QueryVariables,
  Visualisation,
  isAggregatedVisualisation,
} from '@/explore/types';
import { filterVariablesForPipeline } from '@/explore/utils';
import { useMetadataContext } from '@/explore/metadata-context';
import { getDereferencedPipelineFields } from '@/explore/pipeline/state';
import { useExplorationCellContext } from '@/explore/exploration/exploration-cell-context';
import { sortDateGroupingsLast } from '@/explore/utils/grouping';

import { useAccountTimezone } from '@/lib/accounts/context';

import { isBigNumberWithComparison } from '../utils';
import { GroupedTimeSeriesChart } from '../../charts/timeseries-chart';
import { DataWarning } from '../data-warning';
import { getChartType, getQueryForHistogram, getQueryForVisualisation } from '../utils';

import { Histogram } from '../../charts/histogram';
import { VisualisationLoader } from '..';
import {
  generateGroupedCategoryData,
  generateGroupedTimeSeriesData,
} from '../../charts/grouped-chart/utils';

import { GroupedHorizontalBarChart } from '../../charts/horizontal-bar-chart';
import { BigNumber } from '../../charts/big-number';

import styles from './visualisation-graph.module.scss';

interface VisualisationGraphProps {
  visualisation: Visualisation;
  pipeline: DereferencedPipeline;
  accountId: string;
  variables: QueryVariables;
  fields: Field[];
  onCategroryClick?: (event: { clientX: number; clientY: number }, key: string, value: any) => void;
  onStackingChange?: (isStacked: boolean) => void;
  isInView: boolean;
}

export const VisualisationGraph = (props: VisualisationGraphProps) => {
  const {
    visualisation,
    pipeline,
    accountId,
    fields,
    variables,
    onCategroryClick,
    onStackingChange,
  } = props;

  const chartType = getChartType(fields, visualisation);
  const {
    pipeline: visualisationPipeline,
    sort,
    limit,
    groups: queryGroups,
  } = chartType === 'histogram'
    ? getQueryForHistogram(pipeline, visualisation)
    : getQueryForVisualisation(pipeline, fields, visualisation);
  const pipelineVariables = filterVariablesForPipeline(visualisationPipeline, props.variables);

  const timezone = useAccountTimezone();
  const { models, metricsV2: metrics } = useMetadataContext();
  const { isCollapsed } = useExplorationCellContext();
  const [onCompleted, onError, skip] = useQueryLoadCondition(props.isInView, !isCollapsed);

  const { data, error } = useExplorationDataQuery({
    variables: {
      baseModelId: visualisationPipeline.baseModelId,
      accountId,
      pipeline: visualisationPipeline.operations,
      sort,
      first: limit,
      variables: pipelineVariables,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip,
    onCompleted,
    onError,
  });

  const records = getNodes(data?.account?.query);
  const loaded = data?.account?.query !== undefined;

  if (error !== undefined) {
    return <GenericFallback />;
  }

  if (!loaded) {
    return <VisualisationLoader compact={chartType === 'single-value'} />;
  }

  const visualisationFields = getDereferencedPipelineFields(visualisationPipeline, {
    models,
    variables,
    metrics,
  });
  const mainAxisField = visualisationFields.find(
    (field) => field.key === visualisation.mainAxisKey,
  );

  if (chartType === 'histogram') {
    const fieldKey = get(last(visualisationPipeline.operations)?.parameters, 'key');
    return (
      <Histogram
        data={records}
        categoryLabel={fields.find((f) => f.key === fieldKey)?.name ?? ''}
        valueLabel={''}
      />
    );
  }

  const visRecords = queryGroups.length > 1 ? records : flattenNestedList(records);
  const hasNextPage = data?.account?.query?.pageInfo.hasNextPage ?? false;
  const bigNumberFieldKey = visualisation.viewOptions?.bigNumber?.key;
  const axesOptions = visualisation.viewOptions?.axes;

  const grouping = isAggregatedVisualisation(visualisation)
    ? queryGroups.map((group) => ({
        key: group.key,
        label: fields.find((field) => field.key === group.key)?.name ?? '',
      }))
    : visualisation.mainAxisKey !== undefined
      ? [
          {
            key: visualisation.mainAxisKey,
            label: mainAxisField?.name ?? '',
          },
        ]
      : [];

  const renderBigNumber = (compactMode = false) => {
    const bigNumberField =
      bigNumberFieldKey !== undefined
        ? visualisationFields.find((field) => field.key === bigNumberFieldKey)
        : undefined;

    if (bigNumberField === undefined) {
      return null;
    }

    if (isBigNumberWithComparison(visualisation.viewOptions?.bigNumber)) {
      const bigNumberComparisonKey = visualisation.viewOptions?.bigNumber?.comparisonKey;
      const bigNumberComparisonField =
        bigNumberComparisonKey !== undefined
          ? visualisationFields.find((field) => field.key === bigNumberComparisonKey)
          : undefined;

      return (
        <BigNumber
          field={bigNumberField}
          comparisonField={bigNumberComparisonField}
          direction={visualisation.viewOptions?.bigNumber?.comparisonDirection}
          showLabel={visualisation.viewOptions?.bigNumber?.showComparisonLabel}
          comparisonType={visualisation.viewOptions?.bigNumber?.comparisonType}
          data={visRecords}
          compact={compactMode}
        />
      );
    }

    return <BigNumber field={bigNumberField} data={visRecords} compact={compactMode} />;
  };

  const renderTimeSeriesChart = () => {
    const data = generateGroupedTimeSeriesData(
      records,
      visualisation,
      queryGroups,
      visualisationFields,
      axesOptions,
    );

    return (
      <>
        {bigNumberFieldKey !== undefined && (
          <div className={styles.chartOverlay}>{renderBigNumber()}</div>
        )}
        <GroupedTimeSeriesChart
          data={data}
          grouping={grouping}
          aggPeriod={
            isAggregatedVisualisation(visualisation)
              ? (sortDateGroupingsLast(visualisation.aggregation.groups).at(-1)?.precision ??
                'month')
              : (mainAxisField?.precision ?? 'month')
          }
          stacked={visualisation.viewOptions?.stacked === true}
          setStacked={onStackingChange}
          hideGrid={bigNumberFieldKey !== undefined}
          timezone={timezone}
        />
      </>
    );
  };

  const renderBarChart = () => {
    const data = generateGroupedCategoryData(
      records,
      visualisation,
      queryGroups,
      visualisationFields,
    );

    // Check if all values are integers to round the axis values
    const areAllValuesIntegers = visualisation.valueKeys
      .map((key) => visualisationFields.find((field) => field.key === key)!)
      .every((field) => field.type === 'Integer');

    return (
      <GroupedHorizontalBarChart
        data={data}
        grouping={grouping}
        stacked={visualisation.viewOptions?.stacked === true}
        setStacked={onStackingChange}
        onCategroryClick={onCategroryClick}
        roundTickValues={areAllValuesIntegers}
      />
    );
  };

  return (
    <>
      {hasNextPage && <DataWarning />}
      {chartType === 'single-value' && renderBigNumber(true)}
      {chartType === 'grouped-timeseries' && renderTimeSeriesChart()}
      {chartType === 'bar' && renderBarChart()}
    </>
  );
};
