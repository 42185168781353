import { common } from '@gosupersimple/types';

import { Option, SelectOption } from '../../components/form/types';
import { Pipeline } from '../types';

export interface EditCohortState {
  pipeline?: Pipeline;
  cohortId?: string;
  cohortTimeKey?: string;
  cohortTimeInterval?: common.CohortTimeInterval;
  eventTimeKey?: string;
  eventTimeInterval?: common.CohortTimeInterval;
}

export interface EditCohortOptions {
  title: string;
  idFields: Option[];
  cohortTimeKeyFields: SelectOption[];
  eventTimeKeyFields: SelectOption[];
}

export const timeKeys = ['cohortTimeKey', 'eventTimeKey'] as const;
