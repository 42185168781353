import { useState } from 'react';

import { first } from 'lodash';

import { Form } from '@/components/form';
import { Select } from '@/components/form/select';
import { Button } from '@/components/button';

import type { Model, Relation, SwitchToRelationOperation } from '../types';

import { useDirtyContext } from '../dirty-context';

import { createRelationOptions } from './utils/relation';

import form from '@/components/form/form.module.scss';

interface SwitchToRelationFormProps {
  model: Model;
  models: Model[];
  operation?: SwitchToRelationOperation;
  setOperation(operation: SwitchToRelationOperation): void;
  onClose(): void;
  relations: Relation[];
}

const generateOptionValue = (modelId: string, relationKey: string) => `${modelId}.${relationKey}`;

const explodeOptionValue = (optionValue: string) => {
  const [modelId, relationKey] = optionValue.split('.');
  return { modelId, relationKey };
};

export const SwitchToRelationForm = (props: SwitchToRelationFormProps) => {
  const relationOptions = createRelationOptions(props.relations, props.model, props.models);
  const defaultOptionValue = first(relationOptions)?.value;
  const initialOptionValue =
    props.operation?.parameters !== undefined
      ? generateOptionValue(props.model.modelId, props.operation?.parameters.relation.key)
      : defaultOptionValue;
  const [optionValue, setOptionValue] = useState(initialOptionValue);
  const { setDirty } = useDirtyContext();

  if (optionValue === undefined) {
    return (
      <div className={form.formHorizontal}>
        <div>No available relations</div>
        <div>
          <Button onClick={props.onClose}>Close</Button>
        </div>
      </div>
    );
  }

  const handleChange = (value: string) => {
    setOptionValue(value);
    const isDirty = initialOptionValue !== value;
    setDirty(isDirty);
  };

  const handleSubmit = () => {
    setDirty(false);
    const { modelId, relationKey } = explodeOptionValue(optionValue);
    props.setOperation({
      operation: 'switchToRelation',
      parameters: { relation: { key: relationKey, modelId } },
    });
  };

  const handleCancel = () => {
    setDirty(false);
    props.onClose();
  };

  return (
    <Form className={form.formHorizontal} onSubmit={handleSubmit}>
      <div className={form.formRow}>
        <label className={form.formLabel}>From</label>
        <Select
          size="small"
          options={relationOptions}
          value={optionValue}
          onChange={(option) => {
            if (option !== null) {
              handleChange(option);
            }
          }}
          autoFocus
        />
      </div>

      <div className={form.formControls}>
        <Button size="small" htmlType="submit">
          {props.operation ? 'Save' : 'Jump to'}
        </Button>
        <Button size="small" type="outlined" onClick={handleCancel}>
          {props.operation ? 'Cancel' : 'Back'}
        </Button>
      </div>
    </Form>
  );
};
