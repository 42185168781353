import { RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { throttle } from 'lodash';

import { countRecords, sliceNestedList } from '@/explore/grouping';

import { DataTableRow } from '../datatable';
import { PageIndexes } from './types';

const MaxItems = 500;

export type SetFinalIndexesFn = (indexes: PageIndexes) => void;

/**
 * Get the initial internal indexes for generating slices.
 * Use the previously detected pageSize as an optimization for determining the new correct page size (which is likely the same)
 */
const getInitialIndexes = (
  indexes: PageIndexes,
  records: DataTableRow[],
  pageSize = 0,
): {
  startIndex: number;
  endIndex: number;
} => {
  if (indexes.endIndex === null) {
    return {
      startIndex: indexes.startIndex,
      endIndex: indexes.startIndex + Math.max(1, pageSize) + 1,
    };
  } else if (indexes.startIndex === null) {
    // Ensure going to the 'previous' page will produce a full page even if the container has grown
    const adjustedEndIndex = Math.min(
      records.length,
      Math.max(indexes.endIndex as number, pageSize),
    );
    return {
      startIndex: Math.max(0, adjustedEndIndex - pageSize - 1),
      endIndex: adjustedEndIndex,
    };
  }
  return indexes;
};

const getChildrenHeight = (container: HTMLElement) => {
  return Array.from(container.children).reduce((acc, el) => acc + el.clientHeight, 0);
};

/**
 * A hook for paginating records to fit the container by generating slices and measuring the rendered output until a "maximum slice" is found.
 * If the container does not have a specified height we default to the old behaviour of slicing N items.
 *
 * The startIndex and endIndex parameters define the indexes for 'current page' of the records.
 * One of the indexes can be passed as null. This is the 'loose' index and is then determined by how many items fit into the container.
 * The other then serves as an 'anchor' index.
 */
export const useFitContainerSlice = (
  containerRef: RefObject<HTMLElement>,
  records: DataTableRow[],
  indexes: PageIndexes,
  isHeightDefined: boolean,
  setFinalIndexes: SetFinalIndexesFn,
  defaultPageSize = 10,
  isLoading = false,
): DataTableRow[] => {
  const { startIndex, endIndex } = indexes;
  const isResolvingForwards = endIndex === null;
  const isResolvingBackwards = startIndex === null;

  if (isResolvingBackwards && isResolvingForwards) {
    throw new Error('Either startIndex or endIndex must be provided');
  }

  const heightRef = useRef<number | null>(null);
  const pageSizeRef = useRef<number>(0);

  // Internal non-null indexes for generating slices
  const [internalIndexes, setInternalIndexes] = useState(getInitialIndexes(indexes, records));
  // Denotes that if the next slice does not exceed the container bounds, it is the final result
  const [hasExceededContainerBounds, setHasExceededContainerBounds] = useState(false);

  if (!isHeightDefined && (isResolvingBackwards || isResolvingForwards)) {
    // If no fixed height, we default to the old behaviour of slicing N items
    setFinalIndexes({
      startIndex: isResolvingForwards ? startIndex : endIndex - defaultPageSize,
      endIndex: isResolvingBackwards ? endIndex : startIndex + defaultPageSize,
    });
    setHasExceededContainerBounds(false);
  }

  const hasAnchorIndexChanged =
    (isResolvingForwards && startIndex !== internalIndexes.startIndex) ||
    (isResolvingBackwards && endIndex !== internalIndexes.endIndex);

  if (isHeightDefined && hasAnchorIndexChanged) {
    // Reset internal state for a new run
    const initialIndexes = getInitialIndexes(indexes, records, pageSizeRef.current);
    setInternalIndexes(initialIndexes);
    // sync the anchor in 'input' indexes in case the anchor has changed
    setFinalIndexes(
      startIndex === null
        ? {
            startIndex: null,
            endIndex: initialIndexes.endIndex,
          }
        : {
            startIndex: initialIndexes.startIndex,
            endIndex: null,
          },
    );
  }

  useLayoutEffect(() => {
    const container = containerRef.current;
    if (container === null || !isHeightDefined || isLoading) {
      return;
    }
    if ((!isResolvingForwards && !isResolvingBackwards) || hasAnchorIndexChanged) {
      // Indexes already found or have just been re-set
      return;
    }

    const recordsCount = countRecords(records);
    const itemCount = internalIndexes.endIndex - internalIndexes.startIndex;
    const isExceedingContainerBounds = container.scrollHeight > container.clientHeight;
    const canExpandIndexes =
      !isExceedingContainerBounds &&
      !hasExceededContainerBounds &&
      (!isResolvingBackwards || internalIndexes.startIndex > 0) &&
      (!isResolvingForwards || internalIndexes.endIndex < recordsCount) &&
      itemCount < MaxItems;

    // Expand or contract the loose index until we find the maximum slice that fits the container
    if (canExpandIndexes) {
      // Expand indexes and rerender
      // As an optimization we estimate the correct page size based on the average item height
      const avgItemHeight = getChildrenHeight(container) / itemCount;
      const maxItemsEstimate = Math.ceil(container.clientHeight / avgItemHeight);
      const expandIndexesBy = Math.max(1, maxItemsEstimate - itemCount);
      setInternalIndexes({
        startIndex: isResolvingForwards
          ? internalIndexes.startIndex
          : internalIndexes.startIndex - expandIndexesBy,
        endIndex: isResolvingBackwards
          ? internalIndexes.endIndex
          : internalIndexes.endIndex + expandIndexesBy,
      });
    } else if (isExceedingContainerBounds && itemCount > 1) {
      // Contract loose index by one and mark the next non-exceeding pass as final result
      setHasExceededContainerBounds(true);
      setInternalIndexes({
        startIndex: isResolvingForwards
          ? internalIndexes.startIndex
          : internalIndexes.startIndex + 1,
        endIndex: isResolvingBackwards ? internalIndexes.endIndex : internalIndexes.endIndex - 1,
      });
    } else {
      // We have found the maximum slice that fits the container
      pageSizeRef.current = itemCount;
      setHasExceededContainerBounds(false);
      setFinalIndexes(internalIndexes);
    }
  }, [
    containerRef,
    defaultPageSize,
    endIndex,
    hasAnchorIndexChanged,
    hasExceededContainerBounds,
    internalIndexes,
    isResolvingBackwards,
    isResolvingForwards,
    isHeightDefined,
    records.length,
    setFinalIndexes,
    startIndex,
    records,
    isLoading,
  ]);

  useEffect(() => {
    const container = containerRef.current;
    if (container === null || !isHeightDefined || isLoading) {
      return;
    }
    if (heightRef.current === null) {
      heightRef.current = container.clientHeight;
    }

    const observer = new ResizeObserver(
      throttle(() => {
        if (
          heightRef.current !== container.clientHeight &&
          !isResolvingBackwards &&
          !isResolvingForwards
        ) {
          // Height has changed, so let's find the new page size by finding a new endIndex
          // Using the retained page size helps minimize the number of re-renders
          setFinalIndexes({
            startIndex: internalIndexes.startIndex,
            endIndex: null,
          });
          setHasExceededContainerBounds(false);
        }
        heightRef.current = container.clientHeight;
      }, 100),
    );

    observer.observe(container);
    return () => observer.disconnect();
  }, [
    containerRef,
    internalIndexes.startIndex,
    isResolvingBackwards,
    isResolvingForwards,
    isHeightDefined,
    setFinalIndexes,
    isLoading,
  ]);

  if (isResolvingBackwards || isResolvingForwards) {
    return sliceNestedList(records, internalIndexes.startIndex, internalIndexes.endIndex);
  }

  return sliceNestedList(records, startIndex, endIndex);
};
