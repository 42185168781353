import { useState } from 'react';

import { Modal } from '@/components/modal';
import { Panel } from '@/components/panel';
import { Button } from '@/components/button';

import styles from './create-template-modal.module.scss';

interface CreateTemplateModalProps {
  modelName: string;
  onCreate: () => Promise<void>;
  onClose: () => void;
}

export const CreateTemplateModal = (props: CreateTemplateModalProps) => {
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    setLoading(true);
    await props.onCreate();
    setLoading(false);
  };

  return (
    <Modal onClose={props.onClose} closeOnClickAway>
      <Panel className={styles.panel}>
        <div className={styles.header}>
          <h1>Are you sure you want to save changes to the data template</h1>
        </div>
        <div className={styles.body}>
          <p>
            This will update the template for all the detail views in model &quot;{props.modelName}
            &quot;
          </p>

          <div>
            <Button htmlType="submit" size="large" onClick={handleSubmit} loading={loading}>
              Save changes
            </Button>
            <Button type="secondary" size="large" onClick={props.onClose}>
              Cancel
            </Button>
          </div>
        </div>
      </Panel>
    </Modal>
  );
};
