import { first, nth } from 'lodash';

import {
  isPropToken,
  Scanner,
  serializeTokens,
  Token,
  TokenType,
  walkTokens,
} from '@gosupersimple/penguino';

import { Field, Fields } from '../types';
import { getRelatedFieldName } from '../edit-pipeline/utils/format';
import { FieldGroup } from '../pipeline/utils';

export class HumanizedPenguinoError extends Error {
  constructor(
    public readonly originalError: Error,
    public readonly name: string,
    public readonly start: number,
    public readonly end: number,
    public readonly line: number,
  ) {
    super(originalError.message);
  }
}

/**
 * Generates fields with prefixed keys and suffixed names to be able to *evaluate* prop() calls such as:
 * - `prop(myTable.myField)`
 * - `prop(myField)` for ungrouped fields
 * - `prop("My Field (on ModelOrRelationName")`
 */
export const convertFieldsForPenguinoContext = (groups: FieldGroup[]): Fields => {
  return groups.flatMap((group) => {
    return group.fields.map((field) => convertFieldForPenguinoContext(field, group));
  });
};

/**
 * Generates a prefix for the key and a suffix for the name to be able to *create* a prop() call from the field.
 * The passed group represents a table in penguino/prql context.
 * Examples:
 * - `prop(myTable.myField)`
 * - `prop("My Field (on ModelOrRelationName")`
 * - `prop(myField)` if no group is passed or it has no key
 * - `prop("My Field")` if no group is passed
 */
export const convertFieldForPenguinoContext = (field: Field, group?: FieldGroup): Field => {
  return {
    ...field,
    key: group?.key !== undefined ? `${group.key}.${field.key}` : field.key,
    name: group?.name !== undefined ? getRelatedFieldName(field.name, group.name) : field.name,
  };
};

const getFieldByPropArguments = (tokens: Token[], fields: Fields): Field | undefined => {
  if (first(tokens)?.type === TokenType.Identifier) {
    const key = serializeTokens(tokens);
    return fields.find((field) => field.key === key);
  } else if (tokens.length === 1 && first(tokens)?.type === TokenType.String) {
    return fields.find((field) => field.name === first(tokens)?.literal);
  }
  return undefined;
};

export const humanizeExpression = (expression: string, fields: Fields): string => {
  return serializeTokens(
    humanizeExpressionTokens(
      new Scanner(expression, { safe: true }).scanTokens(),
      convertFieldsForPenguinoContext([{ fields }]),
    ),
  );
};

/**
 * Convert raw penguino into a more human-readable format.
 * - prop(myTable.myField) becomes prop("My Field (on My Table)")
 */
export const humanizeExpressionTokens = (tokens: Token[], fields: Fields): Token[] => {
  return walkTokens(tokens, (token, childrenWithParens) => {
    if (!isPropToken(token)) {
      return [token, ...childrenWithParens];
    }
    const field = getFieldByPropArguments(childrenWithParens.slice(1, -1), fields);
    if (field === undefined) {
      return [token, ...childrenWithParens];
    }
    return [
      token,
      { type: TokenType.LeftParen, lexeme: '(' },
      {
        type: TokenType.String,
        lexeme: `"${field.name}"`,
        literal: field.name,
      },
      { type: TokenType.RightParen, lexeme: ')' },
    ];
  });
};

/**
 * Convert human-readable penguino into raw penguiono.
 * - prop("My Field (on My Table)") becomes prop(myTable.myField)
 */
export const dehumanizeExpressionTokens = (tokens: Token[], fields: Fields): Token[] => {
  return walkTokens(tokens, (token, childrenWithParens) => {
    if (!isPropToken(token)) {
      return [token, ...childrenWithParens];
    }
    const field = getFieldByPropArguments(childrenWithParens.slice(1, -1), fields);
    if (field === undefined) {
      return [token, ...childrenWithParens];
    }
    const [key, table] = field.key.split('.').reverse();
    return [
      token,
      { type: TokenType.LeftParen, lexeme: '(' },
      ...(table !== undefined
        ? [
            {
              type: TokenType.Identifier,
              lexeme: `${table}`,
            },
            {
              type: TokenType.Period,
              lexeme: `.`,
            },
          ]
        : []),
      {
        type: TokenType.Identifier,
        lexeme: `${key}`,
      },
      { type: TokenType.RightParen, lexeme: ')' },
    ];
  });
};

/**
 * Adjust the position indicators of a PenguinoError so the error applies to the humanized form of the expression.
 */
export const adjustForHumanizedPenguino = <
  T extends { name: string; message: string; start: number; end: number; line: number },
>(
  e: T,
  originalTokens: Token[],
  fields: Fields,
): HumanizedPenguinoError => {
  let shiftAmount = 0;
  let newLength = e.end - e.start;
  walkTokens(originalTokens, (token, childrenWithParens) => {
    if (!isPropToken(token) || nth(childrenWithParens, 1)?.type !== TokenType.Identifier) {
      return [token, ...childrenWithParens];
    }
    const field = getFieldByPropArguments(childrenWithParens.slice(1, -1), fields);
    const argumentStart = nth(childrenWithParens, 1)?.start ?? 0;
    const fieldNameLexeme = `"${field?.name}"`;
    if (field !== undefined) {
      if (e.start + shiftAmount <= argumentStart) {
        newLength = fieldNameLexeme.length;
      } else {
        shiftAmount += fieldNameLexeme.length - field.key.length;
      }
    }
    return [token, ...childrenWithParens];
  });

  return new HumanizedPenguinoError(
    e,
    e.name,
    e.start + shiftAmount,
    e.start + shiftAmount + newLength,
    e.line,
  );
};
