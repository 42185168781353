import { useState } from 'react';
import { first } from 'lodash';

import { Form } from '@/components/form';
import { Button } from '@/components/button';
import { Select } from '@/components/form/select';

import { timePrecisionOptions } from './groupings-editor';
import { AggregationExtendedType, TimeAggregationPeriod, TimeSeriesOperation } from '../types';
import { fieldToOption } from './utils';
import {
  aggregationExtendedTypeToAggregationType,
  getAggregationOptionsForFields,
  getFieldOptionsForAggregationType,
} from '../utils/aggregation';

import form from '@/components/form/form.module.scss';

enum FieldType {
  'DATE' = 'Date',
}

interface TimeSeriesProps {
  fields: { key: string; name: string; type: FieldType | string }[];
}

interface TimeSeriesFormProps extends TimeSeriesProps {
  operation?: TimeSeriesOperation;
  setOperation(operation: TimeSeriesOperation): void;
  onClose: () => void;
}

export const TimeSeriesForm = (props: TimeSeriesFormProps) => {
  const { fields } = props;

  const [aggregationType, setAggregationType] = useState(
    props.operation?.parameters.aggregationType ?? 'count',
  );
  const [aggregationTarget, setAggregationTarget] = useState(
    props.operation?.parameters.aggregationTarget,
  );
  const [timeField, setTimeField] = useState(props.operation?.parameters.timeField ?? '');
  const [timeInterval, setTimeInterval] = useState(
    props.operation?.parameters.timeInterval ?? 'day',
  );

  const allowedAggregationOptions = getAggregationOptionsForFields(fields).filter(
    (option) => !['custom', 'earliest', 'latest'].includes(option.value),
  );

  const aggregationTargetOptions = getFieldOptionsForAggregationType(aggregationType, fields);

  const handleSubmit = () => {
    props.setOperation({
      operation: 'timeSeries',
      parameters: {
        aggregationType,
        aggregationTarget: aggregationType === 'count' ? undefined : aggregationTarget,
        timeField,
        timeFieldType: 't_date',
        timeInterval,
      },
    });
  };

  return (
    <Form className={form.formHorizontal} onSubmit={handleSubmit}>
      <div className={form.formRow}>
        <div className={form.formRowInner}>
          <label className={form.formLabel}>Aggregation</label>
          <Select
            options={allowedAggregationOptions}
            value={aggregationType}
            onChange={(value) => {
              const type = value as AggregationExtendedType;
              const aggregationTargetOptions = getFieldOptionsForAggregationType(type, fields);
              const currentTargetIsValid = aggregationTargetOptions.some(
                (field) => field.value === aggregationTarget,
              );
              setAggregationType(aggregationExtendedTypeToAggregationType(type));
              if (currentTargetIsValid === false) {
                setAggregationTarget(first(aggregationTargetOptions)?.value);
              }
            }}
          />
        </div>
        {aggregationType !== 'count' && (
          <div className={form.formRowInner}>
            <label className={form.formLabel}>Field Name</label>
            <Select
              options={aggregationTargetOptions}
              value={aggregationTarget}
              onChange={setAggregationTarget}
            />
          </div>
        )}
      </div>
      <div className={form.formRow}>
        <div className={form.formRowInner}>
          <label className={form.formLabel}>Time field</label>
          <Select
            options={props.fields.filter(({ type }) => type === FieldType.DATE).map(fieldToOption)}
            value={timeField}
            onChange={setTimeField}
          />
        </div>
        <div className={form.formRowInner}>
          <label className={form.formLabel}>Time interval</label>
          <Select
            options={timePrecisionOptions}
            value={timeInterval}
            onChange={(value) => setTimeInterval(value as TimeAggregationPeriod)}
          />
        </div>
      </div>
      <div>
        <Button size="small" htmlType="submit">
          {props.operation ? 'Save' : 'Add'}
        </Button>
        <Button size="small" type="outlined" onClick={props.onClose}>
          {props.operation ? 'Cancel' : 'Back'}
        </Button>
      </div>
    </Form>
  );
};
