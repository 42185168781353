import { useEffect } from 'react';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';

import { useMetricsQuery, IntegrationService, timePrecisionToGql } from '@/graphql';
import { useTrackEvent } from '@/lib/analytics';
import { useSelectedAccount } from '@/lib/accounts/context';
import { TimePrecision } from '@/lib/types';
import { useTitle } from '@/lib/hooks/use-title';

import { MetricTile } from '../../components/metric-tile';
import { MetricTimeOptions, useMetricTimeOptions } from './components/metric-time-options';
import { Metric } from '../types';
import { Loader } from '../../components/loader';

import styles from './metrics.module.scss';

interface MetricsProps {
  accountId: string;
  aggPeriod: TimePrecision;
  setAggPeriod: (newVal: TimePrecision) => void;
  isCumulative: boolean;
  setIsCumulative: (newVal: boolean) => void;
  fromDate: Date;
  toDate: Date;
}

const Metrics = (props: MetricsProps) => {
  const { accountId, aggPeriod, isCumulative, fromDate, toDate } = props;

  const trackEvent = useTrackEvent();
  const { data, error, loading } = useMetricsQuery({
    variables: { accountId, aggregation: timePrecisionToGql(aggPeriod) },
    nextFetchPolicy: 'cache-first',
  });

  useEffect(() => trackEvent('Metrics Page opened'), [trackEvent]);

  if (error !== undefined) {
    return <div>error: {JSON.stringify(error)}</div>;
  }
  const showLoading = !data && loading;
  if (showLoading) {
    return <Loader />;
  }
  if (!data) {
    return <div>Backend returned no data :(</div>;
  }
  const account = data.account;
  if (!account) {
    return <div>did not find account</div>;
  }

  const sortedMetrics = sortBy(account.metrics, (metric) => {
    const { services } = metric;
    if (['CONVERSION_RATE', 'HOURLY_CONVERSION_RATE'].includes(metric.metricType)) {
      return 1;
    }
    if (
      ['NUM_ORDERS', 'TOTAL_SALES', 'HOURLY_NUM_ORDERS', 'HOURLY_SALES'].includes(metric.metricType)
    ) {
      return 0;
    }
    if (services.includes(IntegrationService.GoogleAnalytics)) {
      return 2;
    }
    if (
      services.includes(IntegrationService.Shopify) ||
      services.includes(IntegrationService.Woocommerce)
    ) {
      // all other ecommerce metrics
      return 3;
    }
    if (
      services.includes(IntegrationService.FacebookAds) ||
      services.includes(IntegrationService.GoogleAds)
    ) {
      return 4;
    }
    if (services.includes(IntegrationService.FacebookPage)) {
      if (metric.metricType.includes('INSTAGRAM')) {
        return 6;
      }
      return 5;
    }
    return metric.category;
  });

  if (sortedMetrics.length === 0) {
    return <div>No metrics to show yet!</div>;
  }

  const groupedMetrics = groupBy(sortedMetrics, (metric) => metric.category || 'Uncategorized');

  return (
    <>
      {Object.keys(groupedMetrics).map((group) => (
        <div className={styles.category} key={group}>
          <h3>{group}</h3>
          <div className={styles.metricsGrid}>
            {groupedMetrics[group].map((metric) => (
              <MetricTile
                key={metric.metricId}
                metric={metric as Metric}
                accountId={account.accountId}
                startDate={fromDate}
                endDate={toDate}
                aggPeriod={aggPeriod}
                isCumulative={isCumulative}
                allMetrics={sortedMetrics as Metric[]}
                modelNames={account.models}
              />
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export const MetricsPage = () => {
  useTitle('Metrics');
  const metricTimeOptions = useMetricTimeOptions();
  const account = useSelectedAccount();

  return (
    <div className={styles.metricsPage}>
      <h1>Metrics</h1>
      <MetricTimeOptions {...metricTimeOptions} />
      <Metrics {...metricTimeOptions} accountId={account.accountId} />
    </div>
  );
};
