import { compact, isNil, omitBy } from 'lodash';

import {
  ExplorationFragmentFragment,
  ModelFragmentFragment,
  PipelineOperator,
  QueryPipeline,
  RecordTypeFragmentFragment,
  AlertConfiguration as GraphQLAlertConfiguration,
} from '../graphql';

import {
  AggregatedVisualisation,
  DereferencedPipeline,
  Exploration,
  Fields,
  Metric,
  Model,
  Pipeline,
  PipelineOperation,
  RecordsCell,
  Visualisation,
  cellViewOptions,
  deprecatedMetricSchema,
  timeAggregationPeriodZod,
  visualisationZod,
} from './types';
import { AlertConfiguration } from '../settings/types';

import { visualisationKeysFromAggregation } from './components/visualisation/utils';

export const convertRecordTypeTypes = (
  input?: RecordTypeFragmentFragment[],
): Fields | undefined => {
  return input?.map((i) => ({
    key: i.key,
    type: i.type,
    name: i.name,
    pk: i.pk ?? undefined,
    relation: !isNil(i.relation)
      ? {
          modelId: i.relation.modelId,
          key: i.relation.key,
          name: i.relation.name,
        }
      : undefined,
    model: !isNil(i.model)
      ? {
          modelId: i.model.modelId,
          name: i.model.name,
          propertyKey: i.model.propertyKey,
        }
      : undefined,
    precision: isNil(i.precision) ? undefined : timeAggregationPeriodZod.parse(i.precision),
  }));
};

export const convertModelTypes = (input?: ModelFragmentFragment[]): Model[] | undefined => {
  return input?.map((i) => ({
    modelId: i.modelId,
    name: i.name,
    description: i.description ?? undefined,
    primaryKey: i.primaryKey,
    labels: i.labels,
    properties: i.properties.map((p) => ({
      name: p.name,
      type: p.type,
      key: p.key,
    })),
    relations: i.relations.map((r) => {
      return {
        key: r.key,
        modelId: r.modelId,
        type: r.type,
        name: r.name,
        joinStrategy:
          !isNil(r.joinStrategy.joinKeyOnBase) && !isNil(r.joinStrategy.joinKeyOnRelated)
            ? {
                joinKeyOnBase: r.joinStrategy.joinKeyOnBase,
                joinKeyOnRelated: r.joinStrategy.joinKeyOnRelated,
                through: !isNil(r.joinStrategy.through)
                  ? {
                      modelId: r.joinStrategy.through.modelId,
                      joinKeyToBase: !isNil(r.joinStrategy.through.joinKeyToBase)
                        ? r.joinStrategy.through.joinKeyToBase
                        : undefined,
                      joinKeyToRelated: !isNil(r.joinStrategy.through.joinKeyToRelated)
                        ? r.joinStrategy.through.joinKeyToRelated
                        : undefined,
                    }
                  : undefined,
              }
            : !isNil(r.joinStrategy.steps)
              ? {
                  steps: r.joinStrategy.steps.map((step) => ({
                    relationKey: step.relationKey,
                  })),
                }
              : undefined,
      };
    }),
    semantics: i.semantics,
  }));
};

/**
 * Temporary backwards-compatibility
 */
const convertRelationKeys = (input: Record<string, any>): Record<string, any> => {
  // Convert inner pipelines
  if ('pipeline' in input.parameters) {
    input = {
      ...input,
      parameters: {
        ...input.parameters,
        pipeline: {
          ...input.parameters.pipeline,
          operations: input.parameters.pipeline.operations.map(convertRelationKeys),
        },
      },
    };
  }
  switch (input.operation) {
    case 'switchToRelation':
    case 'relationAggregate':
    case 'addRelatedColumn':
      if (!('relationKey' in input.parameters)) {
        return input;
      }
      return {
        ...input,
        parameters: {
          ...input.parameters,
          relation: { key: input.parameters.relationKey },
        },
      };
    default:
      return input;
  }
};

const convertPipelineOperationTypes = (operation: PipelineOperator): PipelineOperation =>
  convertRelationKeys(
    omitBy(
      {
        operation: operation.operation,
        parameters: operation.parameters,
        disabled: operation.disabled,
      },
      isNil,
    ),
  ) as PipelineOperation;

const convertPipelineTypes = (input?: QueryPipeline): Pipeline =>
  omitBy(
    {
      baseModelId: input?.baseModelId,
      operations: (input?.operations ?? []).map(convertPipelineOperationTypes),
      pipelineId: input?.pipelineId,
      parentId: input?.parentId,
    },
    isNil,
  ) as Pipeline;

export const convertVisualisation = (input: any): Visualisation => {
  const sanitizedInput = {
    ...input,
    aggregation: input.aggregation ?? undefined,
    mainAxisKey: input.mainAxisKey ?? undefined,
  };

  const {
    aggregationType,
    aggregationTarget,
    groupByField,
    timeGranularity,
    viewOptions,
    aggregations,
    groups,
    valueKeys,
  } = sanitizedInput;

  if (!isNil(valueKeys)) {
    return visualisationZod.parse(sanitizedInput);
  }

  // If aggregations are provided, we assume that the visualisation is already using the new schema
  if (!isNil(aggregations)) {
    const aggregation = {
      aggregations,
      groups,
    };
    return aggregations.length > 0
      ? {
          aggregation,
          ...visualisationKeysFromAggregation(aggregation),
          viewOptions: viewOptions ?? {},
        }
      : {
          valueKeys: [],
          viewOptions: viewOptions ?? {},
        };
  }

  // Migration logic for old visualisations
  const fallbackVisualisation: AggregatedVisualisation = {
    aggregation: {
      aggregations: [
        {
          type: 'count',
          property: {
            key: 'count',
            name: 'count',
          },
        },
      ],
      groups: [{ key: 'tmp' }], // tmp is replaced with default grouping in ensure valid groupings
    },
    mainAxisKey: 'tmp',
    valueKeys: ['count'],
    viewOptions: {},
  };

  if (aggregationType === null) {
    return fallbackVisualisation;
  }

  const aggregation = {
    aggregations: [
      {
        type: aggregationType,
        ...(isNil(aggregationTarget) ? {} : { key: aggregationTarget }),
        property: {
          key: aggregationType,
          name: aggregationType,
        },
      },
    ],
    groups: [
      {
        key: groupByField ?? 'tmp', // tmp is replaced with default grouping in ensure valid groupings
        ...(isNil(timeGranularity) ? {} : { fill: true, precision: timeGranularity }),
      },
    ],
  };

  const visualisation = visualisationZod.catch(fallbackVisualisation).parse({
    aggregation,
    ...visualisationKeysFromAggregation(aggregation),
    viewOptions: viewOptions ?? {},
  });

  return visualisation;
};

export const convertExplorationTypes = (
  input?: ExplorationFragmentFragment | null,
): Exploration | undefined =>
  isNil(input)
    ? undefined
    : {
        explorationId: input.explorationId,
        name: input.name,
        description: input.description ?? undefined,
        labels: input.labels,
        parameters: input.parameters,
        view: {
          cells: input.view.cells.map((c) => {
            switch (c.__typename) {
              case 'MetricViewCell':
                return {
                  id: c.id,
                  kind: 'metric',
                  metricId: c.metricId,
                  viewOptions:
                    c.viewOptions !== null ? cellViewOptions.parse(c.viewOptions) : undefined,
                };
              case 'RecordsViewCell':
                return {
                  id: c.id,
                  kind: c.kind,
                  title: c.title,
                  excludeProperties: c.excludeProperties,
                  pipeline: convertPipelineTypes(c.pipeline),
                  visualisations: (c.visualisations ?? []).map(convertVisualisation),
                  sort: c.sort ?? [],
                  viewOptions:
                    c.viewOptions !== null ? cellViewOptions.parse(c.viewOptions) : undefined,
                } as RecordsCell;
              case 'FunnelViewCell':
                return {
                  id: c.id,
                  kind: 'funnel',
                  title: c.title,
                  pipeline: convertPipelineTypes(c.pipeline),
                  viewOptions:
                    c.viewOptions !== null ? cellViewOptions.parse(c.viewOptions) : undefined,
                };
              case 'CohortViewCell':
                return {
                  id: c.id,
                  kind: 'cohort',
                  title: c.title,
                  pipeline: convertPipelineTypes(c.pipeline),
                  viewOptions:
                    c.viewOptions !== null ? cellViewOptions.parse(c.viewOptions) : undefined,
                };
              case 'VariableViewCell':
                return {
                  id: c.id,
                  kind: 'variable',
                  definition: c.definition,
                  viewOptions:
                    c.viewOptions !== null ? cellViewOptions.parse(c.viewOptions) : undefined,
                };
              case 'SqlViewCell':
                return {
                  id: c.id,
                  kind: 'sql',
                  title: c.title ?? '',
                  pipeline: convertPipelineTypes(c.pipeline),
                  viewOptions:
                    c.viewOptions !== null ? cellViewOptions.parse(c.viewOptions) : undefined,
                };
              case 'PythonViewCell':
                return {
                  id: c.id,
                  kind: 'python',
                  title: c.title ?? '',
                  pipeline: convertPipelineTypes(c.pipeline),
                  viewOptions:
                    c.viewOptions !== null ? cellViewOptions.parse(c.viewOptions) : undefined,
                };
              case 'TextViewCell':
                return {
                  id: c.id,
                  kind: 'text',
                  title: c.title ?? '',
                  content: c.content ?? '',
                  viewOptions:
                    c.viewOptions !== null ? cellViewOptions.parse(c.viewOptions) : undefined,
                };
              default:
                throw new Error(`Unknown cell type: ${c.__typename}`);
            }
          }),
        },
      };

export const convertExplorationsArrayTypes = (
  input: ExplorationFragmentFragment[],
): Exploration[] => compact(input.map((e) => convertExplorationTypes(e)));

export const convertAlertConfigurationTypes = (
  input?: GraphQLAlertConfiguration | null,
): AlertConfiguration | undefined =>
  isNil(input)
    ? undefined
    : {
        alertConfigurationId: input.alertConfigurationId,
        name: input.name,
        message: input.message,
        pipeline: convertPipelineTypes(input.pipeline) as DereferencedPipeline,
        keyFields: input.keyFields,
        notificationConfig: input.notificationConfig,
        createdAt: input.createdAt,
      };

export const convertDeprecatedMetricTypes = (input: unknown[]): Metric[] =>
  input.map((metric) => deprecatedMetricSchema.parse(metric));
