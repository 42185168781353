import { useEffect, useMemo } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { PageViewContextProvider, useTrackEvent } from '../lib/analytics';
import { useBuildAccountUrl, useSelectedAccount } from '../lib/accounts/context';
import { ExplorationContextProvider } from './exploration/exploration-context';
import { ExplorationView } from './exploration';
import { ExplorationSearch } from './exploration-search';
import {
  decodeExplorationHash,
  decodeExplorationParamsHash,
  buildExplorationUrl,
  getParameters,
  getExplorationFromUrl,
  modelSupportsDetailExploration,
  getModelDetailExploration,
  getVariableDefinitions,
  buildSavedExplorationUrl,
} from './utils';
import { FullPageLoader } from '../components/loader';
import { MetadataContextProvider, useMetadataContext } from './metadata-context';
import { useExplorationsQuery } from '../graphql';
import {
  convertDeprecatedMetricTypes,
  convertExplorationsArrayTypes,
  convertModelTypes,
} from './input';
import { useTitle } from '../lib/hooks/use-title';
import { useExplorationDelete, useExplorationUpsert } from './exploration/hooks';
import { ensureValidExploration } from './utils';

import { RedirectToDetailExploration } from './redirect-to-detail-exploration';

import { DirtyContextProvider } from './dirty-context';

import styles from './explore.module.scss';

export const Explore = () => {
  const account = useSelectedAccount();

  const { data, loading, refetch } = useExplorationsQuery({
    variables: { accountId: account.accountId },
  });

  const { models, metrics, metricsV2, explorations } = useMemo(() => {
    const models = convertModelTypes(data?.account?.models) ?? [];
    const metrics = convertDeprecatedMetricTypes(data?.account?.metrics ?? []);
    const metricsV2 = data?.account?.metricsV2 ?? [];
    const explorations = [
      ...convertExplorationsArrayTypes(data?.account?.explorations ?? []),
      ...convertExplorationsArrayTypes(data?.account?.parameterisedExplorations ?? []),
      ...models.filter(modelSupportsDetailExploration).map(getModelDetailExploration),
    ].map((exploration) => ensureValidExploration(exploration, models, metricsV2));

    return { models, metrics, metricsV2, explorations };
  }, [data?.account]);

  if (loading) {
    return <FullPageLoader />;
  }

  return (
    <MetadataContextProvider
      accountId={account.accountId}
      models={models}
      explorations={explorations}
      metrics={metrics}
      metricsV2={metricsV2}>
      <Routes>
        <Route path="/detail" element={<RedirectToDetailExploration />} />
        <Route path="/*" element={<ExplorePage refetch={refetch} />} />
      </Routes>
    </MetadataContextProvider>
  );
};

export const ExplorePage = ({ refetch }: { refetch: () => Promise<unknown> }) => {
  const trackEvent = useTrackEvent();

  const navigate = useNavigate();
  const location = useLocation();
  const account = useSelectedAccount();
  const buildAccountUrl = useBuildAccountUrl();

  useEffect(() => trackEvent('Explore Page opened'), [trackEvent]);

  const { upsertExploration } = useExplorationUpsert();
  const { deleteExploration } = useExplorationDelete();

  const { models, metrics, metricsV2, explorations } = useMetadataContext();

  const initialExploration = getExplorationFromUrl(location.hash, explorations, models, metrics);
  const modifiedExploration = decodeExplorationHash(location.hash);

  const exploration = modifiedExploration ?? initialExploration;

  if (exploration !== null) {
    const parameters = getParameters(
      decodeExplorationParamsHash(location.hash) ?? {},
      getVariableDefinitions(exploration),
    );

    return (
      <PageViewContextProvider>
        <DirtyContextProvider>
          <ExplorationContextProvider
            key={exploration.explorationId}
            isDirty={modifiedExploration !== null}
            exploration={ensureValidExploration(exploration, models, metricsV2)}
            models={models}
            metrics={metricsV2}
            setExploration={(exploration, parameters) => {
              navigate(
                buildAccountUrl(buildExplorationUrl(exploration, parameters, location.hash)),
              );
            }}
            upsertExploration={async (exploration, parameters) => {
              await upsertExploration(exploration);
              await refetch();

              navigate(
                buildAccountUrl(buildSavedExplorationUrl(exploration.explorationId, parameters)),
              );
            }}
            deleteExploration={async (explorationId: string) => {
              await deleteExploration(explorationId);
              await refetch();

              navigate(buildAccountUrl('/explore'));
            }}
            resetExploration={(parameters) => {
              navigate(buildAccountUrl(buildExplorationUrl(null, parameters, location.hash)));
            }}
            parameters={parameters}>
            <ExplorationView accountId={account.accountId} />
          </ExplorationContextProvider>
        </DirtyContextProvider>
      </PageViewContextProvider>
    );
  }

  return <ExplorationSearchPage />;
};

const ExplorationSearchPage = () => {
  useTitle('Explore');

  return (
    <div className={styles.explorePage}>
      <ExplorationSearch className={styles.panel} />
    </div>
  );
};
