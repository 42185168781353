import { useMemo } from 'react';
import { z } from 'zod';
import { Navigate, useLocation } from 'react-router-dom';

import { useExplorationsQuery } from '../graphql';
import { convertExplorationsArrayTypes, convertModelTypes } from '../explore/input';
import {
  buildExplorationUrl,
  buildSavedExplorationUrl,
  getHashParameter,
  getModelDetailExploration,
  isPersistedModelDetailExploration,
  modelSupportsDetailExploration,
} from '../explore/utils';
import { useBuildAccountUrl, useSelectedAccount } from '../lib/accounts/context';
import { FullPageLoader } from '../components/loader';
import { ErrorBanner } from '../components/banner';
import { ensureValidExploration } from './utils';
import { MetricV2 } from './types';

const paramsZod = z.record(z.union([z.string(), z.number(), z.boolean()]));
type Params = z.infer<typeof paramsZod>;

export const RedirectToDetailExploration = () => {
  const location = useLocation();
  const buildAccountUrl = useBuildAccountUrl();
  const account = useSelectedAccount();

  const modelId = getHashParameter(location.hash, 'modelId');
  const paramsJson = getHashParameter(location.hash, 'params');

  const { data, loading } = useExplorationsQuery({
    variables: { accountId: account.accountId },
  });

  const models = useMemo(() => convertModelTypes(data?.account?.models) ?? [], [data]);
  const metricsV2 = (data?.account?.metricsV2 as MetricV2[]) ?? [];
  const explorations = [
    ...convertExplorationsArrayTypes(data?.account?.parameterisedExplorations ?? []),
    ...models.filter(modelSupportsDetailExploration).map(getModelDetailExploration),
  ].map((exploration) => ensureValidExploration(exploration, models, metricsV2));

  if (loading) {
    return <FullPageLoader />;
  }

  if (!models.some((model) => model.modelId === modelId)) {
    return <ErrorBanner title="Not found" description={`Model '${modelId}' not found.`} />;
  }

  let params: Params;
  try {
    params = paramsZod.parse(JSON.parse(paramsJson ?? '{}'));
  } catch (e) {
    return (
      <ErrorBanner title="Invalid parameters" description="The link may be invalid or incomplete" />
    );
  }

  const exploration = explorations.find((exploration) => {
    return exploration.parameters.every(
      (param) => param.modelId === modelId && param.key in params,
    );
  });

  if (exploration === undefined) {
    return (
      <ErrorBanner
        title="Could not find exploration"
        description="This can happen if the model or its relations have changed"
      />
    );
  }

  const explorationUrl = buildAccountUrl(
    isPersistedModelDetailExploration(exploration)
      ? buildSavedExplorationUrl(exploration.explorationId, params)
      : buildExplorationUrl(exploration, params),
  );

  return <Navigate to={explorationUrl} replace />;
};
