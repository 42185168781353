import { ReactNode } from 'react';
import { z } from 'zod';
import { omit, toNumber } from 'lodash';
import classNames from 'classnames';

import { Select } from '@/components/form/select';
import { CheckboxWithLabel } from '@/components/form/checkbox';
import { Badge } from '@/components/badge';
import { DateRangeSelector } from '@/components/form/date-range-selector';
import { Input } from '@/components/form/input';
import { Dropdown, DropdownMenuItem } from '@/components/dropdown';
import { IconButton } from '@/components/button';
import { Icon } from '@/components/icon';
import { createBackNavigationToast, useToastContext } from '@/components/toast';
import { useTrackEvent } from '@/lib/analytics';
import { DateTimeInput } from '@/components/form/datetime-input';

import { useExplorationContext } from '../exploration-context';
import {
  getVariableColor,
  replaceDefinition,
  setVariableDefinitionDefaultValue,
} from '../../utils';
import {
  DateRangeParameter,
  VariableCell,
  VariableDefinition,
  booleanParameter,
  dateRangeParameter,
  enumParameter,
  dateParameter,
  DateParameter,
  ExplorationParameter,
} from '../../types';

import styles from './variable-cell.module.scss';
import explorationStyles, {
  variableColor1,
  variableColor2,
  variableColor3,
  variableColor4,
  variableColor5,
  variableColor6,
  variableAlternateColor1,
  variableAlternateColor2,
  variableAlternateColor3,
  variableAlternateColor4,
  variableAlternateColor5,
  variableAlternateColor6,
} from '../exploration.module.scss';

interface VariableCellViewProps {
  cell: VariableCell;
  variables: VariableDefinition[];
  onSetDraggable: (value: boolean) => void;
}

const VariableInput = ({
  label,
  children,
  variables,
}: {
  label: string;
  children: ReactNode;
  variables: VariableDefinition[];
}) => (
  <div className={styles.input}>
    <Badge
      bgColor={getVariableColor(label, variables, [
        variableAlternateColor1,
        variableAlternateColor2,
        variableAlternateColor3,
        variableAlternateColor4,
        variableAlternateColor5,
        variableAlternateColor6,
      ])}
      textColor={getVariableColor(label, variables, [
        variableColor1,
        variableColor2,
        variableColor3,
        variableColor4,
        variableColor5,
        variableColor6,
      ])}
      iconName="Variable"
      label={label}
    />
    {children}
  </div>
);

interface StringInputProps {
  definition: VariableDefinition;
  value: string;
  onChange?: (value: string) => void;
  variables: VariableDefinition[];
}

const StringInput = ({ definition, value, onChange, variables }: StringInputProps) => (
  <VariableInput key={definition.key} label={definition.key} variables={variables}>
    <Input value={value} onChange={(e) => onChange && onChange(e.currentTarget.value)} />
  </VariableInput>
);

interface NumberInputProps {
  definition: VariableDefinition;
  value: number;
  onChange?: (value: number) => void;
  variables: VariableDefinition[];
}

const NumberInput = ({ definition, value, onChange, variables }: NumberInputProps) => (
  <VariableInput key={definition.key} label={definition.key} variables={variables}>
    <Input
      value={value}
      type="number"
      onChange={(e) => onChange && onChange(parseFloat(e.currentTarget.value))}
    />
  </VariableInput>
);
interface DateRangeInputProps {
  definition: VariableDefinition;
  value: DateRangeParameter;
  onChange?: (value: DateRangeParameter) => void;
  variables: VariableDefinition[];
}

const DateRangeInput = ({ definition, value, onChange, variables }: DateRangeInputProps) => {
  const { precision, start, end, range } = value;

  return (
    <VariableInput key={definition.key} label={definition.key} variables={variables}>
      <DateRangeSelector
        range={range}
        startDate={new Date(start)}
        endDate={new Date(end)}
        precision={precision}
        onChange={({ range, precision, startDate, endDate }) => {
          onChange &&
            onChange({
              ...value,
              range,
              precision,
              start: startDate.toISOString(),
              end: endDate.toISOString(),
            });
        }}
      />
    </VariableInput>
  );
};

interface DateInputProps {
  definition: VariableDefinition;
  value: DateParameter;
  onChange?: (value: DateParameter) => void;
  variables: VariableDefinition[];
}

const DateInput = ({ definition, value, variables, onChange }: DateInputProps) => (
  <VariableInput key={definition.key} label={definition.key} variables={variables}>
    <DateTimeInput value={value} onChange={(value) => onChange && onChange(value)} />
  </VariableInput>
);

export const VariableCellView = (props: VariableCellViewProps) => {
  const { cell, variables } = props;
  const { definition } = cell;

  const { exploration, deleteCell, deselectCell, setParameter, getParameter, setExploration } =
    useExplorationContext();
  const addToast = useToastContext();
  const trackEvent = useTrackEvent();
  const value = getParameter(definition.key) ?? '';

  const handleChange = (value: ExplorationParameter) => {
    trackEvent('Variable Value Changed', {
      exploration,
      definition,
      value,
    });
    setParameter(definition.key, value);
  };

  const handleSetDefaultValue = (value: ExplorationParameter) =>
    setExploration(
      replaceDefinition(setVariableDefinitionDefaultValue(definition, value), exploration, cell.id),
    );

  const handleDeleteCell = () => {
    deleteCell(cell.id);
    deselectCell();
    addToast(
      createBackNavigationToast(
        'Block Deleted',
        "If you didn't mean to delete it, you can use your browser back button or",
        'click undo',
      ),
    );
    trackEvent('Exploration Cell Deleted', { exploration, cell });
  };

  return (
    <div className={styles.variableCell}>
      <Icon
        name="DragHandle"
        size={10}
        className={classNames(explorationStyles.dragHandle, styles.dragHandle)}
        onMouseOver={() => props.onSetDraggable(true)}
        onMouseOut={() => props.onSetDraggable(false)}
      />
      <div className={styles.menu}>
        <Dropdown
          align="right"
          trigger={(isOpen, setIsOpen) => (
            <IconButton
              icon="MoreHorizontal"
              size="small"
              title="More..."
              type="gray"
              onClick={() => setIsOpen(!isOpen)}
            />
          )}
          items={[
            {
              label: 'Delete variable',
              icon: <Icon name="Trash2" size={16} />,
              onClick: handleDeleteCell,
              sort: 10,
            },
            {
              label: 'Set value as default',
              icon: <Icon name="Bookmark" size={16} />,
              onClick: () => handleSetDefaultValue(value),
              sort: 20,
            },
          ]
            .sort((a, b) => a.sort - b.sort)
            .map((item) => omit(item, ['sort']) as DropdownMenuItem)}
        />
      </div>

      {definition.kind === 'string' ? (
        <StringInput
          definition={definition}
          value={z.string().optional().parse(value) ?? definition.defaultValue}
          onChange={(value) => handleChange(value)}
          variables={variables}
        />
      ) : definition.kind === 'number' ? (
        <NumberInput
          definition={definition}
          value={toNumber(value) ?? definition.defaultValue}
          onChange={(value) => handleChange(value)}
          variables={variables}
        />
      ) : definition.kind === 'enum' ? (
        <VariableInput key={definition.key} label={definition.key} variables={variables}>
          <Select
            value={enumParameter.parse(value)}
            onChange={(value) => handleChange(value)}
            options={
              definition.options.map(({ value }) => ({
                label: value,
                value,
              })) ?? []
            }
          />
        </VariableInput>
      ) : definition.kind === 'date_range' ? (
        <DateRangeInput
          key={definition.key}
          definition={definition}
          value={dateRangeParameter.parse(value)}
          onChange={(value) => handleChange(value)}
          variables={variables}
        />
      ) : definition.kind === 'boolean' ? (
        <VariableInput key={definition.key} label={definition.key} variables={variables}>
          <CheckboxWithLabel
            checked={booleanParameter.parse(Boolean(value))}
            onChange={(value) => handleChange(value.checked)}
            id={definition.key}>
            {definition.key}
          </CheckboxWithLabel>
        </VariableInput>
      ) : definition.kind === 'date' ? (
        <DateInput
          key={definition.key}
          definition={definition}
          value={dateParameter.parse(value)}
          onChange={(value) => handleChange(value)}
          variables={variables}
        />
      ) : null}
    </div>
  );
};
