import { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { ApolloProvider } from '@apollo/client/react';
import camelCase from 'lodash/camelCase';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import { AuthErrorCodes } from 'firebase/auth';

import App from './App';
import { AuthContextProvider, useAuth } from './auth/auth-context';
import { generateUUID } from './lib/utils';

const enableSentry = import.meta.env.PROD;
if (enableSentry) {
  Sentry.init({
    dsn: 'https://550a94ba9ef04240956a34dd514f660c@o1090854.ingest.sentry.io/6291300',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // This should come from import.meta.env but since we currently have no means to create separate builds
    // it is hardcoded for now.
    environment: 'production',
    ignoreErrors: [AuthErrorCodes.POPUP_CLOSED_BY_USER, AuthErrorCodes.USER_CANCELLED],
  });
  Sentry.setContext('Supersimple', {
    Client: `Supersimple-app ${APP_VERSION}`,
  });
}

const UNAUTHENTICATED_REQUESTS = ['SignUp'];

const AuthorisedApolloProvider = ({ children }: { children: React.ReactNode }) => {
  const { user, getToken, logOut } = useAuth();

  useEffect(() => {
    if (user?.email !== null && user?.email !== undefined) {
      Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        email: user?.email,
      });
    }
  }, [user?.email]);

  const httpLink = createHttpLink({
    uri: '/graphql',
    headers: {
      'X-Supersimple-Client': `Supersimple-app ${APP_VERSION}`,
    },
  });

  // Log any GraphQL errors or network error that occurred
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (message.includes('Bad token')) {
          logOut();
        }
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  });

  const authLink = setContext(async ({ operationName }) => {
    if (operationName !== undefined && UNAUTHENTICATED_REQUESTS.includes(operationName)) {
      return {};
    }

    const token = await getToken();

    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const traceLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'X-Trace-ID': generateUUID(),
      },
    }));
    return forward(operation);
  });

  const client = new ApolloClient({
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
    },
    cache: new InMemoryCache({
      typePolicies: {
        Account: {
          keyFields: ['accountId'],
          fields: {
            query: relayStylePagination([
              'baseModelId',
              'pipeline',
              'sort',
              'variables',
              'timezone',
            ]),
          },
        },
        Model: {
          keyFields: false,
          fields: {
            entities: relayStylePagination(),
          },
        },
        EntityRelation: {
          fields: {
            entities: relayStylePagination(),
          },
        },
        Metric: {
          keyFields: false,
        },
      },
      dataIdFromObject: (obj) => {
        const fieldName = `${camelCase(obj.__typename)}Id`;
        if (typeof obj[fieldName] === 'string') {
          return `${obj.__typename}:${obj[fieldName]}`;
        }
        return undefined;
      },
    }),
    link: authLink.concat(errorLink).concat(traceLink).concat(httpLink),
  });

  return <ApolloProvider client={client}> {children} </ApolloProvider>;
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <AuthContextProvider>
      <AuthorisedApolloProvider>
        <App />
      </AuthorisedApolloProvider>
    </AuthContextProvider>
  </StrictMode>,
);
