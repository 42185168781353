import { FormEvent, createRef, useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

import { Card } from '../../components/gradient-layout/cards';
import { Button } from '../../components/button';
import { useToastContext } from '../../components/toast';
import { Input } from '../../components/form/input';

import commonStyles from '../public.module.scss';

interface PasswordLoginProps {
  onLogIn: () => void;
}

export const PasswordLogin = ({ onLogIn }: PasswordLoginProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = createRef<HTMLFormElement>();

  const auth = getAuth();
  const addToast = useToastContext();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      onLogIn();
    } catch (e) {
      setIsSubmitting(false);
      addToast({
        title: 'Bad login',
        content: () => 'Username or Password is incorrect',
        kind: 'error',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      <Card>
        <div className={commonStyles.title}>Log in with email</div>

        <div className={commonStyles.fields}>
          <Input
            type="text"
            size="large"
            placeholder="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />

          <Input
            type="password"
            size="large"
            placeholder="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>

        <Button
          htmlType="submit"
          className={commonStyles.button}
          size="large"
          loading={isSubmitting}>
          Log in
        </Button>
      </Card>
    </form>
  );
};
