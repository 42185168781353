import { humanReadableIntegrations } from '@/lib/integrations';

import { List, ListItem } from '../common';

import { ServiceIcon } from '../../components/service-icon';

import styles from './list.module.scss';

export interface IntegrationsListItem {
  integrationId: string;
  service: string;
}

interface IntegrationsListProps {
  integrations: IntegrationsListItem[];
}

export const IntegrationsList = (props: IntegrationsListProps) => {
  return (
    <List numColumns={3} columnWidths={{ 0: 'min-content' }}>
      {props.integrations.map(({ integrationId, service }, i) => {
        return (
          <ListItem key={i}>
            <div className={styles.serviceIcon}>
              <ServiceIcon service={service} />
            </div>
            <div className={styles.serviceName}>{humanReadableIntegrations[service]}</div>
            <div>{integrationId}</div>
          </ListItem>
        );
      })}
    </List>
  );
};
