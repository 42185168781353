import { BarGroupHorizontal, BarRounded, BarStackHorizontal } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { ParentSize } from '@visx/responsive';
import { localPoint } from '@visx/event';
import { useTooltip } from '@visx/tooltip';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { GridColumns } from '@visx/grid';

import { ScaleLinear, ScaleOrdinal } from 'd3-scale';

import { uniq } from 'lodash';

import { NoMatchBanner } from '@/components/banner';
import { CheckboxWithLabel } from '@/components/form/checkbox';
import { formatNumberToCompact } from '@/lib/utils/number';

import { ChartTooltipSingleValue } from '../common';
import { CategoryData, GroupedChartData } from '../grouped-chart/types';
import { ColoredLegend } from '../legend';
import { GroupedChart } from '../grouped-chart';

import {
  getMaxStackedValue,
  getMaxValue,
  getMinStackedValue,
  getMinValue,
  getSeries,
  getValueKeys,
} from '../grouped-chart/utils';

import styles, {
  gridLineColor,
  barColor,
  barColor1,
  barColor2,
  barColor3,
  barColor4,
  barColor5,
  barColor6,
} from '../charts.module.scss';

const HorizontalPadding = 20;
const ValuePaddingCoef = 0.1;
const MaxHeight = 600;
const WidthPerGroup = parseInt(styles.widthPerGroup);

type TooltipData = {
  categoryLabel: string;
  categoryValue: string;
  valueLabel: string;
  valueKey: string;
  value: number;
  color: string;
};

interface HorizontalBarChartProps {
  categoryFormat: {
    precision?: string;
  };
  onCategroryClick?: (event: React.MouseEvent, key: string, value: any) => void;
  data: CategoryData;
  width: number;
  valueScale: ScaleLinear<number, number>;
  colorScale: ScaleOrdinal<string, string>;
}

const Chart = (props: HorizontalBarChartProps & { stacked: boolean }) =>
  props.width === 0 ? null : props.stacked && props.data.series.length > 1 ? (
    <StackedHorizontalBarChart {...props} />
  ) : (
    <HorizontalBarChart {...props} />
  );

const HorizontalBarChart = ({
  data,
  width,
  colorScale,
  valueScale,
  onCategroryClick,
}: HorizontalBarChartProps) => {
  const { showTooltip, hideTooltip, tooltipOpen, tooltipLeft, tooltipTop, tooltipData } =
    useTooltip<TooltipData>();

  const handleMouseOver = (event: any, data: TooltipData) => {
    const coords = localPoint(event.target.ownerSVGElement, event);
    if (coords === null) {
      return false;
    }
    showTooltip({ tooltipLeft: coords.x, tooltipTop: coords.y, tooltipData: data });
  };

  const seriesCount = data.series.length;
  const barCount = data.items.length * seriesCount;
  const barHeight = seriesCount > 1 || barCount > 10 ? 20 : 30;
  const height = Math.min(data.items.length * seriesCount * barHeight, MaxHeight);

  if (data.items.length === 0) {
    return <NoMatchBanner />;
  }

  const categoryScale = scaleBand({
    domain: data.items.map((d) => d.categoryValue),
    padding: 0.2,
    range: [0, height],
  });
  const seriesScale = scaleBand({
    domain: data.series.map(({ key }) => key),
    padding: 0.3,
    range: [0, categoryScale.bandwidth()],
  });

  return (
    <div className={styles.graph}>
      {tooltipData !== undefined ? (
        <ChartTooltipSingleValue
          tooltipTop={tooltipTop}
          tooltipLeft={tooltipLeft}
          tooltipData={{
            label: tooltipData.categoryValue,
            value: tooltipData.value,
          }}
          tooltipOpen={tooltipOpen}
          categoryLabel={tooltipData.categoryLabel}
          valueLabel={tooltipData.valueLabel}
          seriesColor={colorScale(tooltipData.valueKey)}
        />
      ) : null}
      <svg width={width} height={height}>
        <Group left={120}>
          <GridColumns width={width} height={height} scale={valueScale} stroke={gridLineColor} />
          <BarGroupHorizontal
            data={data.items.map((item) => ({
              categoryValue: item.categoryValue,
              ...item.values,
            }))}
            keys={data.series.map(({ key }) => key)}
            width={width}
            y0={(d) => d.categoryValue}
            y0Scale={categoryScale}
            y1Scale={seriesScale}
            xScale={valueScale}
            color={colorScale}>
            {(barGroups) => {
              return barGroups.map((barGroup) => (
                <Group
                  key={`bar-group-horizontal-${barGroup.index}-${barGroup.y0}`}
                  top={barGroup.y0}>
                  {barGroup.bars.map((bar) => (
                    <BarRounded
                      key={`${barGroup.index}-${bar.index}-${bar.key}`}
                      x={bar.x}
                      y={bar.y}
                      width={bar.width}
                      height={bar.height}
                      fill={bar.color}
                      rx={4}
                      radius={4}
                      right
                      onMouseMove={(e) =>
                        handleMouseOver(e, {
                          value: bar.value,
                          valueKey: bar.key,
                          categoryLabel: data.items.at(barGroup.index)?.label ?? '',
                          categoryValue: data.items.at(barGroup.index)?.categoryValue ?? '',
                          valueLabel:
                            data.series.find(({ key }) => key === bar.key)?.label ?? bar.key,
                          color: bar.color,
                        })
                      }
                      onMouseOut={hideTooltip}
                      onClick={(e) => {
                        const item = data.items.at(barGroup.index);
                        if (item !== undefined) {
                          onCategroryClick?.(e, item.key, item.categoryValue);
                        }
                      }}
                    />
                  ))}
                </Group>
              ));
            }}
          </BarGroupHorizontal>
          <AxisLeft
            scale={categoryScale}
            stroke={gridLineColor}
            hideTicks
            tickLabelProps={{
              fill: '#FFF',
              fontSize: 12,
              textAnchor: 'end',
              width: 100,
              scaleToFit: 'shrink-only',
            }}
          />
        </Group>
      </svg>
    </div>
  );
};

const StackedHorizontalBarChart = ({
  data,
  width,
  colorScale,
  valueScale,
  onCategroryClick,
}: HorizontalBarChartProps) => {
  const { showTooltip, hideTooltip, tooltipOpen, tooltipLeft, tooltipTop, tooltipData } =
    useTooltip<TooltipData>();

  const handleMouseOver = (event: any, data: TooltipData) => {
    const coords = localPoint(event.target.ownerSVGElement, event);
    if (coords === null) {
      return false;
    }
    showTooltip({ tooltipLeft: coords.x, tooltipTop: coords.y, tooltipData: data });
    return true;
  };

  const seriesCount = data.series.length;
  const barCount = data.items.length * seriesCount;
  const barHeight = barCount > 5 ? 20 : 30;
  const height = Math.min(data.items.length * seriesCount * barHeight, MaxHeight);

  if (data.items.length === 0) {
    return <NoMatchBanner />;
  }

  const categoryScale = scaleBand({
    domain: data.items.map((d) => d.categoryValue),
    padding: 0.5,
    range: [0, height],
  });

  return (
    <div className={styles.graph}>
      {tooltipData !== undefined ? (
        <ChartTooltipSingleValue
          tooltipTop={tooltipTop}
          tooltipLeft={tooltipLeft}
          tooltipData={{
            label: tooltipData.categoryValue,
            value: tooltipData.value,
          }}
          tooltipOpen={tooltipOpen}
          categoryLabel={tooltipData.categoryLabel}
          valueLabel={tooltipData.valueLabel}
          seriesColor={tooltipData.color}
        />
      ) : null}
      <svg width={width} height={height}>
        <Group left={120}>
          <GridColumns width={width} height={height} scale={valueScale} stroke={gridLineColor} />
          <BarStackHorizontal<Record<string, any>>
            data={data.items.map((d) => ({
              categoryLabel: d.label,
              categoryValue: d.categoryValue,
              ...d.values,
              ...data.series.reduce(
                (acc, series) => ({
                  ...acc,
                  [series.key]: d.values[series.key] ?? 0,
                }),
                {},
              ),
            }))}
            keys={data.series.map(({ key }) => key)}
            y={(d) => d.categoryValue}
            width={width}
            xScale={valueScale}
            yScale={categoryScale}
            color={(x) => colorScale(x.toString())}>
            {(barStacks) =>
              barStacks.map((barStack, barStackIdx) =>
                barStack.bars.map((bar) => (
                  <BarRounded
                    radius={5}
                    key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                    x={bar.x}
                    y={bar.y}
                    width={bar.width}
                    height={bar.height}
                    fill={bar.color}
                    right={barStackIdx === barStacks.length - 1}
                    onMouseMove={(e) =>
                      handleMouseOver(e, {
                        value: Number(bar.bar.data[bar.key]),
                        valueKey: bar.key.toString(),
                        categoryLabel: bar.bar.data.categoryLabel,
                        categoryValue: bar.bar.data.categoryValue,
                        valueLabel:
                          data.series.find(({ key }) => key === bar.key)?.label ??
                          bar.key.toString(),
                        color: bar.color,
                      })
                    }
                    onMouseOut={hideTooltip}
                    onClick={(e) => {
                      const item = data.items.at(bar.index);
                      if (item !== undefined) {
                        onCategroryClick?.(e, item.key, item.categoryValue);
                      }
                    }}
                  />
                )),
              )
            }
          </BarStackHorizontal>
          <AxisLeft
            scale={categoryScale}
            stroke={gridLineColor}
            hideTicks
            tickLabelProps={{
              fill: '#FFF',
              fontSize: 12,
              textAnchor: 'end',
              width: 100,
              scaleToFit: 'shrink-only',
            }}
          />
        </Group>
      </svg>
    </div>
  );
};

type GroupedHorizontalBarChartProps = {
  data: GroupedChartData<CategoryData>;
  grouping: { key: string; label: string }[];
  stacked?: boolean;
  roundTickValues?: boolean;
  setStacked?: (stacked: boolean) => void;
  onCategroryClick?: (event: React.MouseEvent, key: string, value: any) => void;
};

export const GroupedHorizontalBarChart = ({
  data,
  grouping,
  stacked = false,
  roundTickValues = false,
  setStacked,
  onCategroryClick,
}: GroupedHorizontalBarChartProps) => {
  const seriesDomain = getValueKeys(data);

  const colorScale = scaleOrdinal<string, string>({
    domain: seriesDomain,
    range:
      seriesDomain.length === 1
        ? [barColor]
        : [barColor1, barColor2, barColor3, barColor4, barColor5, barColor6],
  });

  const legend = getSeries(data).map(({ key, label }) => ({
    label,
    color: colorScale(key),
  }));

  const isStackable = legend.length > 1;

  return (
    <ParentSize>
      {(parent) => {
        const groupsWidth = WidthPerGroup * Math.max(1, grouping.length - 1);
        const chartWidth = parent.width - groupsWidth;

        const maxVal = stacked ? getMaxStackedValue(data) : getMaxValue(data);
        const minVal = Math.min(0, stacked ? getMinStackedValue(data) : getMinValue(data));

        const valueScale = scaleLinear<number>({
          range: [0, chartWidth],
          round: true,
          domain: [minVal + minVal * ValuePaddingCoef, maxVal + maxVal * ValuePaddingCoef],
        });

        const tickValues = roundTickValues
          ? uniq(valueScale.ticks().map(Math.floor))
          : valueScale.ticks();

        return (
          <div>
            <GroupedChart
              width={parent.width}
              widthPerGroup={WidthPerGroup}
              data={data}
              renderChart={(chartData, width) => {
                return (
                  <Chart
                    data={chartData}
                    width={width}
                    categoryFormat={{ precision: 'month' }}
                    colorScale={colorScale}
                    valueScale={valueScale}
                    onCategroryClick={onCategroryClick}
                    stacked={stacked}
                  />
                );
              }}
            />
            <div
              style={{
                marginLeft: groupsWidth,
                marginRight: HorizontalPadding,
              }}>
              <svg width={chartWidth} height={30}>
                <AxisBottom
                  scale={valueScale}
                  tickClassName={styles.tickLabel}
                  tickLabelProps={() => ({ textAnchor: 'start' })}
                  tickFormat={(n) => formatNumberToCompact({ num: n.valueOf() })}
                  tickValues={tickValues}
                />
              </svg>
              <div className={styles.chartFooter}>
                <ColoredLegend items={legend} />
                {isStackable ? (
                  <CheckboxWithLabel
                    className={[styles.stackingCheckbox]}
                    checked={stacked}
                    onChange={() => setStacked && setStacked(stacked !== true)}>
                    Stacked
                  </CheckboxWithLabel>
                ) : null}
              </div>
            </div>
          </div>
        );
      }}
    </ParentSize>
  );
};
