import { isArray } from 'lodash';

import { ChartData, GroupedChartData } from './types';

import styles from './grouped-chart.module.scss';

interface GroupedChartProps<T extends ChartData> {
  width: number;
  widthPerGroup: number;
  data: GroupedChartData<T>;
  renderChart: (data: T, chartWidth: number) => JSX.Element;
}

export const GroupedChart = <T extends ChartData>(props: GroupedChartProps<T>) => {
  const { width, widthPerGroup, data, renderChart } = props;

  if (isArray(data)) {
    return (
      <div>
        {data.map((child, idx) => (
          <div key={idx} className={styles.group}>
            <div className={styles.groupLabel} style={{ width: `${widthPerGroup}px` }}>
              {child.label}
            </div>
            <GroupedChart {...props} data={child.items} width={width - widthPerGroup} />
          </div>
        ))}
      </div>
    );
  }

  return renderChart(data.chartData, width);
};
