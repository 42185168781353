import { common } from '@gosupersimple/types';
import { first } from 'lodash';

import { Fields } from '../types';

export const getDefaultSlice = (fields: Fields, previousSlice?: common.Slice): common.Slice => {
  const isSortValid =
    previousSlice?.sort?.every(({ key }) => fields.some((field) => field.key === key)) ?? false;
  return {
    limit: 100,
    ...previousSlice,
    sort: isSortValid ? previousSlice?.sort : [getDefaultSorting(fields)],
  };
};

export const getDefaultSorting = (fields: Fields): common.Sorting => {
  return {
    key: fields.find(({ type }) => type === 'Date')?.key ?? first(fields)?.key ?? '',
    direction: 'DESC',
  };
};
