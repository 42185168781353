import { Section } from '../common';
import { IntegrationsListItem, IntegrationsList } from './list';

import commonStyles from '../common/common.module.scss';

type IntegrationsSectionProps = { integrations: IntegrationsListItem[] };

export const IntegrationsSection = ({ integrations }: IntegrationsSectionProps) => {
  return (
    <Section title="Integrations">
      {integrations.length === 0 ? (
        <div className={commonStyles.emptyView}>No integrations configured for your account.</div>
      ) : (
        <IntegrationsList integrations={integrations} />
      )}
    </Section>
  );
};
