import { omit } from 'lodash';

import { NonEmptyArray } from '@/lib/utils/array';

import { generatePipelineId } from '../pipeline/utils';
import { Cell, Exploration, ExplorationParameters, Model, VariableCell } from '../types';
import { generateCellId } from './exploration';
import { isVariableCell } from './variable';
import { isNumberType } from '.';

export const isModelDetailExploration = (exploration: Exploration) =>
  exploration.parameters.length > 0;

export const isPersistedModelDetailExploration = (exploration: Exploration) =>
  isModelDetailExploration(exploration) &&
  exploration.options?.detailExplorationForModelId === undefined;

export const modelSupportsDetailExploration = (
  model: Model,
): model is Model & { primaryKey: NonEmptyArray<string> } => model.primaryKey.length > 0;

const buildPropertyVariableCell = (property: Model['properties'][0]): VariableCell =>
  isNumberType(property.type)
    ? {
        id: generateCellId(),
        kind: 'variable',
        definition: {
          kind: 'number' as const,
          key: property.key,
          defaultValue: 0,
        },
      }
    : {
        id: generateCellId(),
        kind: 'variable',
        definition: {
          kind: 'string' as const,
          key: property.key,
          defaultValue: '',
        },
      };

export const getModelDetailExploration = (
  model: Model & { primaryKey: NonEmptyArray<string> },
): Exploration => {
  const basePipelineId = generatePipelineId();
  return {
    explorationId: `${model.modelId}_detail`,
    name: model.name,
    labels: {},
    parameters: model.primaryKey.map((key) => ({ key, modelId: model.modelId })),
    options: {
      detailExplorationForModelId: model.modelId,
    },
    view: {
      cells: [
        ...model.primaryKey.reduce<VariableCell[]>((acc, key) => {
          const property = model.properties.find((p) => p.key === key);
          if (property !== undefined) {
            acc.push(buildPropertyVariableCell(property));
          }
          return acc;
        }, []),
        {
          id: generateCellId(),
          kind: 'records',
          title: model.name,
          pipeline: {
            pipelineId: basePipelineId,
            baseModelId: model.modelId,
            operations:
              model.primaryKey.length === 1
                ? model.primaryKey.map((key) => ({
                    operation: 'filter',
                    parameters: {
                      key,
                      operator: '==',
                      value: { expression: `variable("${key}")`, version: '1' },
                    },
                  }))
                : [
                    {
                      operation: 'filter',
                      parameters: {
                        operator: 'and',
                        operands: model.primaryKey.map((key) => ({
                          key,
                          operator: '==',
                          value: { expression: `variable("${key}")`, version: '1' },
                        })),
                      },
                    },
                  ],
          },
          viewOptions: {
            viewMode: 'cards',
          },
        },
        ...getModelRelationCells(model, basePipelineId),
      ],
    },
  };
};

const getModelRelationCells = (model: Model, parentId: string): Cell[] =>
  model.relations.map((relation) => ({
    id: generateCellId(),
    kind: 'records',
    title: relation.name,
    pipeline: {
      parentId,
      operations: [
        {
          operation: 'switchToRelation',
          parameters: { relation: { key: relation.key, modelId: model.modelId } },
        },
      ],
    },
    viewOptions: {
      viewMode: ['hasOne', 'hasOneThrough'].includes(relation.type) ? 'cards' : 'table',
    },
  }));

/**
 * Convert model detail exploration to ordinary exploration that does not need parameters. It is done by
 * replacing all variable cell default values with values from parameters.
 */
export const modelDetailExplorationToExploration = (
  exploration: Exploration,
  parameters: ExplorationParameters,
): Exploration => ({
  ...exploration,
  options: omit(exploration.options, 'detailExplorationForModelId'),
  view: {
    cells: exploration.view.cells.map((cell) =>
      isVariableCell(cell) ? setVariableCellDefaultValue(cell, parameters) : cell,
    ),
  },
  parameters: [],
});

// Since model detail exploration generates exploration with string variable cells, we only need to replace
// default values of string variable cells with values from parameters.
const setVariableCellDefaultValue = (
  cell: VariableCell,
  parameters: ExplorationParameters,
): VariableCell => ({
  ...cell,
  definition:
    cell.definition.kind === 'string'
      ? { ...cell.definition, defaultValue: parameters[cell.definition.key].toString() }
      : cell.definition,
});
