import { pick } from 'lodash';

import { getDatesFromTimeRange } from '@/lib/date';
import { Json, TimePrecision } from '@/lib/types';

import {
  DateRangeParameter,
  DateRangeVariableDefinition,
  Exploration,
  ExplorationParameters,
  ExplorationType,
  Fields,
  ModelKind,
  VariableDefinition,
  explorationTypes,
} from '../types';
import { isRecordsCell } from '../exploration/utils';
import { findField } from '../pipeline/state';
import { LinkingProperty } from './linking';

export * from './exploration';
export * from './model-exploration';
export * from './detail-exploration';
export * from './linking';
export * from './url';
export * from './validation';
export * from './variable';

export const isNumberType = (type: string | null | undefined) =>
  ['Number', 'Float', 'Integer'].includes(type ?? '');

export const isStringType = (type: string | null | undefined) =>
  ['String', 'Enum'].includes(type ?? '');

export const sortProperties = <T extends LinkingProperty>(properties: T[], grouping: string[]) =>
  properties.sort((a: T, b: T) => {
    const aIsGrouping = grouping.includes(a.key);
    const bIsGrouping = grouping.includes(b.key);

    if ((a.pk ?? false) && !bIsGrouping) {
      return -1;
    }
    if ((b.pk ?? false) && !aIsGrouping) {
      return 1;
    }

    if (aIsGrouping && !bIsGrouping) {
      return -1;
    }
    if (bIsGrouping && !aIsGrouping) {
      return 1;
    }

    if (aIsGrouping && bIsGrouping) {
      if (grouping.indexOf(a.key) < grouping.indexOf(b.key)) {
        return -1;
      }
      if (grouping.indexOf(a.key) > grouping.indexOf(b.key)) {
        return 1;
      }
    }

    // Links should always come first. Multiple links should have a deterministic sort order based on the key.
    const aIsLink = a.buildLink !== undefined;
    const bIsLink = b.buildLink !== undefined;
    if (aIsLink && bIsLink) {
      return a.key > b.key ? 1 : -1;
    }
    if (aIsLink) {
      return -1;
    }
    if (bIsLink) {
      return 1;
    }
    return 0;
  });

export const sortFields = <T extends { fields: Fields }>(
  input: T,
  fields: Fields,
  grouping: string[],
): T => ({
  ...input,
  fields: sortProperties(
    input.fields.map((field) => {
      const property = findField(fields, field.key);
      return {
        ...field,
        relation:
          field.relation ??
          (property?.relation !== undefined
            ? pick(property.relation, 'modelId', 'key', 'name')
            : undefined),
        model:
          field.model ??
          (property?.model !== undefined
            ? pick(property.model, 'modelId', 'name', 'propertyKey')
            : undefined),
      };
    }),
    grouping,
  ),
});

const isUnparameterizedExploration = (exploration: Exploration) =>
  exploration.parameters.length === 0;

export const getUnparameterizedExplorations = (explorations: Exploration[]) =>
  explorations.filter(isUnparameterizedExploration);

const getDateRangeInputDefault = ({
  defaultRange,
}: DateRangeVariableDefinition): DateRangeParameter => {
  const precision = TimePrecision.Monthly;
  const { startDate, endDate } = getDatesFromTimeRange(defaultRange, precision);
  return {
    range: defaultRange,
    start: startDate.toISOString(),
    end: endDate.toISOString(),
    precision,
  };
};

const getInputDefaults = (definitions: VariableDefinition[]) =>
  definitions.reduce<ExplorationParameters>((acc, inputDefinition) => {
    if (inputDefinition.kind === 'date_range') {
      return {
        ...acc,
        [inputDefinition.key]: getDateRangeInputDefault(inputDefinition),
      };
    }
    return {
      ...acc,
      [inputDefinition.key]: inputDefinition.defaultValue,
    };
  }, {});

export const getParameters = (
  urlParameters: ExplorationParameters,
  inputDefinitions: VariableDefinition[],
) => {
  const defaultParameters = getInputDefaults(inputDefinitions);
  return {
    ...defaultParameters,
    ...urlParameters,
  };
};

export const setExplorationType = (exploration: Exploration, type: ExplorationType) => ({
  ...exploration,
  labels: { ...exploration.labels, type: type as string },
});

export const getExplorationType = (exploration: Exploration): ExplorationType =>
  explorationTypes.find((type) => exploration.labels.type === type) ?? null;

export const getExplorationIconName = (exploration: Exploration) => {
  const type = getExplorationType(exploration);

  return type === 'model'
    ? 'Model'
    : type === 'ai'
      ? 'Zap'
      : type === 'metric'
        ? 'Activity'
        : type === 'exploration'
          ? 'Exploration'
          : 'Hash';
};

export const getModelKindIconName = (kind: ModelKind | null) => {
  switch (kind) {
    case 'Event':
      return 'Event';
    case 'User':
      return 'User';
    case 'Account':
      return 'Briefcase';
    default:
      return 'Box';
  }
};

export const getModelIdFromModelExploration = (exploration: Exploration) => {
  const firstCell = exploration.view.cells[0];

  if (isRecordsCell(firstCell)) {
    const { pipeline } = firstCell;
    if ('baseModelId' in pipeline) {
      return pipeline?.baseModelId;
    }
  }

  throw new Error('Could not get model id from model exploration');
};

export const getDataTypeFromValue = (value: Json) => {
  switch (true) {
    case typeof value === 'string':
      return 'String';
    case typeof value === 'number':
      return 'Number';
    case typeof value === 'boolean':
      return 'Boolean';
    case Array.isArray(value):
      return 'Array';
    case typeof value === 'object':
      return 'Object';
    default:
      return 'Unknown';
  }
};
