import { useMemo, useState } from 'react';
import { first, last, uniq } from 'lodash';
import { AreaClosed, Bar, LinePath, Line } from '@visx/shape';
import { scaleLinear, scaleTime } from '@visx/scale';
import { ParentSize } from '@visx/responsive';
import { curveMonotoneX } from '@visx/curve';
import { LinearGradient } from '@visx/gradient';
import { localPoint } from '@visx/event';
import { MarkerCircle } from '@visx/marker';

import { findClosestDate } from '@/lib/date';

import { ChartTooltip } from '../../explore/components/charts/common';

interface SeriesPoint {
  date: string;
  value: number;
  compareValue: number | null;
}

const YScaleBuffer = 1.1;

const TooltipSeriesItem = ({
  label,
  value,
  color,
}: {
  label: string;
  value: string;
  color: string;
}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          width: 10,
          height: 10,
          borderRadius: '50%',
          background: color,
          opacity: 1,
          marginRight: 5,
        }}
      />
      {label}:&nbsp;
      <strong>{value}</strong>
    </div>
  );
};

type ComparedTimeSeriesChartProps = {
  data: SeriesPoint[];
  name: string;
  compareName?: string;
  color: string;
  compareColor: string;
  onMouseEnter: () => void;
  formatDate: (date: Date) => string;
  formatValue: (value: number) => string;
  formatCompareValue: (value: number) => string;
};

const ComparedTimeSeriesChart = ({
  data,
  name,
  compareName,
  color,
  compareColor,
  onMouseEnter,
  formatDate,
  formatValue,
  formatCompareValue,
}: ComparedTimeSeriesChartProps) => {
  const isComparison = compareName !== undefined;

  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);

  const getDate = (point?: SeriesPoint) => (point ? new Date(point.date) : new Date());
  const getValue = (point?: SeriesPoint) => (point ? point.value : 0);
  const getCompareValue = (point: SeriesPoint) => point.compareValue ?? 0;

  const maxValue = Math.max(...data.map(getValue));
  const maxCompareValue = Math.max(...data.map(getCompareValue));

  const tooltipData = useMemo(() => {
    if (hoveredDate === null) {
      return null;
    }

    return data.find((p) => new Date(p.date).getTime() === hoveredDate?.getTime()) ?? null;
  }, [hoveredDate, data]);

  if (data.length === 0) {
    return null;
  }

  return (
    <ParentSize>
      {(parent) => {
        const { width, height } = parent;

        const valueScale = scaleLinear<number>({
          range: [height, 0],
          domain: [0, maxValue * YScaleBuffer],
        });

        const compareValueScale = scaleLinear<number>({
          range: [height, 0],
          domain: [0, maxCompareValue * YScaleBuffer],
        });

        const dateScale = scaleTime({
          domain: [getDate(first(data)), getDate(last(data))],
          range: [0, width],
        });

        const handleMouseMove = (event: any) => {
          const xTickSize = parent.width / uniq(data.map(({ date }) => date)).length;

          const { x } = localPoint(event) || { x: 0 };
          const hoveredDate = new Date(dateScale.invert(x - xTickSize / 2));

          const dates = uniq(data.map((point) => point.date)).map((date) => new Date(date));

          const closestDate = findClosestDate(dates, hoveredDate);

          setHoveredDate(closestDate);
        };

        return (
          <>
            <svg width={width} height={height}>
              <>
                <LinearGradient
                  id={`gradient-${color}`}
                  from={color}
                  to={color}
                  fromOpacity={1}
                  toOpacity={0.3}
                />
                <AreaClosed
                  width={90}
                  height={300}
                  data={data}
                  x={(d) => dateScale(getDate(d)) ?? 0}
                  y={(d) => valueScale(getValue(d))}
                  yScale={valueScale}
                  fill={`url(#gradient-${color})`}
                  fillOpacity={0.6}
                  stroke={color}
                  strokeWidth={1}
                  strokeOpacity={0.5}
                  curve={curveMonotoneX}
                />
              </>
              {isComparison && (
                <>
                  <MarkerCircle
                    id="marker-circle"
                    size={1}
                    refX={2}
                    fill="trasparent"
                    stroke={compareColor}
                  />
                  <LinePath
                    curve={curveMonotoneX}
                    data={data}
                    x={(d) => dateScale(getDate(d)) ?? 0}
                    y={(d) => compareValueScale(getCompareValue(d))}
                    stroke={compareColor}
                    strokeWidth={2}
                    strokeOpacity={0.5}
                    shapeRendering="geometricPrecision"
                    markerMid="url(#marker-circle)"
                  />
                </>
              )}
              {tooltipData && (
                <>
                  <Line
                    from={{ x: dateScale(getDate(tooltipData)), y: 0 }}
                    to={{ x: dateScale(getDate(tooltipData)), y: height }}
                    stroke={'#fff'}
                    strokeWidth={1}
                  />
                  <circle
                    cx={dateScale(getDate(tooltipData))}
                    cy={valueScale(getValue(tooltipData))}
                    r={4}
                    fill={color}
                    stroke="#fff"
                    strokeWidth={2}
                    pointerEvents="none"
                  />
                  {isComparison && (
                    <circle
                      cx={dateScale(getDate(tooltipData))}
                      cy={compareValueScale(getCompareValue(tooltipData) ?? 0)}
                      r={4}
                      fill={compareColor}
                      stroke="#fff"
                      strokeWidth={2}
                      pointerEvents="none"
                    />
                  )}
                </>
              )}
              <Bar
                width={width}
                height={height}
                fill="transparent"
                onTouchStart={handleMouseMove}
                onTouchMove={handleMouseMove}
                onMouseMove={handleMouseMove}
                onMouseLeave={() => setHoveredDate(null)}
                onMouseEnter={onMouseEnter}
              />
            </svg>

            {tooltipData && (
              <>
                {isComparison ? (
                  <ChartTooltip top={-10} left={-5}>
                    <div style={{ color: '#fff' }}>
                      <strong style={{ marginBottom: 5, display: 'block' }}>
                        {formatDate(getDate(tooltipData))}
                      </strong>
                      <TooltipSeriesItem
                        label={name}
                        value={formatValue(getValue(tooltipData))}
                        color={color}
                      />
                      <TooltipSeriesItem
                        label={compareName}
                        value={formatCompareValue(getCompareValue(tooltipData))}
                        color={compareColor}
                      />
                    </div>
                  </ChartTooltip>
                ) : (
                  <ChartTooltip top={10} left={dateScale(getDate(tooltipData))}>
                    <div style={{ color: '#fff' }}>
                      <strong style={{ color: '#fff' }}>{formatDate(getDate(tooltipData))}</strong>{' '}
                      - {formatValue(getValue(tooltipData))}
                    </div>
                  </ChartTooltip>
                )}
              </>
            )}
          </>
        );
      }}
    </ParentSize>
  );
};

export default ComparedTimeSeriesChart;
