import { Fragment } from 'react';
import { FunctionDefinition } from '@gosupersimple/penguino';
import {
  generateFnHeader,
  generateFnSignature,
} from '@gosupersimple/penguino/src/utils/documentation';

import { Field } from '@/explore/types';

import styles from './penguino-input.module.scss';

interface PenguinoDocumentationProps {
  item: { definition: FunctionDefinition } | { field: Field };
}

export const PenguinoDocumentation = ({ item }: PenguinoDocumentationProps) => (
  <aside className={styles.documentation}>
    {'definition' in item ? (
      <FunctionDocumentation definition={item.definition} />
    ) : (
      <FieldDocumentation field={item.field} />
    )}
  </aside>
);

interface FieldDocumentationProps {
  field: Field;
}

const FieldDocumentation = ({ field }: FieldDocumentationProps) => (
  <>
    <h2>{field.name}</h2>
    <p>For each row use the value from column &apos;{field.name}&apos;</p>
    <p>Column type: {field.type ?? 'Unknown'}</p>
  </>
);

interface FunctionDocumentationProps {
  definition: FunctionDefinition;
}

const FunctionDocumentation = ({ definition }: FunctionDocumentationProps) => {
  const args = definition.arguments?.map((arg) => ('variadic' in arg ? arg.variadic : arg)).flat();
  return (
    <>
      <h2>
        {generateFnHeader(definition.name, definition.arguments, definition.signatures ?? [])}
      </h2>
      <p>{definition.description}</p>
      {args !== undefined && (
        <dl title="Arguments">
          {args.map((arg) => (
            <Fragment key={arg.name}>
              <dt>
                {arg.name}
                {arg.optional === true && <span className={styles.optional}> (optional)</span>}
              </dt>
              <dd>
                {arg.description}
                {arg.values !== undefined && (
                  <>
                    <br />
                    Allowed values: {arg.values.join(', ')}
                  </>
                )}
              </dd>
            </Fragment>
          ))}
        </dl>
      )}
      <h3>Supported signatures</h3>
      {definition.signatures !== undefined && (
        <ul>
          {definition.signatures.map((signature, i) => (
            <li key={i}>
              <code>{generateFnSignature(definition.name, signature)}</code>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
