import { ErrorBoundary } from '@/lib/error';
import { ErrorBanner } from '@/components/banner';

import { Model, DereferencedPipeline, MetricV2 } from '../../types';
import { Visualisation } from '../../types';
import { VisualisationPanel } from '../visualisation';
import { useExplorationCellContext } from '../../exploration/exploration-cell-context';

import style from './visualisations.module.scss';

interface VisualisationsProps {
  accountId: string;
  pipeline: DereferencedPipeline;
  visualisations: Visualisation[];
  onCategroryClick?: (e: { clientX: number; clientY: number }, key: string, value: any) => void;
  models: Model[];
  metrics: MetricV2[];
  isInView: boolean;
}

export const Visualisations = (props: VisualisationsProps) => {
  const { accountId, pipeline, models, metrics, onCategroryClick, visualisations } = props;

  const { setVisualisation, removeVisualisation } = useExplorationCellContext();

  if (visualisations.length === 0) {
    return null;
  }

  return (
    <div className={style.visualisations}>
      {visualisations.map((visualisation, idx) => (
        <ErrorBoundary
          key={idx}
          fallback={(error) => <ErrorBanner description={(error as any).message} />}>
          <VisualisationPanel
            visualisation={visualisation}
            pipeline={pipeline}
            models={models}
            metrics={metrics}
            accountId={accountId}
            onClose={() => removeVisualisation(idx)}
            onCategroryClick={onCategroryClick}
            onVisualisationChange={(visualisation) => setVisualisation(idx, visualisation)}
            isInView={props.isInView}
          />
        </ErrorBoundary>
      ))}
    </div>
  );
};
