const ONE_MILLION = 1e6;
const ONE_BILLION = 1e9;
const ONE_TRILLION = 1e12;

/**
 * Formats a number to a locale string
 * @num number to format
 * @locale locale to use
 * @maximumFractionDigits maximum number of decimal places to show
 * @returns formatted number (e.g. 1,000,000)
 */
export const numberLocaleString = ({
  num,
  locale,
  maximumFractionDigits = 0,
}: {
  num: number;
  locale?: string;
  maximumFractionDigits?: number;
}) => num.toLocaleString(locale, { maximumFractionDigits });

/**
 * Formats a number to a compact string
 * @num number to format
 * @locale locale to use
 * @returns formatted number (e.g. 1.2M, 45.3T)
 */
export const formatNumberToCompact = ({
  num,
  locale,
}: {
  num: number;
  locale?: string;
  maximumFractionDigits?: number;
}) => Intl.NumberFormat(locale, { notation: 'compact', maximumFractionDigits: 1 }).format(num);

/**
 * Formats a number to a short string
 * @num number to format
 * @locale locale to use
 * @returns formatted number (e.g. 1.223 million, 45.33 trillion)
 */
export const formatNumberToShort = ({ num, locale }: { num: number; locale?: string }): string => {
  if (num > ONE_TRILLION) {
    return `${numberLocaleString({
      num: num / ONE_TRILLION,
      maximumFractionDigits: 3,
      locale,
    })} trillion`;
  }
  if (num > ONE_BILLION) {
    return `${numberLocaleString({
      num: num / ONE_BILLION,
      maximumFractionDigits: 3,
      locale,
    })} billion`;
  }
  if (num > ONE_MILLION) {
    return `${numberLocaleString({
      num: num / ONE_MILLION,
      maximumFractionDigits: 3,
      locale,
    })} million`;
  }
  return numberLocaleString({ num, maximumFractionDigits: 2, locale });
};
