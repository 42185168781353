import { useRef, useState } from 'react';
import classNames from 'classnames';
import { first, isNil, uniq } from 'lodash';

import { notNil } from '@/lib/utils';
import { useScrollPosition } from '@/lib/hooks/use-scroll-position';
import { NavigableListItemInput, useNavigableList } from '@/lib/hooks/use-navigable-list';

import { useMetadataContext } from '../metadata-context';
import { Icon } from '../../components/icon';
import { Modal } from '../../components/modal';
import { Panel } from '../../components/panel';
import { Exploration } from '../types';
import { getModelExploration, getUnparameterizedExplorations } from '../utils';
import {
  ReplicableCell,
  buildExplorationFromCells,
  cloneCell,
  explorationHasReplicableCells,
  extractCellFromExploration,
  isReplicableCell,
} from '../exploration/utils';
import { CollapsibleSection } from './collapsible-section';
import { CellList } from './cell-list';
import { ExplorationList } from './exploration-list';
import { SearchResults } from './search-results';
import { AddButton } from './add-button';
import { AddDropDownButton } from './add-dropdown-button';

import styles from './exploration-search.module.scss';

export type ExplorationListData = {
  title: string;
};

type CellListData = {
  title: string;
};

export type ExplorationListItemData = {
  exploration: Exploration;
};

export type CellListItemData = {
  cell: ReplicableCell;
};

export type ListItemData =
  | ExplorationListData
  | ExplorationListItemData
  | CellListData
  | CellListItemData;

interface ExplorationSearchProps {
  exploration?: Exploration;
  onClickExploration?: (exploration: Exploration) => void;
  selectIndividualCells?: boolean;
  className?: string;
}

export const ExplorationSearch = (props: ExplorationSearchProps) => {
  const { explorations, models } = useMetadataContext();
  const { exploration, onClickExploration, className, selectIndividualCells = false } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [scrollPosition, scrollContainerRef] = useScrollPosition<HTMLDivElement>();
  const inputRef = useRef<HTMLInputElement>(null);
  const setResultsListFocusIndex = useRef<((index: number | null) => void) | undefined>();

  const trimmedSearchTerm = searchTerm.trim();

  const modelExplorations = models.map((model) => getModelExploration(model));
  const modelSections = uniq(modelExplorations.map(({ labels }) => labels.section))
    .filter(notNil)
    .sort();
  const modelsWithoutSections = modelExplorations.filter(({ labels }) => isNil(labels.section));
  const unparameterisedExplorations = getUnparameterizedExplorations(explorations);

  const { list: explorationList, setFocusIndex } = useNavigableList<ListItemData>({
    items: [
      exploration === undefined || !explorationHasReplicableCells(exploration)
        ? null
        : {
            data: { title: 'On This Page' },
            children: exploration?.view.cells.filter(isReplicableCell).map((cell) => ({
              isFocusable: true,
              data: { cell },
              onClick: (event) => {
                event.preventDefault();
                onClickExploration &&
                  onClickExploration(buildExplorationFromCells([cloneCell(cell)]));
              },
            })),
          },
      unparameterisedExplorations.length === 0
        ? null
        : {
            data: { title: 'Saved Explorations' },
            children: unparameterisedExplorations
              .filter(
                (exploration) =>
                  !selectIndividualCells ||
                  exploration.view.cells.filter(isReplicableCell).length > 0,
              )
              .map((exploration) => ({
                isCollapsed: selectIndividualCells,
                isFocusable: true,
                data: { exploration },
                collapseOnClick: selectIndividualCells,
                onClick: (event) => {
                  if (selectIndividualCells || onClickExploration) {
                    event.preventDefault();
                  }
                  if (!selectIndividualCells && onClickExploration) {
                    onClickExploration(exploration);
                  }
                },
                children: selectIndividualCells
                  ? exploration.view.cells.filter(isReplicableCell).map((cell) => ({
                      isFocusable: true,
                      data: { cell },
                      onClick: (event) => {
                        event.preventDefault();
                        const { cell: extractedCell, dependencies } = extractCellFromExploration(
                          cell.id,
                          exploration,
                        );
                        onClickExploration &&
                          onClickExploration(
                            buildExplorationFromCells([extractedCell, ...dependencies]),
                          );
                      },
                    }))
                  : [],
              })),
          },
      ...modelSections.map<NavigableListItemInput<ListItemData>>((section) => ({
        data: { title: section },
        children: modelExplorations
          .filter(({ labels }) => labels.section === section)
          .map((exploration) => ({
            isFocusable: true,
            data: { exploration },
            onClick: (event) => {
              if (onClickExploration) {
                event.preventDefault();
                onClickExploration(exploration);
              }
            },
          })),
      })),
      modelsWithoutSections.length === 0
        ? null
        : {
            data: { title: modelSections.length > 0 ? 'Other Data Models' : 'Raw Data Models' },
            children: modelsWithoutSections.map((exploration) => ({
              isFocusable: true,
              data: {
                exploration,
              },
              onClick: (event) => {
                if (onClickExploration) {
                  event.preventDefault();
                  onClickExploration(exploration);
                }
              },
            })),
          },
    ],
    listContainerRef: scrollContainerRef,
    onFocusMovedOutside: () => {
      inputRef.current?.focus();
    },
  });

  const showResults = trimmedSearchTerm.length > 0;

  const updateFocusIndex = (index: number) => {
    if (showResults) {
      setResultsListFocusIndex.current?.(index);
    } else {
      setFocusIndex(index);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      updateFocusIndex(0);
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      updateFocusIndex(-1);
    }
  };

  const onThisPageListItem = first(explorationList);

  return (
    <Panel className={classNames(styles.panel, className)}>
      <section className={styles.explorationSearch}>
        <div className={styles.searchHeader}>
          <h1>What would you like to find?</h1>
          <div className={styles.searchInput}>
            <Icon name="Search" size={24} className={styles.searchIcon} />
            <input
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.currentTarget.value)}
              onKeyDown={handleKeyDown}
              placeholder='e.g. "Users who..."'
              autoFocus
              ref={inputRef}
            />
          </div>
        </div>
        <div className={styles.toolsList}>
          <AddButton type="funnel" onClick={onClickExploration} />
          <AddButton type="cohort" onClick={onClickExploration} />
          <AddButton type="variable" onClick={onClickExploration} />
          <AddButton type="text" onClick={onClickExploration} />
          <AddDropDownButton onClick={onClickExploration} />
        </div>
        {showResults ? (
          <SearchResults
            explorations={unparameterisedExplorations.concat(modelExplorations)}
            models={models}
            searchTerm={trimmedSearchTerm}
            exploration={exploration}
            onClickExploration={onClickExploration}
            setFocusIndexRef={setResultsListFocusIndex}
            onBlur={() => {
              inputRef.current?.focus();
            }}
          />
        ) : (
          <div
            className={classNames(styles.allExplorations, {
              [styles.scrolled]: scrollPosition > 0,
            })}
            ref={scrollContainerRef}>
            {exploration !== undefined && !isNil(onThisPageListItem) && (
              <CollapsibleSection
                title={onThisPageListItem.getData<CellListData>().title ?? 'On This Page'}
                isCollapsed={onThisPageListItem.getIsCollapsed()}
                onToggleCollapsed={onThisPageListItem.toggleCollapsed}>
                <CellList exploration={exploration} listItems={onThisPageListItem.getChildren()} />
              </CollapsibleSection>
            )}

            {explorationList.slice(1).map((item, i) => {
              if (item === null) {
                return;
              }
              return (
                <CollapsibleSection
                  key={i}
                  title={item.getData<ExplorationListData>().title ?? 'Models'}
                  isCollapsed={item.getIsCollapsed()}
                  onToggleCollapsed={item.toggleCollapsed}>
                  <ExplorationList listItems={item.getChildren()} hideSectionLabels />
                </CollapsibleSection>
              );
            })}
          </div>
        )}
      </section>
    </Panel>
  );
};

interface ExplorationSearchModalProps extends ExplorationSearchProps {
  onClose: () => void;
}

export const ExplorationSearchModal = ({ onClose, ...rest }: ExplorationSearchModalProps) => (
  <Modal onClose={onClose} closeOnClickAway closeOnEsc>
    <ExplorationSearch {...rest} />
  </Modal>
);
