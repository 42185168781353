export const humanReadableIntegrations: any = {
  STRIPE: 'Stripe',
  SHOPIFY: 'Shopify',
  WOOCOMMERCE: 'WooCommerce',
  INSTAGRAM: 'Instagram',
  FACEBOOK_ADS: 'Facebook Ads',
  FACEBOOK_PAGE: 'Facebook Page',
  GOOGLE_ADS: 'Google Ads',
  GOOGLE_ANALYTICS: 'Google Analytics',
  GOOGLE_BIGQUERY: 'Google BigQuery',
};
