import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { AnalyticsContextProvider, useTrackEvent } from '@/lib/analytics';
import { Breakpoint, useScreenSize } from '@/lib/hooks/use-screen-size';
import { useTitle } from '@/lib/hooks/use-title';

import { Cell } from '../types';
import { useExplorationContext } from './exploration-context';
import { useMetadataContext } from '../metadata-context';
import { useDirtyContext } from '../dirty-context';
import { Icon } from '../../components/icon';
import { ScrollToSelected } from './scroll-to-selected';
import { ExplorationSearchModal } from '../exploration-search';
import { ExplorationCell } from './exploration-cell';
import { AddSection } from './add-section';
import {
  getExplorationIconName,
  getExplorationType,
  getVariableDefinitions,
  isModelDetailExploration,
  isPersistedModelDetailExploration,
} from '../utils';
import { Sidebar } from './sidebar';
import { CellDropZone } from './exploration-layout';
import { getCellRows } from './exploration-layout/utils';

import styles from './exploration.module.scss';
import layoutStyles from './exploration-layout/exploration-layout.module.scss';

interface ExplorationViewProps {
  accountId: string;
}

export const ExplorationView = (props: ExplorationViewProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const scrollToRef = useRef<HTMLDivElement | null>(null);
  const selectedCellRef = useRef<HTMLDivElement | null>(null);

  const [explorationListIndex, setExplorationListIndex] = useState<number | null>(null);

  const { exploration, parameters } = useExplorationContext();
  const { models, explorations } = useMetadataContext();
  const { confirmUnsavedChagesIfNeeded } = useDirtyContext();

  const {
    selectedCellIndex,
    isEditorOpen,
    resetExploration,
    scrollToIndex,
    closeEditor,
    addCells,
    openEditor,
    scrollToCell,
    isDirty,
    cellCount,
  } = useExplorationContext();

  const trackEvent = useTrackEvent();

  const screenSize = useScreenSize();

  useTitle(exploration.name);

  useEffect(() => {
    trackEvent('Exploration Opened', { exploration });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exploration.explorationId]);

  const scrollIntoView = (elem?: HTMLElement | null) =>
    elem?.scrollIntoView({ behavior: 'smooth' });

  useEffect(() => {
    if (scrollToIndex !== null && scrollToRef.current !== null) {
      // make sure to scroll to the top for the first cell
      scrollIntoView(scrollToIndex === 0 ? containerRef.current : scrollToRef.current);
      scrollToCell(null);
    }
  }, [scrollToCell, scrollToIndex]);

  const addSection = (index = cellCount) => {
    confirmUnsavedChagesIfNeeded({
      onConfirm: () => {
        closeEditor();
        setExplorationListIndex(index);
      },
    });
  };

  const renderExplorationList = (index: number) => (
    <AnalyticsContextProvider
      properties={{
        intent: 'new block',
        exploration,
        atIndex: index,
      }}>
      <ExplorationSearchModal
        exploration={exploration}
        onClickExploration={(selectedExploration) => {
          addCells(selectedExploration.view.cells, index);
          openEditor({ cellIndex: index });
          scrollToCell(index);
          setExplorationListIndex(null);
          trackEvent('Exploration Cells Added', {
            exploration,
            selectedExploration,
            atIndex: index,
          });
        }}
        onClose={() => setExplorationListIndex(null)}
        selectIndividualCells
      />
    </AnalyticsContextProvider>
  );

  const explorationType = getExplorationType(exploration);
  const explorationIconName = getExplorationIconName(exploration);

  const isDesktopView = screenSize.breakpoint > Breakpoint.md;
  const isDetailExploration = isModelDetailExploration(exploration);
  const isPersisted = isPersistedModelDetailExploration(exploration);

  const rows = useMemo(() => getCellRows(exploration.view.cells), [exploration.view.cells]);

  const getAbsoluteCellIndex = useCallback(
    (rows: Cell[][], rowIndex: number, cellIndex: number) =>
      rows.slice(0, rowIndex).reduce((acc, row) => acc + row.length, 0) + cellIndex,
    [],
  );

  return (
    <div className={styles.explorationContent} ref={containerRef}>
      <div className={styles.splitView}>
        <div className={styles.main}>
          <ScrollToSelected
            direction="up"
            cellIndex={selectedCellIndex}
            cellRef={selectedCellRef}
            onClick={() => scrollToCell(selectedCellIndex)}
          />
          {isDetailExploration ? (
            <>
              {isDirty ? (
                <div className={styles.dirtyHeader}>
                  {isPersisted ? (
                    <h1>
                      Exploring from
                      <button
                        type="button"
                        className={styles.resetButton}
                        title="Reset exploration"
                        onClick={() => resetExploration(parameters)}>
                        <Icon name="Exploration" size={32} />
                        <span className="truncate">{exploration.name}</span>
                      </button>
                    </h1>
                  ) : (
                    <div className={styles.header}>
                      <Icon name="Exploration" size={32} />
                      <h1>{exploration.name}</h1>
                      <div className={styles.templateTag}>Template</div>
                    </div>
                  )}
                </div>
              ) : (
                <div className={styles.header}>
                  <Icon name="Exploration" size={32} />
                  <h1>{exploration.name}</h1>
                  <div className={styles.templateTag}>Template</div>
                </div>
              )}
            </>
          ) : (
            <>
              {isDirty ? (
                <div className={styles.dirtyHeader}>
                  {explorationType === null ? (
                    <h1>Explore</h1>
                  ) : (
                    <h1>
                      {explorationType === 'ai' ? (
                        <Icon name={explorationIconName} size={24} />
                      ) : (
                        <span className={styles.headerLabel}>Exploring from</span>
                      )}
                      <button
                        type="button"
                        className={styles.resetButton}
                        title="Reset exploration"
                        onClick={() => resetExploration(parameters)}>
                        {explorationType !== 'ai' && <Icon name={explorationIconName} size={24} />}
                        <span className="truncate">{exploration.name}</span>
                      </button>
                    </h1>
                  )}
                </div>
              ) : (
                <div className={styles.header}>
                  <Icon name={explorationIconName} size={32} />
                  <h1>{exploration.name}</h1>
                </div>
              )}
            </>
          )}

          {isEditorOpen && <div className={styles.overlay} onClick={closeEditor} />}
          <CellDropZone index={0} addBefore mergeIntoRow={false}>
            <AddSection onClick={() => addSection(0)} compact={false} />
          </CellDropZone>
          {explorationListIndex === 0 && renderExplorationList(0)}
          {rows.map((cells, rowIndex) => {
            const nextRowCellIndex = getAbsoluteCellIndex(rows, rowIndex + 1, 0);
            const isLastRow = rowIndex === rows.length - 1;
            return (
              <Fragment key={rowIndex}>
                <div className={layoutStyles.explorationRow}>
                  {cells.map((cell, i) => {
                    const cellIndex = getAbsoluteCellIndex(rows, rowIndex, i);
                    return (
                      <Fragment key={cell.id}>
                        <ExplorationCell
                          index={cellIndex}
                          accountId={props.accountId}
                          exploration={exploration}
                          explorations={explorations}
                          parameters={parameters}
                          models={models}
                          cell={cell}
                          selected={cellIndex === selectedCellIndex}
                          ref={(element) => {
                            if (cellIndex === scrollToIndex) {
                              scrollToRef.current = element;
                            }
                            if (cellIndex === selectedCellIndex) {
                              selectedCellRef.current = element;
                            }
                          }}
                          variables={getVariableDefinitions(exploration)}
                          isCollapsible={cells.length === 1 || !isDesktopView}
                          onClick={() => isDesktopView && openEditor({ cellIndex })}
                        />
                        {i < cells.length - 1 && (
                          <CellDropZone
                            index={cellIndex}
                            vertical
                            mergeIntoRow
                            className={layoutStyles.cellSeparator}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                </div>
                <CellDropZone index={nextRowCellIndex - 1} mergeIntoRow={false}>
                  <AddSection onClick={() => addSection(nextRowCellIndex)} compact={!isLastRow} />
                </CellDropZone>
                {explorationListIndex === nextRowCellIndex &&
                  renderExplorationList(nextRowCellIndex)}
              </Fragment>
            );
          })}
          <ScrollToSelected
            direction="down"
            cellIndex={selectedCellIndex}
            cellRef={selectedCellRef}
            onClick={() => scrollToCell(selectedCellIndex)}
          />
        </div>
        <Sidebar onAddSection={() => addSection()} />
      </div>
    </div>
  );
};
