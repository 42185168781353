import { useState } from 'react';

import { useUpdateNavigationMutation } from '@/graphql';
import { useSelectedAccount } from '@/lib/accounts/context';
import { useTrackEvent } from '@/lib/analytics';

import { Section } from '../common';
import { NavigationItemsList, NavigationItem } from './list';
import { Select } from '../../components/form/select';
import { Button } from '../../components/button';
import { Icon } from '../../components/icon';

import commonStyles from '../common/common.module.scss';
import styles from './section.module.scss';

interface NavigationSectionProps {
  navigation: NavigationItem[];
  explorations: { name: string; explorationId: string }[];
  canEdit: boolean;
}

const explorationSelected = (explorationId: string) => explorationId !== '';

export const NavigationSection = (props: NavigationSectionProps) => {
  const { navigation, explorations, canEdit } = props;
  const [selectedExplorationId, setSelectedExplorationId] = useState<string>('');
  const [updateNavigationMutation, { loading }] = useUpdateNavigationMutation();
  const account = useSelectedAccount();
  const trackEvent = useTrackEvent();

  if (account === null) {
    throw new Error('NavigationSection should be rendered in Account Context.');
  }

  const handleAdd = (explorationId: string) => {
    const updatedNavigation = [
      ...navigation,
      {
        kind: 'exploration',
        label: explorations.find((e) => e.explorationId === explorationId)?.name ?? '',
        explorationId,
      },
    ];
    handleUpdate(updatedNavigation);
    trackEvent('Navigation item added');
  };

  const handleRemove = (item: NavigationItem) => {
    const updatedNavigation = navigation.filter((i) => i.explorationId !== item.explorationId);
    handleUpdate(updatedNavigation);
    trackEvent('Navigation item removed');
  };

  const handleMoveUp = (item: NavigationItem) => {
    const index = navigation.findIndex((i) => i.explorationId === item.explorationId);
    const updatedNavigation = [...navigation];
    updatedNavigation[index] = updatedNavigation[index - 1];
    updatedNavigation[index - 1] = item;
    handleUpdate(updatedNavigation);
    trackEvent('Navigation item moved up');
  };

  const handleMoveDown = (item: NavigationItem) => {
    const index = navigation.findIndex((i) => i.explorationId === item.explorationId);
    const updatedNavigation = [...navigation];
    updatedNavigation[index] = updatedNavigation[index + 1];
    updatedNavigation[index + 1] = item;
    handleUpdate(updatedNavigation);
    trackEvent('Navigation item moved down');
  };

  const handleUpdate = (navigation: NavigationItem[]) => {
    updateNavigationMutation({
      variables: {
        accountId: account.accountId,
        input: navigation,
      },
      refetchQueries: ['AccountSettings', 'Navigation'],
    });
  };

  if (navigation.length === 0 && !canEdit) {
    return null;
  }

  return (
    <Section title="Main Navigation">
      {navigation.length === 0 ? (
        <div className={commonStyles.emptyView}>Add explorations to main navigation.</div>
      ) : (
        <NavigationItemsList
          items={navigation}
          onRemove={handleRemove}
          onMoveUp={handleMoveUp}
          onMoveDown={handleMoveDown}
          canEdit={canEdit}
        />
      )}
      {canEdit && (
        <div className={styles.add}>
          <Icon className={styles.icon} name="Exploration" />
          <Select
            value={selectedExplorationId}
            options={[
              {
                label: 'Select an exploration...',
                value: '',
                disabled: true,
              },
              ...explorations.map(({ name, explorationId }) => ({
                label: name,
                value: explorationId,
                disabled: navigation.some((item) => item.explorationId === explorationId),
              })),
            ]}
            size="small"
            onChange={setSelectedExplorationId}
          />
          <Button
            type="outlined"
            size="small"
            onClick={() => {
              explorationSelected(selectedExplorationId) && handleAdd(selectedExplorationId);
              setSelectedExplorationId('');
            }}
            loading={loading}
            disabled={!explorationSelected(selectedExplorationId)}>
            Add menu item
          </Button>
        </div>
      )}
    </Section>
  );
};
