import { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  Scanner,
  isPenguinoError,
  translateToPrql,
  Variables,
  Token,
  serializeTokens,
} from '@gosupersimple/penguino';

import { Field, Model } from '@/explore/types';
import { FieldGroup, isField, isFieldGroup } from '@/explore/pipeline/utils';
import {
  adjustForHumanizedPenguino,
  convertFieldsForPenguinoContext,
  dehumanizeExpressionTokens,
  humanizeExpressionTokens,
} from '@/explore/utils/penguino';

import { TextArea, TextAreaProps } from '../text-area';
import { PenguinoAutoComplete } from './penguino-autocomplete';
import { SyntaxHighlighting, SyntaxIssue } from './syntax-highlighting';

import styles from './penguino-input.module.scss';

export const PenguinoVersion = '1';

interface PenguinoInputProps extends Omit<TextAreaProps, 'onChange' | 'highlighted'> {
  fields: (Field | FieldGroup)[];
  variables: Variables;
  model?: Model;
  onChange: (value: string) => void;
  requiredType?: string;
}

export const PenguinoInput = (props: PenguinoInputProps) => {
  const [caretPosition, setCaretPosition] = useState<number | null>(null);
  const [forcedCaretPosition, setForcedCaretPosition] = useState<number | null>(null);
  const [inputFocused, setInputFocused] = useState(false);
  const [showIssueMessages, setShowIssueMessages] = useState(false);

  const preRef = useRef<HTMLPreElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const syncScroll = (e: React.UIEvent<HTMLTextAreaElement>) => {
    if (!preRef.current) {
      return;
    }
    preRef.current.scrollTop = e.currentTarget.scrollTop;
  };

  // Add default fields to context in a keyless group so both `prop(myField)` and `prop(myTable.myField)`
  // become `prop("My Field (on My Table)")`
  const defaultFieldGroup = props.fields
    .filter(isFieldGroup)
    .find((group) => group.key === props.model?.modelId);

  const penguinoFields = useMemo(
    () =>
      convertFieldsForPenguinoContext([
        ...props.fields.filter(isFieldGroup),
        { fields: props.fields.filter(isField) },
        ...(defaultFieldGroup !== undefined ? [{ ...defaultFieldGroup, key: undefined }] : []),
      ]),
    [props.fields, defaultFieldGroup],
  );

  const tokens = useMemo(
    () => new Scanner(String(props.value ?? ''), { safe: true }).scanTokens(),
    [props.value],
  );

  const humanizedTokens = useMemo(
    () => humanizeExpressionTokens(tokens, penguinoFields),
    [tokens, penguinoFields],
  );

  const issues: SyntaxIssue[] = useMemo(() => {
    const value = String(props.value ?? '');
    if (value === '') {
      return [];
    }

    try {
      const { returnType } = translateToPrql(value, {
        fields: penguinoFields,
        variables: props.variables,
      });

      if (props.requiredType !== undefined && returnType !== props.requiredType) {
        throw new Error(`Expected type ${props.requiredType}, got ${returnType}`);
      }

      return [];
    } catch (e) {
      if (isPenguinoError(e)) {
        return [adjustForHumanizedPenguino(e, tokens, penguinoFields)];
      }
      if (e instanceof Error) {
        return [{ message: e.message, line: 1, start: 0, end: value.length }];
      }
      return [{ message: 'Unknown syntax error', line: 1, start: 0, end: 0 }];
    }
  }, [props.value, props.variables, props.requiredType, penguinoFields, tokens]);

  const handleCaretMove = () => {
    setCaretPosition(inputRef.current?.selectionStart ?? caretPosition);
  };

  const handleAutoComplete = (output: Token[], caretPosition: number) => {
    props.onChange(serializeTokens(dehumanizeExpressionTokens(output, penguinoFields)));
    setCaretPosition(caretPosition);
    setForcedCaretPosition(caretPosition);
  };

  const forceCaretPosition = (position: number) => {
    inputRef.current?.focus();
    inputRef.current?.setSelectionRange(position, position);
  };

  const handleBlur = () => {
    if (forcedCaretPosition === null) {
      setInputFocused(false);
    }
  };

  // Force caret position and focus after autocompletion via keyboard
  useEffect(() => {
    if (forcedCaretPosition !== null) {
      // setTimeout is necessary to force focus after rendering and any blur event (which happens later)
      setTimeout(() => forceCaretPosition(forcedCaretPosition), 0);
      setForcedCaretPosition(null);
    }
  }, [forcedCaretPosition]);

  // Value and caret position are updated separately so they are out of sync in brief moments
  const constrainedCaretPosition = Math.min(
    caretPosition ?? 0,
    serializeTokens(humanizedTokens).length,
  );

  return (
    <>
      <div
        className={classNames(styles.penguinoInput, {
          [styles.sizeSmall]: props.size === 'small',
          [styles.sizeMedium]: props.size === 'regular',
          [styles.sizeLarge]: props.size === 'large',
        })}>
        <SyntaxHighlighting
          tokens={humanizedTokens}
          issues={issues}
          showIssueMessages={showIssueMessages}
          preRef={preRef}
        />
        <TextArea
          {...props}
          value={serializeTokens(humanizedTokens)}
          className={classNames(props.className, styles.editableInput)}
          onScroll={syncScroll}
          spellCheck={false}
          highlighted={inputFocused}
          onFocus={() => setInputFocused(true)}
          onBlur={handleBlur}
          onChange={(e) => {
            return props.onChange(
              serializeTokens(
                dehumanizeExpressionTokens(
                  new Scanner(String(e.target.value), { safe: true }).scanTokens(),
                  penguinoFields,
                ),
              ),
            );
          }}
          onKeyDown={handleCaretMove}
          onKeyUp={handleCaretMove}
          onMouseDown={handleCaretMove}
          onMouseUp={handleCaretMove}
          onMouseEnter={() => setShowIssueMessages(true)}
          onMouseLeave={() => setShowIssueMessages(false)}
          ref={inputRef}
        />
      </div>
      {inputFocused && (
        <PenguinoAutoComplete
          tokens={humanizedTokens}
          caretPosition={constrainedCaretPosition}
          onSelect={handleAutoComplete}
          fields={props.fields}
          inputRef={inputRef}
        />
      )}
    </>
  );
};
