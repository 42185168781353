import { MouseEventHandler } from 'react';
import classNames from 'classnames';

import { Icon } from '../../components/icon';

import style from './add-section.module.scss';

interface AddSectionProps {
  compact?: boolean;
  onClick: MouseEventHandler;
}

export const AddSection = ({ compact = true, onClick }: AddSectionProps) => (
  <div className={classNames(style.addSection, { [style.compact]: compact })}>
    <div className={style.line} />
    <div className={style.label} onClick={onClick}>
      <Icon name="Plus" size={12} color="currentColor" />
      <div className={style.text}>New block</div>
    </div>
    <div className={style.line} />
  </div>
);
