import { Suspense, lazy, useEffect, useState } from 'react';
import { first } from 'lodash';
import classNames from 'classnames';
const CodeEditor = lazy(() => import('@uiw/react-textarea-code-editor'));

import { useAccountContext } from '@/lib/accounts/context';
import { Button } from '@/components/button';
import { Icon } from '@/components/icon';
import { Loader } from '@/components/loader';
import { ErrorBoundary, GenericFallback } from '@/lib/error';
import { useIsInView, useLoadingStatus, useQueryLoadCondition } from '@/lib/hooks';

import { DereferencedPipeline, Fields, Model, Sort, SortItem, SqlCell } from '../../types';
import { getNodes, mapSort, useExplorationDataQuery } from '../../../graphql';
import { PaginatedRecords } from '../../components/paginated-records';
import { HorizontalScrollTable } from '../../components/horizontal-scroll-table';
import { MasterBadge } from '../../components/master-badge';
import { DataTableProperty, DataTableRow } from '../../components/datatable';
import { CollapseButton, CollapsibleContainer, CollapsibleContent } from '../collapsible-cell';
import { CellTitle } from '../cell-title';
import { CellControls } from '../cell-controls';
import { useExplorationContext } from '../exploration-context';
import { useExplorationCellContext } from '../exploration-cell-context';
import { convertRecordTypeTypes } from '../../input';
import { useMetadataContext } from '../../metadata-context';
import { createSqlCellInstance, getSqlOperation, setSqlOperation } from './utils';
import { getNumberOfChildPipelines } from '../../pipeline/utils';

import container from '../exploration.module.scss';
import style from './sqlcell.module.scss';
import editorStyle from '../code-editor.module.scss';

interface SqlCellViewProps {
  cell: SqlCell;
  onSetDraggable: (value: boolean) => void;
}

export const SqlCellView = (props: SqlCellViewProps) => {
  const { exploration, setExploration, scrollToCell } = useExplorationContext();
  const cell = props.cell;
  const { setCell, isCollapsible, cellIndex } = useExplorationCellContext();
  const [sql, setSql] = useState(getSqlOperation(cell.pipeline)?.parameters.sql ?? '');
  const [containerRef, isInView] = useIsInView();

  const setFields = (sql: string, fields: Fields) => {
    setCell({
      ...cell,
      pipeline: setSqlOperation(cell.pipeline, sql, fields),
    });
  };

  const handleCreateInstance = (sql: string, fields: Fields) => {
    setExploration(createSqlCellInstance(cell, sql, fields, exploration));
    scrollToCell(cellIndex + 1);
  };

  const deleteAllowed = getNumberOfChildPipelines(exploration, cell.pipeline) === 0;

  return (
    <CollapsibleContainer className={container.cohortViewCell} ref={containerRef}>
      <div className={container.cellHeader}>
        <div className={container.cellControlsContainer}>
          <Icon
            name="DragHandle"
            size={10}
            className={container.dragHandle}
            onMouseOver={() => props.onSetDraggable(true)}
            onMouseOut={() => props.onSetDraggable(false)}
          />
          <MasterBadge exploration={exploration} pipeline={props.cell.pipeline} />
          <CellTitle
            exploration={exploration}
            value={cell.title ?? '(Untitled)'}
            onChange={(value) => setCell({ ...cell, title: value })}
          />
          <CellControls
            exploration={exploration}
            editButtonVisible={false}
            canDelete={deleteAllowed}
          />
          {isCollapsible && <CollapseButton />}
        </div>
      </div>
      <CollapsibleContent>
        <ErrorBoundary fallback={(errorData) => <GenericFallback {...errorData} />}>
          <SqlView
            sql={sql}
            setSql={setSql}
            setFields={setFields}
            onCreateInstance={handleCreateInstance}
            isInView={isInView}
          />
        </ErrorBoundary>
      </CollapsibleContent>
    </CollapsibleContainer>
  );
};

interface SqlViewProps {
  sql: string;
  setSql: (sql: string) => void;
  setFields: (sql: string, fields: Fields) => void;
  onCreateInstance: (sql: string, fields: Fields) => void;
  isInView: boolean;
}

const SqlView = (props: SqlViewProps) => {
  const sql = props.sql.trim();

  const { account } = useAccountContext();
  const { models } = useMetadataContext();
  const { isCollapsed } = useExplorationCellContext();
  const [onCompleted, onError, skip] = useQueryLoadCondition(
    props.isInView,
    !isCollapsed,
    sql !== '',
  );

  const baseModelId = first(models)?.modelId ?? '';

  const [sort, setSort] = useState<SortItem | null>(null);

  const pipeline = {
    baseModelId,
    operations: [{ operation: 'sql' as const, parameters: { sql } }],
  };
  const variables = {
    accountId: account.accountId,
    baseModelId: pipeline.baseModelId,
    pipeline: pipeline.operations,
    sort: mapSort(sort === null ? [] : [sort]),
  };
  const { data, loading, error, fetchMore } = useExplorationDataQuery({
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
    skip,
    onCompleted,
    onError,
  });

  // NOTE! If not modified carefully, this piece of code may cause unnecessary re-renders.
  useEffect(() => {
    const fields = convertRecordTypeTypes(data?.account?.query?.recordType);
    if (fields !== undefined) {
      props.setFields(sql, fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const loaded = data?.account?.query !== undefined;
  const records = getNodes(data?.account?.query);
  const recordType = convertRecordTypeTypes(data?.account?.query?.recordType) ?? [];

  const handleFetchMore = async () => {
    if (loading || !(data?.account?.query?.pageInfo.hasNextPage ?? false)) {
      return;
    }

    fetchMore({ variables: { after: data?.account?.query?.pageInfo.endCursor } });
  };

  return (
    <>
      <div className={classNames(container.cellSection, style.inputSection)}>
        <SQLInputView
          sql={props.sql}
          setSql={props.setSql}
          error={error?.message}
          onCreateInstance={() => props.onCreateInstance(sql, recordType)}
        />
      </div>
      <div className={classNames(container.cellSection, container.tableSection)}>
        <SQLResponseView
          records={records}
          pipeline={pipeline}
          fetchMore={handleFetchMore}
          properties={recordType}
          sort={sort === null ? [] : [sort]}
          setSort={setSort}
          loading={loading}
          loaded={loaded}
        />
      </div>
    </>
  );
};

interface SQLInputViewProps {
  sql: string;
  setSql: (sql: string) => void;
  onCreateInstance: () => void;
  error?: string;
}

const SQLInputView = (props: SQLInputViewProps) => {
  const [sql, setSql] = useState(props.sql);
  const [hasFocus, setHasFocus] = useState(false);
  const [sqlError, setSqlError] = useState<string | undefined>();

  useEffect(() => setSql(props.sql), [props.sql]);

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      handleSubmit();
    }

    if (event.key === 'Tab') {
      event.preventDefault();
    }
  };

  const handleSubmit = () => {
    setSqlError(undefined);

    if (sql === '') {
      return;
    }

    if (!sql.toLowerCase().startsWith('select')) {
      setSqlError('Query must start with "SELECT"');
      return;
    }

    props.setSql(sql);
  };

  const error = props.error ?? sqlError;

  return (
    <div className={editorStyle.inputContainer}>
      <Suspense fallback={<div className={editorStyle.codeInput} />}>
        <CodeEditor
          value={sql}
          language="sql"
          className={classNames(editorStyle.codeInput, {
            [editorStyle.hasFocus]: hasFocus,
            [editorStyle.hasError]: error !== undefined,
          })}
          padding={10}
          data-color-mode="dark"
          minHeight={200}
          onChange={(e) => setSql(e.target.value)}
          onKeyDown={onKeyDown}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
        />
      </Suspense>

      <div className={editorStyle.buttons}>
        <Button type="primary" size="small" onClick={handleSubmit}>
          Execute
        </Button>

        <Button
          type="outlined"
          size="small"
          onClick={props.onCreateInstance}
          icon={<Icon name="Instance" size={16} />}>
          Create Instance
        </Button>
      </div>

      {error !== undefined && (
        <div className={style.errorContainer}>
          <Icon name="Alert" /> {error}
        </div>
      )}
    </div>
  );
};

const doModel = (properties: DataTableProperty[]): Model => ({
  modelId: 'sql-results',
  name: 'SQL',
  primaryKey: [],
  properties,
  relations: [],
  labels: {},
});

interface SQLResponseViewProps {
  records: DataTableRow[];
  pipeline: DereferencedPipeline;
  fetchMore: () => Promise<void>;
  properties: DataTableProperty[];
  sort: Sort;
  setSort: (sort: SortItem | null) => void;
  loaded: boolean;
  loading: boolean;
}

const SQLResponseView = (props: SQLResponseViewProps) => {
  const { cell } = useExplorationCellContext();
  const { isFirstLoad, isSubsequentLoad } = useLoadingStatus(props.loading, props.loaded);

  if (!props.loaded && !props.loading) {
    return (
      <div className={editorStyle.empty}>
        <div className={editorStyle.text}>Execute code to see results</div>
      </div>
    );
  }

  return (
    <PaginatedRecords
      properties={props.properties}
      pipeline={props.pipeline}
      sort={props.sort}
      setSort={props.setSort}
      records={props.records}
      model={doModel(props.properties)}
      fetchMore={props.fetchMore}
      cellHeight={cell.viewOptions?.height}
      footerContent={isSubsequentLoad && <Loader />}
      canEditPipeline={false}
      loading={isFirstLoad}
      component={HorizontalScrollTable}
    />
  );
};
