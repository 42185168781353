import { forwardRef, useState } from 'react';
import classNames from 'classnames';

import { ErrorBoundary, GenericFallback } from '@/lib/error';

import { RecordsCellView } from './records-cell-view';
import { MetricCellView } from './metrics-cell-view';
import { FunnelCellView } from './funnel-cell';
import { CohortCellView } from './cohort-cell';
import { VariableCellView } from './variable-cell';
import { SqlCellView } from './sql-cell';
import { PythonCellView } from './python-cell';
import { TextCellView } from './text-cell';
import { Cell, Exploration, ExplorationParameters, Model, VariableDefinition } from '../types';
import { ExplorationCellContextProvider } from './exploration-cell-context';
import { DraggableCell } from './exploration-layout';
import { InvalidCellView } from './invalid-cell';

import styles from './exploration.module.scss';

interface ExplorationCellProps {
  accountId: string;
  exploration: Exploration;
  models: Model[];
  explorations: Exploration[];
  parameters: ExplorationParameters;
  index: number;
  cell: Cell;
  selected?: boolean;
  variables: VariableDefinition[];
  isCollapsible: boolean;
  onClick: () => void;
}

export const ExplorationCell = forwardRef(function ExplorationCell(
  props: ExplorationCellProps,
  ref?: React.ForwardedRef<HTMLDivElement | null>,
) {
  const {
    accountId,
    exploration,
    cell,
    index,
    parameters,
    selected = false,
    variables,
    onClick,
  } = props;

  const [isDraggable, setIsDraggable] = useState(false);

  return (
    <ErrorBoundary fallback={<GenericFallback />}>
      <ExplorationCellContextProvider
        cell={cell}
        cellIndex={index}
        isCollapsible={props.isCollapsible}>
        <DraggableCell
          index={index}
          isDraggable={isDraggable}
          selected={selected}
          height={cell.viewOptions?.height}>
          <div
            className={classNames(styles.cell, {
              [styles.selectedCell]: selected,
              [styles.transparent]: ['text'].includes(cell.kind),
            })}
            onClick={onClick}
            ref={ref}>
            {cell.kind === 'metric' ? (
              <MetricCellView cell={cell} accountId={accountId} parameters={parameters} />
            ) : cell.kind === 'records' ? (
              <RecordsCellView
                cell={cell}
                exploration={exploration}
                parameters={parameters}
                onSetDraggable={(value) => setIsDraggable(value)}
              />
            ) : cell.kind === 'funnel' ? (
              <FunnelCellView
                accountId={accountId}
                cell={cell}
                exploration={exploration}
                onSetDraggable={(value) => setIsDraggable(value)}
              />
            ) : cell.kind === 'variable' ? (
              <VariableCellView
                cell={cell}
                variables={variables}
                onSetDraggable={(value) => setIsDraggable(value)}
              />
            ) : cell.kind === 'sql' ? (
              <SqlCellView cell={cell} onSetDraggable={(value) => setIsDraggable(value)} />
            ) : cell.kind === 'text' ? (
              <TextCellView
                cell={cell}
                selected={selected}
                onSetDraggable={(value) => setIsDraggable(value)}
              />
            ) : cell.kind === 'python' ? (
              <PythonCellView cell={cell} onSetDraggable={(value) => setIsDraggable(value)} />
            ) : cell.kind === 'cohort' ? (
              <CohortCellView
                accountId={accountId}
                cell={cell}
                exploration={exploration}
                onSetDraggable={(value) => setIsDraggable(value)}
              />
            ) : cell.kind === 'invalid' ? (
              <InvalidCellView
                cell={cell}
                exploration={exploration}
                onSetDraggable={(value) => setIsDraggable(value)}
              />
            ) : null}
          </div>
        </DraggableCell>
      </ExplorationCellContextProvider>
    </ErrorBoundary>
  );
});
