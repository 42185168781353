import { generateVisualisation } from '../components/visualisation/utils';
import {
  DereferencedPipeline,
  Exploration,
  Metric,
  MetricV2,
  Model,
  PipelineOperation,
} from '../types';
import { generateCellId } from '../utils';

export const getMetricExploration = (metric: Metric, models: Model[]): Exploration => ({
  explorationId: metric.metricId,
  name: metric.name,
  labels: { type: 'metric', service: metric.services[0] },
  parameters: [],
  view: {
    cells: [
      {
        id: generateCellId(),
        kind: 'records',
        title: metric.name,
        pipeline: {
          baseModelId: metric.pipeline.baseModelId,
          operations: metric.pipeline.operations as PipelineOperation[], // TODO: replace with correct type from @types
        },
        visualisations: [
          generateVisualisation(metric.pipeline as DereferencedPipeline, {
            models,
            variables: [],
            metrics: [],
          }),
        ],
      },
    ],
  },
});

export const formatMetricV2Label = (metricName: string) => `Metric: ${metricName}`;

export const metricV2ById = (metrics: MetricV2[], metricId: string) =>
  metrics.find((metric) => metric.metricId === metricId);

export const metricV2sByModelId = (metrics: MetricV2[], modelId: string) =>
  metrics.filter((metric) => metric.definition.modelId === modelId);

export const metricV2AggregationOptions = (metrics: MetricV2[]) =>
  metrics.map((metric) => ({ label: formatMetricV2Label(metric.name), value: metric.metricId }));
