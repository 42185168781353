import { useState } from 'react';
import classNames from 'classnames';

import { Input } from '../../components/form/input';
import { Select } from '../../components/form/select';
import { Button } from '../../components/button';

import styles from './step-welcome-survey.module.scss';
import formStyles from '../../components/form/form.module.scss';

export type WelcomeSurveyFormData = {
  companyName: string;
  companySize: (typeof CompanySizeOptions)[number]['value'];
  role: (typeof RoleOptions)[number]['value'];
};

interface WelcomeSurveyFormProps {
  onSubmit: (data: WelcomeSurveyFormData) => void;
  loading?: boolean;
}

const CompanySizeOptions = [
  { value: '', label: 'Select company size...' },
  { value: '<10', label: '< 10 employees' },
  { value: '11-40', label: '11-40 employees' },
  { value: '41-100', label: '41-100 employees' },
  { value: '100+', label: '100+ employees' },
] as const;

const RoleOptions = [
  { value: '', label: 'Select role...' },
  { value: 'founder_executive', label: 'Founder/Executive' },
  { value: 'product', label: 'Product' },
  { value: 'engineering', label: 'Engineering' },
  { value: 'data', label: 'Data' },
  { value: 'sales', label: 'Sales' },
  { value: 'customer_success', label: 'Customer Success' },
  { value: 'marketing', label: 'Marketing' },
  { value: 'other', label: 'Other' },
] as const;

export const WelcomeSurveyForm = ({ onSubmit, loading = false }: WelcomeSurveyFormProps) => {
  const [data, setData] = useState<WelcomeSurveyFormData>({
    companyName: '',
    companySize: '',
    role: '',
  });

  const isValid = () => data.companyName !== '' && data.companySize !== '' && data.role !== '';

  const handleChange = (key: keyof WelcomeSurveyFormData) => (value: string) =>
    setData({ ...data, [key]: value });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValid()) {
      return;
    }

    onSubmit(data);
  };

  return (
    <form
      className={classNames(formStyles.formHorizontal, styles.formHorizontal)}
      onSubmit={handleSubmit}>
      <div className={classNames(formStyles.formRow, styles.formRow)}>
        <label
          className={classNames(formStyles.formLabel, styles.formLabel)}
          style={{ minWidth: '25%' }}>
          Company name
        </label>
        <Input
          required
          autoFocus
          type="text"
          placeholder="Acme"
          value={data.companyName}
          size="large"
          onChange={(e) => handleChange('companyName')(e.currentTarget.value)}
        />
      </div>
      <div className={classNames(formStyles.formRow, styles.formRow)}>
        <label
          className={classNames(formStyles.formLabel, styles.formLabel)}
          style={{ minWidth: '25%' }}>
          Company size
        </label>
        <Select
          options={CompanySizeOptions}
          value={data.companySize}
          size="large"
          onChange={handleChange('companySize')}
        />
      </div>
      <div className={classNames(formStyles.formRow, styles.formRow)}>
        <label
          className={classNames(formStyles.formLabel, styles.formLabel)}
          style={{ minWidth: '25%' }}>
          Your role
        </label>
        <Select
          options={RoleOptions}
          value={data.role}
          size="large"
          onChange={handleChange('role')}
        />
      </div>
      <div className={classNames(formStyles.formControls, styles.formControls)}>
        <Button
          htmlType="submit"
          type="primary"
          size="large"
          loading={loading}
          disabled={!isValid()}>
          Continue
        </Button>
      </div>
    </form>
  );
};
