import { generatePipelineId } from '../pipeline/utils';
import { Exploration, PipelineOperation, Model } from '../types';
import { generateExplorationId, generateCellId } from './exploration';

const cache = new Map<string, Exploration>();

export const getModelExploration = (
  model: Pick<Model, 'name' | 'modelId' | 'labels' | 'description'>,
  operations?: PipelineOperation[],
): Exploration => {
  if (!cache.has(model.modelId)) {
    cache.set(model.modelId, {
      explorationId: generateExplorationId(),
      name: model.name,
      description: model.description,
      labels: model.labels,
      parameters: [],
      view: {
        cells: [
          {
            id: generateCellId(),
            kind: 'records',
            title: model.name,
            pipeline: {
              pipelineId: generatePipelineId(),
              baseModelId: model.modelId,
              operations: operations ?? [],
            },
          },
        ],
      },
    });
  }

  return cache.get(model.modelId)!;
};
