import { exploration as output, pipeline } from '@gosupersimple/types';

import { Cell, Exploration, Pipeline } from './types';
import { restoreInvalidCell, restoreInvalidOperation } from './utils';

export const restoreInvalidOperations = (pipeline: Pipeline): Pipeline => {
  return {
    ...pipeline,
    operations: pipeline.operations.map(restoreInvalidOperation),
  };
};

export const exportViewCell = (cell: Cell): output.ViewCell => {
  switch (cell.kind) {
    case 'metric':
      return {
        id: cell.id,
        kind: cell.kind,
        metricId: cell.metricId,
        viewOptions: cell.viewOptions ?? undefined,
      };
    case 'records':
      return {
        id: cell.id,
        kind: cell.kind,
        title: cell.title ?? undefined,
        excludeProperties: cell.excludeProperties ?? undefined,
        pipeline: pipeline.pipelineWithInstances.parse(restoreInvalidOperations(cell.pipeline)),
        visualisations: cell.visualisations,
        sort: cell.sort,
        viewOptions: cell.viewOptions ?? undefined,
      };
    case 'funnel':
      return {
        id: cell.id,
        kind: cell.kind,
        title: cell.title ?? undefined,
        pipeline: pipeline.basePipeline.parse(restoreInvalidOperations(cell.pipeline)),
        viewOptions: cell.viewOptions ?? undefined,
      };
    case 'cohort':
      return {
        id: cell.id,
        kind: 'cohort',
        title: cell.title ?? undefined,
        pipeline: pipeline.pipelineWithInstances.parse(restoreInvalidOperations(cell.pipeline)),
        viewOptions: cell.viewOptions ?? undefined,
      };
    case 'variable':
      return {
        id: cell.id,
        kind: cell.kind,
        definition: cell.definition,
        viewOptions: cell.viewOptions ?? undefined,
      };
    case 'sql':
      return {
        id: cell.id,
        kind: 'sql',
        title: cell.title ?? undefined,
        pipeline: pipeline.basePipeline.parse(restoreInvalidOperations(cell.pipeline)),
        viewOptions: cell.viewOptions ?? undefined,
      };
    case 'python':
      return {
        id: cell.id,
        kind: 'python',
        title: cell.title ?? undefined,
        pipeline: pipeline.basePipeline.parse(restoreInvalidOperations(cell.pipeline)),
        viewOptions: cell.viewOptions ?? undefined,
      };
    case 'text':
      return {
        id: cell.id,
        kind: cell.kind,
        title: cell.title ?? undefined,
        content: cell.content ?? undefined,
        viewOptions: cell.viewOptions ?? undefined,
      };
    case 'invalid':
      return exportViewCell(restoreInvalidCell(cell));
    default:
      throw new Error(`Unknown cell type: ${cell}`);
  }
};

export const exportExploration = (exploration: Exploration): output.Exploration => ({
  description: exploration.description ?? undefined,
  explorationId: exploration.explorationId,
  labels: exploration.labels,
  name: exploration.name,
  parameters: exploration.parameters,
  view: {
    cells: exploration.view.cells.map(exportViewCell),
  },
});
