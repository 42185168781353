import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { compact } from 'lodash';
import { FunctionDefinition, OneOfT, T, Token } from '@gosupersimple/penguino';
import { functions } from '@gosupersimple/penguino/src/library/functions';

import { Field } from '@/explore/types';
import { useNavigableList } from '@/lib/hooks/use-navigable-list';
import { getIconForField } from '@/explore/model/utils';
import { useClientRect } from '@/lib/hooks/use-client-rect';
import { FieldGroup } from '@/explore/pipeline/utils';
import { convertFieldForPenguinoContext } from '@/explore/utils/penguino';

import { Icon } from '../../icon';
import { DropdownMenu } from '../../dropdown';
import { PenguinoDocumentation } from './penguino-documentation';
import { Tooltip } from '../../tooltip';
import { createCaretContext, createCompletion, getFilteredAutoCompleteItems } from './utils';

import styles from './penguino-input.module.scss';

type ListData = {
  title: string;
};

type FieldListItemData = {
  field: Field;
  group: FieldGroup;
};

type FunctionListItemData = {
  definition: FunctionDefinition;
};

type ListItemData = ListData | FieldListItemData | FunctionListItemData;

const functionMatchesTypes = (
  definition: FunctionDefinition,
  types: (T | OneOfT)[],
  only = true,
) => {
  return definition.signatures.every((signature) => {
    if ('arguments' in signature && signature.arguments.length > 0) {
      return (
        signature.arguments.filter((type) => {
          return types.includes(type);
        }).length >= (only ? signature.arguments.length : 1)
      );
    }
    if ('variadic' in signature && signature.variadic.length > 0) {
      return (
        signature.variadic.filter((type) => {
          return types.includes(type);
        }).length >= (only ? signature.variadic.length : 1)
      );
    }
    return false;
  });
};

const getIconForFunction = (definition: FunctionDefinition) => {
  switch (true) {
    case functionMatchesTypes(definition, [T.number]):
      return 'Hash';
    case functionMatchesTypes(definition, [T.string]):
      return 'AlignLeft';
    case functionMatchesTypes(definition, [T.boolean]):
      return 'CheckSquare';
    case definition.name === 'now':
    case definition.name === 'date_diff':
    case functionMatchesTypes(definition, [T.date]):
      return 'Calendar';
    case functionMatchesTypes(definition, [T.object], false):
      return 'Box';
    default:
      return 'Summarise';
  }
};

interface PenguinoAutoCompleteProps {
  tokens: Token[];
  caretPosition: number;
  fields: (Field | FieldGroup)[];
  inputRef: React.RefObject<HTMLTextAreaElement>;
  style?: React.CSSProperties;
  onSelect: (output: Token[], caretPosition: number) => void;
}

export const PenguinoAutoComplete = (props: PenguinoAutoCompleteProps) => {
  const { tokens, caretPosition, fields, onSelect } = props;
  const [suppressed, setSuppressed] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);
  const tooltipRect = useClientRect<HTMLDivElement>(listRef);

  const caretContext = createCaretContext(tokens, caretPosition, fields, Object.values(functions));

  const { fields: filteredFields, functions: fileteredFunctions } =
    getFilteredAutoCompleteItems(caretContext);

  const { list, focusIndex, setFocusIndex, leafCount, focusedItem } =
    useNavigableList<ListItemData>({
      items: [
        ...filteredFields.map((group) => ({
          data: { title: group.name !== undefined ? `Columns on ${group.name}` : 'Columns' },
          children: group.fields.map((field) => ({
            isFocusable: true,
            data: { field, group },
          })),
        })),
        {
          data: { title: 'Functions' },
          children: fileteredFunctions.map((definition) => ({
            isFocusable: true,
            data: { definition },
          })),
        },
      ],
      initialFocusIndex: 0,
      listContainerRef: listRef,
    });

  const handleSelectItem = useCallback(
    (data: FieldListItemData | FunctionListItemData) => {
      const isField = 'field' in data;
      const [completion, newCaretPosition] = createCompletion(
        caretContext,
        isField
          ? {
              name: convertFieldForPenguinoContext(data.field, data.group).name,
              type: 'field',
            }
          : {
              name: data.definition.name,
              type: 'function',
            },
      );
      onSelect(completion, newCaretPosition);
      setFocusIndex(0);
    },
    [caretContext, onSelect, setFocusIndex],
  );

  useEffect(() => {
    const inputElement = props.inputRef.current;
    if (inputElement === null) {
      return;
    }
    const handleKeyDown = (e: KeyboardEvent) => {
      if (leafCount === 0 || suppressed) {
        return;
      }
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setFocusIndex(focusIndex === null ? 0 : focusIndex + 1);
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setFocusIndex(focusIndex === null ? -1 : focusIndex - 1);
      } else if ((e.key === 'Enter' || e.key === 'Tab') && focusedItem !== null) {
        // These key-combinations are preserved for form submissions
        if (e.ctrlKey || e.metaKey) {
          return;
        }

        e.preventDefault();
        handleSelectItem(focusedItem?.getData<FieldListItemData | FunctionListItemData>());
      } else if (e.key === 'Escape') {
        e.preventDefault();
        setSuppressed(true);
      }
    };
    inputElement.addEventListener('keydown', handleKeyDown);
    return () => {
      inputElement.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    focusIndex,
    focusedItem,
    handleSelectItem,
    leafCount,
    props.inputRef,
    setFocusIndex,
    suppressed,
  ]);

  useEffect(() => {
    if (suppressed) {
      setFocusIndex(0);
      setSuppressed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens]);

  useEffect(() => {
    setFocusIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leafCount]);

  const menuItems = useMemo(() => {
    return compact(
      list.flatMap((item) => {
        if (item === null || item.getChildren().length === 0) {
          return null;
        }
        return [
          {
            type: 'header' as const,
            label: item.getData<ListData>().title,
          },
          ...item.getChildren().map((child) => {
            const data = child.getData<FieldListItemData | FunctionListItemData>();
            const isField = 'field' in data;
            const label = isField ? data.field.name : data.definition.name;
            const icon = (
              <Icon
                size={16}
                name={isField ? getIconForField(data.field) : getIconForFunction(data.definition)}
              />
            );
            return {
              label,
              icon,
              focused: child.getIsFocused(),
              onMouseDown: () => handleSelectItem(data),
              onMouseOver: () => child.setIsFocused(true),
            };
          }),
        ];
      }),
    );
  }, [handleSelectItem, list]);

  if (suppressed) {
    return null;
  }

  const focusedData = focusedItem?.getData<FunctionListItemData>();

  return (
    <div className={styles.autoComplete} ref={listRef} style={props.style}>
      {menuItems.length > 0 && (
        <>
          <DropdownMenu items={menuItems} className={styles.autoCompleteMenu} />
          <Tooltip
            left={tooltipRect?.left}
            top={tooltipRect?.top}
            color="light"
            className={styles.documentationTooltip}>
            {focusedData !== undefined && <PenguinoDocumentation item={focusedData} />}
          </Tooltip>
        </>
      )}
    </div>
  );
};
