import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from 'lz-string';
import { isEmpty } from 'lodash';

import { ArrayKeyedMap } from '@/lib/array-keyed-map';

import { Exploration, ExplorationParameters, Metric, Model } from '../types';
import { getLinkableExplorations, isExplorationReferencedByProperty } from './linking';
import { getModelExploration } from './model-exploration';
import { isPersistedModelDetailExploration } from './detail-exploration';
import { getMetricExploration } from '../metrics/utils';
import { migrateVisualisations } from './exploration';

const ExplorationHashKey = 'exploration';
const ParametersHashKey = 'params';

const sanitizeHash = (hash: string) => hash.replace(/^#/, '');

export const getHashParameter = (hash: string, key: string) => {
  const params = new URLSearchParams(sanitizeHash(hash));

  return params.get(key);
};

const setHashParameter = (hash: string, key: string, value: string) => {
  const params = new URLSearchParams(sanitizeHash(hash));
  params.set(key, value);

  return params.toString();
};

const removeHashParameter = (hash: string, key: string): string => {
  const params = new URLSearchParams(sanitizeHash(hash));
  params.delete(key);

  return params.toString();
};

export const decodeExplorationHash = (hash: string): Exploration | null => {
  const encodedExploration = getHashParameter(hash, ExplorationHashKey);

  if (encodedExploration === null || encodedExploration.length === 0) {
    return null;
  }

  const exploration = decodeString<Exploration>(encodedExploration);

  return exploration !== null ? migrateVisualisations(exploration) : null;
};

export const decodeExplorationParamsHash = (hash: string): ExplorationParameters | null => {
  const encodedParams = getHashParameter(hash, ParametersHashKey);

  if (encodedParams === null || encodedParams.length === 0) {
    return null;
  }

  return decodeString<ExplorationParameters>(encodedParams);
};

export const buildExplorationUrl = (
  exploration: Exploration | null,
  parameters: ExplorationParameters | null,
  hash = '',
) => {
  const explorationHash = isEmpty(exploration)
    ? removeHashParameter(hash, ExplorationHashKey)
    : setHashParameter(
        hash,
        ExplorationHashKey,
        compressToEncodedURIComponent(JSON.stringify(exploration)),
      );

  const parametersHash = isEmpty(parameters)
    ? removeHashParameter(explorationHash, ParametersHashKey)
    : setHashParameter(
        explorationHash,
        ParametersHashKey,
        compressToEncodedURIComponent(JSON.stringify(parameters)),
      );

  return `/explore#${parametersHash}`;
};

export const buildSavedExplorationUrl = (
  explorationId: string,
  parameters: ExplorationParameters | null,
) => buildExplorationUrl(null, parameters, `explorationId=${explorationId}`);

export const buildModelExplorationUrl = (modelId: string) =>
  buildExplorationUrl(null, null, `modelId=${modelId}`);

export const buildMetricExplorationUrl = (metricId: string) =>
  buildExplorationUrl(null, null, `metricId=${metricId}`);

const decodeString = <T>(encoded: string) => {
  const decoded = decompressFromEncodedURIComponent(encoded);
  return decoded === null ? null : (JSON.parse(decoded) as T);
};

export const addExplorationUrlBuilders = <
  T extends { key: string; relation?: { key: string; modelId: string } },
>(
  properties: T[],
  explorations: Exploration[],
): (T & { buildLink?: (record: Record<string, unknown>) => string })[] => {
  const linkableExplorations = getLinkableExplorations(properties, explorations);

  const paramToKeyMap = new ArrayKeyedMap(
    properties.map(({ relation, key }) => [[relation?.modelId, relation?.key], key]),
  );

  return properties.map((property) => {
    const exploration = linkableExplorations.find((exploration) =>
      isExplorationReferencedByProperty(exploration, property),
    );

    if (exploration === undefined) {
      return property;
    }

    const buildLink = (record: Record<string, unknown>) => {
      const parameters = exploration.parameters.reduce((acc, { key, modelId }) => {
        const propertyKey = paramToKeyMap.get([modelId, key]);
        if (propertyKey === undefined) {
          return acc;
        }

        return { ...acc, [key]: record[propertyKey] };
      }, {});

      if (isPersistedModelDetailExploration(exploration)) {
        return buildSavedExplorationUrl(exploration.explorationId, parameters);
      }

      return buildExplorationUrl(exploration, parameters);
    };

    return { ...property, buildLink };
  });
};

export const getExplorationFromUrl = (
  hash: string,
  explorations: Exploration[],
  models: Model[],
  metrics: Metric[],
): Exploration | null => {
  const urlParams = new URLSearchParams(sanitizeHash(hash));

  const explorationId = urlParams.get('explorationId');
  if (explorationId !== null) {
    return explorations.find((exploration) => exploration.explorationId === explorationId) ?? null;
  }

  const modelId = urlParams.get('modelId');
  if (modelId !== null) {
    const model = models.find((model) => model.modelId === modelId);
    return model !== undefined ? getModelExploration(model) : null;
  }

  const metricId = urlParams.get('metricId');
  if (metricId !== null) {
    const metric = metrics.find((metric) => metric.metricId === metricId);
    return metric !== undefined ? getMetricExploration(metric, models) : null;
  }

  return null;
};

export function isValidLink(input: unknown) {
  try {
    const { protocol } = new URL(String(input));
    return protocol === 'http:' || protocol === 'https:';
  } catch (_) {
    return false;
  }
}
