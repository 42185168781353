import { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import { useTextAreaAutoResize } from '@/lib/hooks/use-textarea-auto-resize';

import { Icon } from '@/components/icon';

import { TextCell } from '../../types';
import { useExplorationCellContext } from '../exploration-cell-context';
import { useExplorationContext } from '../exploration-context';
import { CellControls } from '../cell-controls';

import styles from './text-cell.module.scss';
import explorationStyles from '../exploration.module.scss';

const AutoResizeTextArea = (props: React.TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useTextAreaAutoResize(textAreaRef);

  return <textarea ref={textAreaRef} {...props} />;
};

interface TextCellViewProps {
  cell: TextCell;
  selected: boolean;
  onSetDraggable: (value: boolean) => void;
}

export const TextCellView = (props: TextCellViewProps) => {
  const { cell, selected } = props;

  const [title, setTitle] = useState(cell.title ?? '');
  const [content, setContent] = useState(cell.content ?? '');

  const { exploration, deleteCell } = useExplorationContext();
  const { setCell } = useExplorationCellContext();

  useEffect(() => {
    if (!selected && isEmpty(title) && isEmpty(content)) {
      deleteCell(cell.id);
    }
  }, [selected, deleteCell, title, content, cell.id]);

  return (
    <div className={styles.textCell}>
      <Icon
        name="DragHandle"
        size={10}
        className={classNames(explorationStyles.dragHandle, styles.dragHandle)}
        onMouseOver={() => props.onSetDraggable(true)}
        onMouseOut={() => props.onSetDraggable(false)}
      />
      {selected || !isEmpty(cell.title) ? (
        <AutoResizeTextArea
          className={styles.title}
          placeholder="Title..."
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          onBlur={() => {
            cell.title !== title && setCell({ ...cell, title } as TextCell);
          }}
          readOnly={!selected}
        />
      ) : null}
      {selected || !isEmpty(cell.content) ? (
        <AutoResizeTextArea
          className={styles.content}
          placeholder="Text..."
          value={content}
          onChange={(e) => setContent(e.target.value)}
          onBlur={() => {
            cell.content !== content && setCell({ ...cell, content } as TextCell);
          }}
          readOnly={!selected}
        />
      ) : null}
      <div className={styles.controls}>
        <CellControls exploration={exploration} />
      </div>
    </div>
  );
};
