import { useEffect } from 'react';

import { useSelectedAccount } from '@/lib/accounts/context';
import { useTrackEvent } from '@/lib/analytics';
import { OnboardingStateKey, useUpdateOnboardingStateMutation } from '@/graphql';
import { useGAPageview } from '@/lib/ga/use-ga';

import { Button } from '../../components/button';
import { useOnboardingStepCheck } from '../use-onboarding-step-check';
import { OnboardingStep } from '../types';
import { BookaCallForm } from '../components/book-a-call-form';
import { useAuth } from '../../auth/auth-context';

import styles from './step-setup-call.module.scss';

const CurrentStep: OnboardingStep = 'setupCall';

const formatRecord = (record: Record<string, string>) => {
  return Object.entries(record)
    .map(([key, value]) => `${key}: ${value}`)
    .join('\n');
};

export const OnboardingSetupCallStep = () => {
  const {
    onboardingState,
    check: checkOnboardingState,
    loading: onboardingStateLoading,
  } = useOnboardingStepCheck(CurrentStep);
  const [updateOnboardingStateMutation, { loading }] = useUpdateOnboardingStateMutation();

  const trackEvent = useTrackEvent();
  useGAPageview();
  const account = useSelectedAccount();
  const { user } = useAuth();

  useEffect(() => {
    trackEvent('Onboarding Setup Call Step Opened', { accountId: account.accountId });
  }, [trackEvent, account.accountId]);

  const handleCallBooked = () => {
    updateOnboardingStateMutation({
      variables: {
        accountId: account.accountId,
        key: OnboardingStateKey.HasBookedSetupCall,
        value: true,
      },
    });

    trackEvent('Onboarding Setup Call Booked', { accountId: account.accountId });
  };

  const handleClickNext = async () => {
    await updateOnboardingStateMutation({
      variables: {
        accountId: account.accountId,
        key: OnboardingStateKey.HasPassedSetupCallStep,
        value: true,
      },
    });
    checkOnboardingState();
  };

  return (
    <BookaCallForm
      email={user?.email ?? ''}
      notes={formatRecord(onboardingState?.welcomeSurvey ?? {})}
      successMessage={
        onboardingState !== undefined && onboardingState.hasConnection
          ? 'Your database is connected!'
          : undefined
      }
      onCallBooked={handleCallBooked}>
      {onboardingState !== undefined && !onboardingState.hasConnection ? (
        <div className={styles.alt}>
          <div className={styles.or}>OR</div>
          <Button
            type="outlined"
            size="large"
            onClick={handleClickNext}
            loading={onboardingStateLoading || loading}>
            Continue setup on my own
          </Button>
        </div>
      ) : null}
    </BookaCallForm>
  );
};
