import { IntegrationService } from '../../generated/graphql';

type ServiceIconProps = { service: string; className?: string };

export const ServiceIcon = ({ service, className }: ServiceIconProps) => {
  if (service === IntegrationService.Shopify) {
    return <img src="/shopify_logo.svg" alt="Shopify logo" className={className} />;
  }
  if (service === IntegrationService.FacebookAds || service === IntegrationService.FacebookPage) {
    return <img src="/fb_logo.svg" alt="Facebook logo" className={className} />;
  }
  if (service === IntegrationService.GoogleAnalytics) {
    return <img src="/ga_logo.svg" alt="Google Analytics logo" className={className} />;
  }
  if (service === IntegrationService.GoogleBigquery) {
    return <img src="/auth-googlebigquery.svg" alt="Google BigQuery logo" className={className} />;
  }
  if (service === IntegrationService.Woocommerce) {
    return <img src="/woocommerce_logo.svg" alt="WooCommerce logo" className={className} />;
  }
  if (service === IntegrationService.GoogleAds) {
    return <img src="/auth-googleads.svg" alt="Google Ads logo" className={className} />;
  }
  return null;
};

type SingleMetricIconProps = {
  service: string;
  metricType: string;
  className?: string;
};

const SingleMetricIcon = ({ service, metricType, className }: SingleMetricIconProps) => {
  if (metricType.includes('INSTAGRAM')) {
    return <img src="/instagram_raster_logo.png" alt="Instagram logo" className={className} />;
  }
  return <ServiceIcon className={className} service={service} />;
};

type MetricIconProps = {
  service: string | string[];
  metricType: string;
  className?: string;
};

export const MetricIcon = ({ service, metricType, className }: MetricIconProps) => {
  if (Array.isArray(service)) {
    return (
      <>
        {service.map((singleService) => (
          <div key={singleService}>
            <SingleMetricIcon
              service={singleService}
              metricType={metricType}
              className={className}
            />
          </div>
        ))}
      </>
    );
  }
  return <SingleMetricIcon service={service} metricType={metricType} className={className} />;
};
