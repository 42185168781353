import React from 'react';
import { first } from 'lodash';

import { useBuildAccountUrl } from '@/lib/accounts/context';

import { Dropdown, DropdownMenuItem } from '@/components/dropdown';

import { useMetadataContext } from '../metadata-context';
import { Exploration } from '../types';
import { Button } from '../../components/button';
import { Icon } from '../../components/icon';
import { buildExplorationUrl } from '../utils';
import { buildExplorationByType, BuildType } from '../utils/builder';

interface AddDropDownButtonProps {
  onClick?: (exploration: Exploration) => void;
}

export const AddDropDownButton = ({ onClick }: AddDropDownButtonProps) => {
  const buildAccountUrl = useBuildAccountUrl();
  const { models } = useMetadataContext();
  const baseModelId = first(models)?.modelId ?? '';

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>, type: BuildType) => {
    if (onClick === undefined) {
      return;
    }

    event.preventDefault();

    onClick(buildExplorationByType(type, baseModelId));
  };

  const codeMenuItems: DropdownMenuItem[] = [
    {
      icon: <Icon name="Database" size={22} />,
      label: 'SQL',
      type: 'link',
      href: buildAccountUrl(buildExplorationUrl(buildExplorationByType('sql', baseModelId), null)),
      onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
        handleClick(e, 'sql');
      },
    },
    {
      icon: <Icon name="Python" size={22} />,
      label: 'Python',
      type: 'link',
      href: buildAccountUrl(
        buildExplorationUrl(buildExplorationByType('python', baseModelId), null),
      ),
      onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
        handleClick(e, 'python');
      },
    },
  ];

  return (
    <Dropdown
      align="both"
      trigger={(isOpen, setIsOpen) => (
        <Button
          icon={<Icon name="Code" size={22} />}
          type="outlined"
          size="large"
          onClick={() => setIsOpen(!isOpen)}>
          Code
        </Button>
      )}
      items={codeMenuItems}
    />
  );
};
