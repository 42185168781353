import * as icons from 'react-feather';

import { AddColumnIcon } from './add-column-icon';
import { Alert } from './alert';
import { CirclePlusIcon } from './circle-plus-icon';
import { CohortIcon } from './cohort-icon';
import { Combine } from './combine';
import { CustomFormulaIcon } from './custom-formula-icon';
import { Database } from './database';
import { Python } from './python';
import { Detach } from './detach';
import { DownArrowIcon } from './down-arrow-icon';
import { DownArrowOutlinedIcon } from './down-arrow-outlined-icon';
import { Duplicate } from './duplicate';
import { Exploration } from './exploration';
import { FunnelIcon } from './funnel-icon';
import { Graphic } from './graphic';
import { HorizontalEqualsIcon } from './horizontal-equals-icon';
import { Instance } from './instance';
import { LineChartIcon } from './line-chart-icon';
import { Model } from './model';
import { Parent } from './parent';
import { RelatedDataIcon } from './related-data-icon';
import { RightArrowIcon } from './right-arrow-icon';
import { RightArrowOutlinedIcon } from './right-arrow-outlined-icon';
import { SearchIcon } from './search-icon';
import { Block } from './block';
import { SummariseIcon } from './summarise-icon';
import { VariableIcon } from './variable-icon';
import { VariableInstanceIcon } from './variable-instance-icon';
import { VerticalEqualsIcon } from './vertical-equals-icon';
import { DragHandleIcon } from './drag-handle-icon';
import { EventIcon } from './event';
import { TableIcon } from './table-icon';
import { CodeIcon } from './code-icon';

export type IconProps = {
  name:
    | keyof typeof icons
    | 'AddColumn'
    | 'Alert'
    | 'CirclePlus'
    | 'Cohort'
    | 'Combine'
    | 'CustomFormula'
    | 'Database'
    | 'Python'
    | 'Detach'
    | 'DownArrow'
    | 'DownArrowOutlined'
    | 'Duplicate'
    | 'Exploration'
    | 'Funnel'
    | 'Graphic'
    | 'HorizontalEquals'
    | 'Instance'
    | 'LineChart'
    | 'Model'
    | 'Parent'
    | 'RelatedData'
    | 'RightArrow'
    | 'RightArrowOutlined'
    | 'Search'
    | 'Block'
    | 'Summarise'
    | 'Variable'
    | 'VariableInstance'
    | 'VerticalEquals'
    | 'DragHandle'
    | 'Event'
    | 'Table';
  size?: number;
} & icons.IconProps;

export const Icon = ({ name, ...rest }: IconProps) => {
  switch (name) {
    case 'AddColumn':
      return <AddColumnIcon size={rest.size} color={rest.color} {...rest} />;
    case 'Alert':
      return <Alert size={rest.size} color={rest.color} {...rest} />;
    case 'CirclePlus':
      return <CirclePlusIcon size={rest.size} color={rest.color} {...rest} />;
    case 'Cohort':
      return <CohortIcon size={rest.size} color={rest.color} {...rest} />;
    case 'Combine':
      return <Combine size={rest.size} color={rest.color} {...rest} />;
    case 'CustomFormula':
      return <CustomFormulaIcon size={rest.size} color={rest.color} {...rest} />;
    case 'Database':
      return <Database size={rest.size} color={rest.color} {...rest} />;
    case 'Python':
      return <Python size={rest.size} color={rest.color} {...rest} />;
    case 'Detach':
      return <Detach size={rest.size} color={rest.color} {...rest} />;
    case 'DownArrow':
      return <DownArrowIcon size={rest.size} color={rest.color} {...rest} />;
    case 'DownArrowOutlined':
      return <DownArrowOutlinedIcon size={rest.size} color={rest.color} {...rest} />;
    case 'Duplicate':
      return <Duplicate size={rest.size} color={rest.color} {...rest} />;
    case 'Exploration':
      return <Exploration size={rest.size} color={rest.color} {...rest} />;
    case 'Funnel':
      return <FunnelIcon size={rest.size} color={rest.color} {...rest} />;
    case 'Graphic':
      return <Graphic size={rest.size} color={rest.color} {...rest} />;
    case 'HorizontalEquals':
      return <HorizontalEqualsIcon size={rest.size} color={rest.color} {...rest} />;
    case 'Instance':
      return <Instance size={rest.size} color={rest.color} {...rest} />;
    case 'LineChart':
      return <LineChartIcon size={rest.size} color={rest.color} {...rest} />;
    case 'Model':
      return <Model size={rest.size} color={rest.color} {...rest} />;
    case 'Parent':
      return <Parent size={rest.size} color={rest.color} {...rest} />;
    case 'RelatedData':
      return <RelatedDataIcon size={rest.size} color={rest.color} {...rest} />;
    case 'RightArrow':
      return <RightArrowIcon size={rest.size} color={rest.color} {...rest} />;
    case 'RightArrowOutlined':
      return <RightArrowOutlinedIcon size={rest.size} color={rest.color} {...rest} />;
    case 'Search':
      return <SearchIcon size={rest.size} color={rest.color} {...rest} />;
    case 'Block':
      return <Block size={rest.size} color={rest.color} {...rest} />;
    case 'Summarise':
      return <SummariseIcon size={rest.size} color={rest.color} {...rest} />;
    case 'Variable':
      return <VariableIcon size={rest.size} color={rest.color} {...rest} />;
    case 'VariableInstance':
      return <VariableInstanceIcon size={rest.size} color={rest.color} {...rest} />;
    case 'VerticalEquals':
      return <VerticalEqualsIcon size={rest.size} color={rest.color} {...rest} />;
    case 'DragHandle':
      return <DragHandleIcon size={rest.size} color={rest.color} {...rest} />;
    case 'Event':
      return <EventIcon size={rest.size} color={rest.color} {...rest} />;
    case 'Table':
      return <TableIcon size={rest.size} color={rest.color} {...rest} />;
    case 'Code':
      return <CodeIcon size={rest.size} color={rest.color} {...rest} />;
    default:
      // eslint-disable-next-line no-case-declarations
      const IconComponent = icons[name];
      return <IconComponent {...rest} />;
  }
};
