import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { isNil, nth, uniq } from 'lodash';

import { notNil } from '@/lib/utils';
import { useTrackEvent } from '@/lib/analytics';
import { useScrollPosition } from '@/lib/hooks/use-scroll-position';

import {
  NavigableListItemInput,
  NavigableListItemOutput,
  useNavigableList,
} from '@/lib/hooks/use-navigable-list';

import { Icon } from '../../components/icon';
import { Modal } from '../../components/modal';
import { Panel } from '../../components/panel';
import { NoMatchBanner } from '../../components/banner';
import { Cell, Exploration, Model, ModelKind } from '../types';
import { getModelExploration } from '../utils';
import { CollapsibleSection } from './collapsible-section';
import { CellList } from './cell-list';
import { ExplorationList } from './exploration-list';
import { dereferencePipeline } from '../pipeline/utils';
import {
  explorationHasReplicableCells,
  extractCellFromExploration,
  isReplicableCell,
} from '../exploration/utils';
import { getModelOrThrow, getModelKind } from '../model/utils';
import { ExplorationListData, ListItemData } from '.';
import { InlineButton } from '../../components/button';
import { Tip } from '../../components/tip';
import { useMetadataContext } from '../metadata-context';

import styles from './exploration-search.module.scss';

const modelKindSectionTitles: { [key in ModelKind]: string } = {
  Account: 'Accounts',
  User: 'Users',
  Event: 'Events',
  Entity: 'Entities',
};

interface PipelineSearchProps {
  explorations: Exploration[];
  exploration?: Exploration;
  onSelectModel: (model: Model) => void;
  onSelectCell: (cell: Cell, dependencies: Cell[]) => void;
  kinds?: ModelKind[];
  title?: string;
  className?: string;
}

const PipelineSearch = (props: PipelineSearchProps) => {
  const { explorations, exploration, onSelectModel, onSelectCell, kinds, className } = props;
  const { models } = useMetadataContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [showOtherModels, setShowOtherModels] = useState(false);
  const otherModelsShown = useRef(false);
  const [scrollPosition, scrollContainerRef] = useScrollPosition<HTMLDivElement>();
  const inputRef = useRef<HTMLInputElement>(null);
  const otherModelsContainerRef = useRef<HTMLDivElement>(null);
  const trackEvent = useTrackEvent();

  const trimmedSearchTerm = searchTerm.trim().toLocaleLowerCase();

  const savedExplorations = explorations.filter((exploration) =>
    exploration.name.toLocaleLowerCase().includes(trimmedSearchTerm),
  );

  const modelExplorations = models
    .filter((model) => model.name.toLocaleLowerCase().includes(trimmedSearchTerm))
    .map((model) => getModelExploration(model));

  const classifiedModelExplorations = modelExplorations.filter((exploration) => {
    const kind = getModelKind(exploration);
    return kind !== null && kinds?.includes(kind);
  });

  const unclassifiedModelExplorations = modelExplorations.filter(
    (exploration) => classifiedModelExplorations.indexOf(exploration) === -1,
  );

  const modelSections = uniq(unclassifiedModelExplorations.map(({ labels }) => labels.section))
    .filter(notNil)
    .sort();

  const modelsWithoutSections = unclassifiedModelExplorations
    .filter(({ labels }) => isNil(labels.section))
    .filter((exploration) => exploration.name.toLocaleLowerCase().includes(trimmedSearchTerm));

  useEffect(() => {
    if (trimmedSearchTerm.length === 0) {
      return;
    }
    const timeout = setTimeout(() => {
      trackEvent('Exploration Search', {
        searchTerm: trimmedSearchTerm,
        modelCount: modelExplorations.length,
      });
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trimmedSearchTerm]);

  useEffect(() => {
    if (showOtherModels && !otherModelsShown.current) {
      otherModelsContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    otherModelsShown.current = showOtherModels;
  }, [showOtherModels]);

  const handleModelExplorationClick = (exploration: Exploration) => {
    if (!('pipeline' in exploration.view.cells[0])) {
      throw new Error('Cell does not have a pipeline');
    }
    const dereferencedPipeline = dereferencePipeline(
      exploration.view.cells[0].pipeline,
      exploration,
    );
    const model = getModelOrThrow(models, dereferencedPipeline.baseModelId);
    return onSelectModel(model);
  };

  const { list: explorationList, setFocusIndex } = useNavigableList<ListItemData>({
    items: [
      ...(kinds ?? []).map((kind) => {
        const explorations = classifiedModelExplorations.filter(
          (exploration) => getModelKind(exploration) === kind,
        );
        if (explorations.length === 0) {
          return null;
        }
        return {
          data: { title: modelKindSectionTitles[kind] ?? kind },
          children: explorations.map<NavigableListItemInput<ListItemData>>((exploration) => ({
            isFocusable: true,
            data: { exploration },
            onClick: (event) => {
              event.preventDefault();
              handleModelExplorationClick(exploration);
            },
          })),
        };
      }),
      exploration === undefined || !explorationHasReplicableCells(exploration)
        ? null
        : {
            data: { title: 'On This Page' },
            children: exploration?.view.cells.filter(isReplicableCell).map((cell) => ({
              isFocusable: true,
              data: { cell },
              onClick: (event) => {
                event.preventDefault();
                const { cell: extractedCell, dependencies } = extractCellFromExploration(
                  cell.id,
                  exploration,
                );
                onSelectCell(extractedCell, dependencies);
              },
            })),
          },
      savedExplorations.length === 0
        ? null
        : {
            data: { title: 'Saved Explorations' },
            children: savedExplorations
              .filter((exploration) => exploration.view.cells.filter(isReplicableCell).length > 0)
              .map((exploration) => ({
                isCollapsed: true,
                isFocusable: true,
                data: { exploration },
                collapseOnClick: true,
                children: exploration.view.cells.filter(isReplicableCell).map((cell) => ({
                  isFocusable: true,
                  data: { cell },
                  onClick: (event) => {
                    event.preventDefault();
                    const { cell: extractedCell, dependencies } = extractCellFromExploration(
                      cell.id,
                      exploration,
                    );
                    onSelectCell(extractedCell, dependencies);
                  },
                })),
              })),
          },
      ...modelSections.map<NavigableListItemInput<ListItemData>>((section) => ({
        data: { title: section },
        children: unclassifiedModelExplorations
          .filter(({ labels }) => labels.section === section)
          .map((exploration) => ({
            isFocusable: true,
            data: { exploration },
            onClick: (event) => {
              event.preventDefault();
              handleModelExplorationClick(exploration);
            },
          })),
      })),
      modelsWithoutSections.length === 0
        ? null
        : {
            data: { title: modelSections.length > 0 ? 'Other Data Models' : 'Raw Data Models' },
            children: modelsWithoutSections.map((exploration) => ({
              isFocusable: true,
              data: { exploration },
              onClick: (event) => {
                event.preventDefault();
                handleModelExplorationClick(exploration);
              },
            })),
          },
    ],
    listContainerRef: scrollContainerRef,
    onFocusMovedOutside: () => {
      inputRef.current?.focus();
    },
  });

  const onThisPageIndex = kinds?.length ?? 0;
  const classifiedLists = explorationList.slice(0, onThisPageIndex);
  const onThisPageList = nth(explorationList, onThisPageIndex);
  const unclassifiedLists = explorationList.slice(onThisPageIndex + 1);

  const hasClassifiedModels = classifiedLists.some(
    (list) => list !== null && list.getChildren().length > 0,
  );
  const hasUnclassifiedModels = unclassifiedLists.some(
    (list) => list !== null && list.getChildren().length > 0,
  );

  const renderList = (item: NavigableListItemOutput<ListItemData> | null, index: number) => {
    if (item === null) {
      return null;
    }
    return (
      <CollapsibleSection
        key={index}
        title={item.getData<ExplorationListData>().title ?? 'Models'}
        isCollapsed={item.getIsCollapsed()}
        onToggleCollapsed={item.toggleCollapsed}>
        <ExplorationList listItems={item.getChildren()} />
      </CollapsibleSection>
    );
  };

  return (
    <Panel className={classNames(styles.panel, className)}>
      <section className={styles.explorationSearch}>
        <div className={styles.searchHeader}>
          <h1>{props.title ?? 'Select Model'}</h1>
          <div className={styles.searchInput}>
            <Icon name="Search" size={24} className={styles.searchIcon} />
            <input
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.currentTarget.value)}
              placeholder="Search your data models..."
              autoFocus
              onKeyDown={(e) => {
                if (e.key === 'ArrowDown') {
                  e.preventDefault();
                  setFocusIndex(0);
                } else if (e.key === 'ArrowUp') {
                  e.preventDefault();
                  setFocusIndex(-1);
                }
              }}
              ref={inputRef}
            />
          </div>
        </div>
        <div
          className={classNames(styles.allExplorations, {
            [styles.scrolled]: scrollPosition > 0,
          })}
          ref={scrollContainerRef}>
          {!hasClassifiedModels && (
            <Tip disableKey={'tip.modelKinds.disabledAt'}>
              Did you know you can give additional context to your data models by labeling them as
              events, users, accounts & entities? By doing that, Supersimple can make better
              suggestions for you in the future.
            </Tip>
          )}
          {classifiedLists.map(renderList)}

          {exploration !== undefined && !isNil(onThisPageList) && (
            <CollapsibleSection
              title="On This Page"
              isCollapsed={onThisPageList.getIsCollapsed()}
              onToggleCollapsed={onThisPageList.toggleCollapsed}>
              <CellList exploration={exploration} listItems={onThisPageList.getChildren()} />
            </CollapsibleSection>
          )}

          {hasUnclassifiedModels && hasClassifiedModels && (
            <>
              <hr />
              <InlineButton
                className={styles.otherModelsButton}
                onClick={() => {
                  setShowOtherModels(!showOtherModels);
                }}>
                {showOtherModels ? 'Hide other data models' : 'Other data models'}
              </InlineButton>
            </>
          )}

          {hasUnclassifiedModels && (!hasClassifiedModels || showOtherModels) && (
            <div ref={otherModelsContainerRef}>{unclassifiedLists.map(renderList)}</div>
          )}

          {explorationList.every((list) => list === null || list.getChildren().length === 0) && (
            <NoMatchBanner />
          )}
        </div>
      </section>
    </Panel>
  );
};

interface PipelineSearchModalProps extends PipelineSearchProps {
  onClose: () => void;
}

export const PipelineSearchModal = ({ onClose, ...rest }: PipelineSearchModalProps) => (
  <Modal onClose={onClose} closeOnClickAway>
    <PipelineSearch {...rest} />
  </Modal>
);
