import { first } from 'lodash';

import { generatePipelineId } from '@/explore/pipeline/utils';

import { CombineItemState, CombineState } from './types';
import { replacePipeline } from '../../edit-pipeline/utils';
import {
  Cell,
  Exploration,
  Field,
  JoinPipelineOperation,
  Pipeline,
  RecordsCell,
} from '../../types';
import { addExplorationCells, getCell, getCellIndex, getRecordsCell } from '../utils';
import { generateCellId } from '../../utils';

export const getDefaultJoinKey = (fields: Field[], otherItem?: CombineItemState) =>
  fields.find((field) => field.key === otherItem?.joinKey)?.key ??
  fields.find((field) => field.pk)?.key ??
  first(fields)?.key ??
  '';

export const addCombineToExploration = (
  initialExploration: Exploration,
  combine: CombineState,
  cellId: string,
): { cell: Cell; exploration: Exploration } | undefined => {
  const result = combinePipeline(initialExploration, combine, cellId);

  if (result === undefined) {
    return;
  }

  const { pipeline, exploration } = result;
  const addedCellCount = exploration.view.cells.length - initialExploration.view.cells.length;

  const cell: RecordsCell = {
    id: generateCellId(),
    title: 'Combined data',
    kind: 'records',
    pipeline,
  };

  return {
    cell,
    exploration: addExplorationCells(
      exploration,
      [cell],
      getCellIndex(cellId, result.exploration) + addedCellCount + 1,
    ),
  };
};

export const updateCombineInExploration = (
  exploration: Exploration,
  combine: CombineState,
  cellId: string,
): Exploration | undefined => {
  const result = combinePipeline(exploration, combine, cellId);

  if (result === undefined) {
    return;
  }

  return replacePipeline(
    result.exploration,
    getCellIndex(cellId, result.exploration),
    result.pipeline,
  );
};

// TODO: Reuse part of previous pipeline when updating existing onw
const combinePipeline = (
  initialExploration: Exploration,
  combine: CombineState,
  cellId: string,
) => {
  const { left, right } = combine;
  let exploration: Exploration = initialExploration;
  let pipeline: Pipeline | undefined = undefined;
  let operation: JoinPipelineOperation | undefined = undefined;
  const cellIndex = getCellIndex(cellId, exploration);

  if (right.kind === 'cell') {
    const isFromCurrentExploration = getCell(right.cellId, exploration) !== undefined;
    if (!isFromCurrentExploration) {
      exploration = addExplorationCells(exploration, right.cells, cellIndex + 1);
    }
    const cell = getRecordsCell(right.cellId, exploration);
    if ('pipelineId' in cell.pipeline && cell.pipeline.pipelineId !== undefined) {
      operation = {
        operation: 'joinPipeline',
        parameters: {
          pipeline: {
            parentId: cell.pipeline.pipelineId,
            operations: [],
          },
          joinStrategy: {
            joinKeyOnBase: left.key,
            joinKeyOnRelated: right.key,
          },
        },
      };
    }
  }

  if (right.kind === 'model') {
    operation = {
      operation: 'joinPipeline',
      parameters: {
        pipeline: {
          baseModelId: right.modelId,
          operations: [],
        },
        joinStrategy: {
          joinKeyOnBase: left.key,
          joinKeyOnRelated: right.key,
        },
      },
    };
  }

  if (operation !== undefined) {
    if (left.kind === 'cell') {
      const isFromCurrentExploration = getCell(left.cellId, exploration) !== undefined;
      if (!isFromCurrentExploration) {
        exploration = addExplorationCells(exploration, left.cells, cellIndex + 1);
      }
      const cell = getRecordsCell(left.cellId, exploration);

      if ('pipelineId' in cell.pipeline && cell.pipeline.pipelineId !== undefined) {
        pipeline = {
          pipelineId: generatePipelineId(),
          parentId: cell.pipeline.pipelineId,
          operations: [operation],
        };
      }
    }

    if (left.kind === 'model') {
      pipeline = {
        pipelineId: generatePipelineId(),
        baseModelId: left.modelId,
        operations: [operation],
      };
    }
  }

  if (pipeline !== undefined) {
    return { pipeline, exploration };
  }
};
