import { bisect } from 'd3-array';

import {
  endOfISOWeek,
  endOfMonth,
  endOfYear,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';
import { format, toZonedTime } from 'date-fns-tz';

import { TimePrecision, TimeRange, timePrecision } from './types';
import { TimeAggregationPeriod } from '../explore/types';

const getLocalDayNames = (
  locale = 'en',
  format: 'long' | 'short' | 'narrow' | undefined = 'long',
) => {
  const formatter = new Intl.DateTimeFormat(locale, { weekday: format, timeZone: 'UTC' });
  const days = [2, 3, 4, 5, 6, 7, 1].map((day) => {
    const dd = day < 10 ? `0${day}` : day;
    return new Date(`2017-01-${dd}T00:00:00+00:00`);
  });
  return days.map((date) => formatter.format(date));
};

const localDayNames = getLocalDayNames();

export const getWeekdayName = (dow: number) => localDayNames[dow - 1];

/**
 * Format a date for display in a given timezone.
 * @param date The Date, ISO string or timestamp to format.
 */
export const formatDate = (
  date: string | number | Date,
  fmt: string,
  timezone?: string,
): string => {
  return format(timezone !== undefined ? toZonedTime(date, timezone) : new Date(date), fmt, {
    timeZone: timezone,
  });
};

export const formatLocalDateTime = (date: string | number | Date): string =>
  formatDate(date, 'MMMM do yyyy HH:mm:ss z');

const IntervalFormats: { [key in TimeAggregationPeriod]: string } = {
  year: 'yyyy',
  quarter: 'QQQ yyyy',
  month: 'MMMM yyyy',
  week: "'W'I yyyy",
  day_of_week: 'EEEE',
  day: 'MMM do yyyy',
  hour: 'MMM do yyyy HH:mm',
};

export const getIntervalFormat = (timeInterval?: TimeAggregationPeriod) => {
  return timeInterval !== undefined ? IntervalFormats[timeInterval] : 'MMMM yyyy HH:mm:ss OOOO';
};

export function getFormattedDateForPeriod(
  date: Date,
  aggPeriod: TimeAggregationPeriod,
  timezone?: string,
) {
  if (aggPeriod === 'week') {
    const startOf = startOfISOWeek(date);
    const endOf = endOfISOWeek(date);
    if (
      startOf.getFullYear() !== new Date().getFullYear() ||
      endOf.getFullYear() !== new Date().getFullYear()
    ) {
      // prettier-ignore
      return `${formatDate(startOf, 'MMM do yyyy', timezone)}–${formatDate(endOf, 'MMM do yyyy', timezone)}`;
    }
    return `${formatDate(startOf, 'MMM do', timezone)}–${formatDate(endOf, 'MMM do', timezone)}`;
  }
  return formatDate(date, getIntervalFormat(aggPeriod), timezone);
}

export const dateSort = (a: Date, b: Date) => a.getTime() - b.getTime();

export const findClosestDate = (values: Date[], target: Date) => values[bisect(values, target)];

function assertNeverPreset(timeRangePreset: never): never {
  throw new Error(`Unhandled timeRangePreset: ${timeRangePreset}`);
}

export const DateRangeOptions: { label: string; value: TimeRange }[] = [
  { label: 'Custom', value: 'custom' },
  { label: 'Last 7 days', value: '7d' },
  { label: 'Last 30 days', value: '30d' },
  { label: 'Last 90 days', value: '90d' },
  { label: 'Last 1 year', value: '1y' },
  { label: 'Year to date', value: 'ytd' },
  { label: 'Month to date', value: 'mtd' },
  { label: `Last year (${subYears(new Date(), 1).getFullYear()})`, value: 'prevyear' },
  { label: `Last month (${format(subMonths(new Date(), 1), 'MMMM')})`, value: 'prevmonth' },
];

export function getFromDate(timeRangePreset: TimeRange, aggPeriod: TimePrecision) {
  switch (timeRangePreset) {
    case '1y': {
      switch (aggPeriod) {
        case timePrecision.enum.Daily:
          return startOfDay(subYears(new Date(), 1));
        case timePrecision.enum.Weekly:
          return startOfWeek(subYears(new Date(), 1));
        case timePrecision.enum.Monthly:
        default:
          return startOfMonth(subYears(new Date(), 1));
      }
    }
    case '7d':
      return startOfDay(subDays(new Date(), 7));
    case '30d':
      return startOfDay(subDays(new Date(), 30));
    case '90d':
    case 'custom':
      return startOfDay(subDays(new Date(), 90));
    case 'ytd':
      return startOfYear(new Date());
    case 'mtd':
      return startOfMonth(new Date());
    case 'prevmonth':
      return startOfMonth(subMonths(new Date(), 1));
    case 'prevyear':
      return startOfYear(subYears(new Date(), 1));
    default:
      return assertNeverPreset(timeRangePreset);
  }
}

export function getToDate(timeRangePreset: TimeRange) {
  switch (timeRangePreset) {
    case '1y':
    case '7d':
    case '30d':
    case '90d':
    case 'ytd':
    case 'mtd':
    case 'custom':
      return new Date();
    case 'prevmonth':
      return endOfMonth(subMonths(new Date(), 1));
    case 'prevyear':
      return endOfYear(subYears(new Date(), 1));
    default:
      return assertNeverPreset(timeRangePreset);
  }
}

export const getDatesFromTimeRange = (timeRangePreset: TimeRange, aggPeriod: TimePrecision) => {
  return {
    startDate: getFromDate(timeRangePreset, aggPeriod),
    endDate: getToDate(timeRangePreset),
  };
};

const isoDateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
const isoDateRegex = /^\d{4}-\d{2}-\d{2}/;

const isISODateTimeString = (value: string) => isoDateTimeRegex.test(value);

const isISODateString = (value: string) => isoDateRegex.test(value);

export const containsISODateString = (value: string) =>
  isISODateString(value) || isISODateTimeString(value);
