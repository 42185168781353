import { useLayoutEffect, useState } from 'react';

const isNullRect = (rect: DOMRect) =>
  rect.width === 0 && rect.height === 0 && rect.x === 0 && rect.y === 0;

/**
 * A hook that returns the client bounding rect of the given element and keeps it updated
 * when any parent container is scrolled.
 * An optional dependency array can be passed to force a rect update when any of the
 * dependencies change.
 */
export const useClientRect = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  deps?: React.DependencyList,
) => {
  const [rect, setRect] = useState<DOMRect | undefined>(undefined);

  useLayoutEffect(() => {
    const updateRect = () => {
      const r = ref.current?.getBoundingClientRect();
      if (r !== undefined && !isNullRect(r)) {
        setRect(r);
      }
    };
    updateRect();
    document.addEventListener('scroll', updateRect, true);
    return () => {
      document?.removeEventListener('scroll', updateRect, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps ?? []);

  return rect ?? null;
};
