import { RefObject, useEffect, useRef } from 'react';

export const useFocus = (isFocused: boolean, targetRef: RefObject<HTMLElement>) => {
  // Avoid refocusing on random rerender if flag state is out of sync with browser
  // for some reason
  const wasFocused = useRef<boolean>(false);

  useEffect(() => {
    if (isFocused !== true) {
      wasFocused.current = false;
      return;
    }
    if (wasFocused.current) {
      return;
    }
    targetRef.current?.focus();
    wasFocused.current = true;
  }, [isFocused, targetRef]);
};
