import { NavLink } from 'react-router-dom';

import { Breakpoint, useScreenSize } from '@/lib/hooks/use-screen-size';

import { DefaultMenu, Menu, MobileMenu } from './menu';

import styles from './header.module.scss';

const Logo = () => (
  <img src="/logo.svg" className={styles.logo} title="Supersimple" alt="Supersimple logo" />
);

export const MainHeader = () => {
  const screenSize = useScreenSize();

  return (
    <div className={styles.header}>
      <div className={styles.content}>
        <NavLink to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
          <Logo />
        </NavLink>
        <Menu Component={screenSize.breakpoint <= Breakpoint.xsm ? MobileMenu : DefaultMenu} />
      </div>
    </div>
  );
};
