import React from 'react';
import { Link } from 'react-router-dom';
import { first } from 'lodash';

import { useBuildAccountUrl } from '@/lib/accounts/context';

import { exhaustiveCheck } from '@/lib/exhaustive';

import { useMetadataContext } from '../metadata-context';
import { Exploration } from '../types';
import { Button } from '../../components/button';
import { Icon } from '../../components/icon';
import { buildExplorationUrl } from '../utils';
import { buildExplorationByType, BuildType } from '../utils/builder';

interface AddButtonProps {
  type: BuildType;
  onClick?: (exploration: Exploration) => void;
}

export const AddButton = ({ type, onClick }: AddButtonProps) => {
  const buildAccountUrl = useBuildAccountUrl();
  const { models } = useMetadataContext();
  const baseModelId = first(models)?.modelId ?? '';

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (onClick === undefined) {
      return;
    }

    event.preventDefault();

    onClick(buildExplorationByType(type, baseModelId));
  };

  const button =
    type === 'funnel' ? (
      <Button icon={<Icon name="Funnel" size={22} />} type="outlined" size="large">
        Funnel chart
      </Button>
    ) : type === 'cohort' ? (
      <Button icon={<Icon name="Cohort" size={22} />} type="outlined" size="large">
        Retention chart
      </Button>
    ) : type === 'variable' ? (
      <Button icon={<Icon name="Variable" size={22} />} type="outlined" size="large">
        Variable
      </Button>
    ) : type === 'text' ? (
      <Button icon={<Icon name="AlignLeft" size={22} />} type="outlined" size="large">
        Text
      </Button>
    ) : type === 'sql' ? (
      <Button icon={<Icon name="Database" size={22} />} type="outlined" size="large">
        SQL
      </Button>
    ) : type === 'python' ? (
      <Button icon={<Icon name="Python" size={22} />} type="outlined" size="large">
        Python
      </Button>
    ) : (
      exhaustiveCheck(type)
    );

  return (
    <Link
      onClick={handleClick}
      to={buildAccountUrl(buildExplorationUrl(buildExplorationByType(type, baseModelId), null))}
      tabIndex={-1}>
      {button}
    </Link>
  );
};
