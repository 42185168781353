import { Icon, IconProps } from '../../components/icon';
import { Panel } from '../../components/panel';
import { Button } from '../../components/button';
import { CompositeFilterForm } from './edit-filter';
import { NewColumnForm } from './edit-new-column';
import { GroupAggregateForm } from './edit-group-aggregate';
import { SwitchToRelationForm } from './edit-switch-relation';
import { FormId } from '../exploration/exploration-context';
import { metricV2sByModelId } from '../metrics/utils';
import {
  Exploration,
  FilterOperation,
  GroupAggregateOperation,
  MetricV2,
  Model,
  PipelineOperation,
  PipelineState,
  VariableDefinition,
} from '../types';

import styles from './add-operation.module.scss';

type MenuItemProps = {
  title: string;
  iconName: IconProps['name'];
  onClick: () => void;
};

const MenuItem = ({ title, iconName, onClick }: MenuItemProps) => {
  return (
    <button className={styles.menuItem} onClick={onClick}>
      <Icon name={iconName} size={24} />
      <span className={styles.title}>{title}</span>
    </button>
  );
};

type AddOperationMenuProps = {
  onSelect: (formId: FormId) => void;
  onClose: () => void;
};

const AddOperationMenu = ({ onSelect, onClose }: AddOperationMenuProps) => {
  return (
    <Panel>
      <div className={styles.menuItems}>
        <MenuItem title="Filter" iconName="Filter" onClick={() => onSelect('addFilter')} />
        <MenuItem
          title="Summarize"
          iconName="Summarise"
          onClick={() => onSelect('addGroupAggregate')}
        />
        <MenuItem
          title="Jump to related data"
          iconName="RelatedData"
          onClick={() => onSelect('addSwitchToRelation')}
        />
        <MenuItem
          title="New column"
          iconName="AddColumn"
          onClick={() => onSelect('addNewColumn')}
        />
        <Button className={styles.cancelBtn} type="outlined" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </Panel>
  );
};

interface AddOperationProps {
  formData?:
    | {
        formId: 'addFilter';
        parameters?: FilterOperation['parameters'];
      }
    | {
        formId: 'addGroupAggregate';
        parameters?: GroupAggregateOperation['parameters'];
      }
    | {
        formId?: 'addSwitchToRelation' | 'addNewColumn';
      };
  state: PipelineState;
  exploration: Exploration;
  models: Model[];
  metrics: MetricV2[];
  onAdd: (operation: PipelineOperation) => void;
  onClose: () => void;
  onOpenMenu: () => void;
  onOpenForm: (formId: FormId) => void;
  variables: VariableDefinition[];
}

export const AddOperation = (props: AddOperationProps) => {
  const { formData } = props;
  if (formData?.formId === undefined) {
    return <AddOperationMenu onSelect={props.onOpenForm} onClose={props.onClose} />;
  }

  return (
    <>
      {formData?.formId === 'addFilter' && (
        <Panel title="Add Filter">
          <CompositeFilterForm
            fields={props.state.fields}
            operation={
              formData.parameters && { operation: 'filter', parameters: formData.parameters }
            }
            setOperation={props.onAdd}
            onClose={props.onOpenMenu}
            variables={props.variables}
          />
        </Panel>
      )}
      {formData?.formId === 'addNewColumn' && (
        <Panel title="Add Columns">
          <NewColumnForm
            model={props.state.model}
            fields={props.state.fields}
            relations={props.state.relations}
            models={props.models}
            metrics={props.metrics}
            exploration={props.exploration}
            setOperation={props.onAdd}
            onClose={props.onOpenMenu}
            variables={props.variables}
          />
        </Panel>
      )}
      {formData?.formId === 'addGroupAggregate' && (
        <Panel title="Summarize">
          <GroupAggregateForm
            fields={props.state.fields}
            operation={
              formData.parameters && {
                operation: 'groupAggregate',
                parameters: formData.parameters,
              }
            }
            metrics={metricV2sByModelId(props.metrics, props.state.model.modelId)}
            setOperation={props.onAdd}
            onClose={props.onOpenMenu}
          />
        </Panel>
      )}
      {formData?.formId === 'addSwitchToRelation' && (
        <Panel title="Jump to Related Data">
          <SwitchToRelationForm
            model={props.state.model}
            models={props.models}
            relations={props.state.relations}
            setOperation={props.onAdd}
            onClose={props.onOpenMenu}
          />
        </Panel>
      )}
    </>
  );
};
