import { endOfISOWeek, startOfISOWeek } from 'date-fns';

import { TimePrecision } from '@/lib/types';
import { isNil } from '@/lib/utils';
import { formatNumberToShort } from '@/lib/utils/number';
import { formatDate } from '@/lib/date';

import { MetricKind } from '../../generated/graphql';
import { Metric, MetricValues, Point } from '../../explore/types';

export function formatPoints(
  metric: {
    kind: string;
  },
  values: MetricValues,
  isCumulative: boolean,
): MetricValues {
  if (metric.kind === MetricKind.NewValue && isCumulative) {
    let prevVal = 0;

    return values.reduce(
      (acc, val) => [...acc, { date: val.date, value: (prevVal += val.value) }],
      [] as Point[],
    );
  }

  return values;
}

export function formatMetricValue(metric: Metric, value: number) {
  if (metric.kind === MetricKind.Percentage) {
    if (isNil(metric.displayPostfix)) {
      return `${(value * 100).toFixed(2)}%`;
    }
  }
  return [metric.displayPrefix, formatNumberToShort({ num: value }), metric.displayPostfix].join(
    '',
  );
}

export function getDateFnsFormatForPeriodInMetrics(aggPeriod: TimePrecision) {
  switch (aggPeriod) {
    case 'DAILY':
      return 'MMM do yyyy';
    case 'WEEKLY':
      return `'Week' I yyyy`;
    case 'MONTHLY':
      return 'MMMM yyyy';
    default:
      throw new Error(`Unsupported time precision: ${aggPeriod}`);
  }
}

export function getFormattedDateForPeriodInMetrics(
  date: Date,
  aggPeriod: TimePrecision,
  timezone?: string,
) {
  if (aggPeriod === 'WEEKLY') {
    const startOf = startOfISOWeek(date);
    const endOf = endOfISOWeek(date);
    if (
      startOf.getFullYear() !== new Date().getFullYear() ||
      endOf.getFullYear() !== new Date().getFullYear()
    ) {
      // prettier-ignore
      return `${formatDate(startOf, 'MMM do yyyy', timezone)}–${formatDate(endOf, 'MMM do yyyy', timezone)}`;
    }
    return `${formatDate(startOf, 'MMM do', timezone)}–${formatDate(endOf, 'MMM do', timezone)}`;
  }
  return formatDate(date, getDateFnsFormatForPeriodInMetrics(aggPeriod), timezone);
}
