import { common } from '@gosupersimple/types';

import { MetricValueAggregation, QuerySortDirection } from '../generated/graphql';
import { TimePrecision } from '../lib/types';

export function timePrecisionToGql(timePrecision: TimePrecision) {
  switch (timePrecision) {
    case TimePrecision.Daily:
      return MetricValueAggregation.Daily;
    case TimePrecision.Weekly:
      return MetricValueAggregation.Weekly;
    case TimePrecision.Monthly:
      return MetricValueAggregation.Monthly;
    default:
      throw new Error('Invalid time precision');
  }
}

export function sortingToGql(sorting: common.Sorting[]) {
  return sorting.map(({ key, direction }) => {
    switch (direction) {
      case 'ASC':
        return { key, direction: QuerySortDirection.Asc };
      case 'DESC':
        return { key, direction: QuerySortDirection.Desc };
      default:
        throw new Error(`Unknown direction: ${direction}`);
    }
  });
}
