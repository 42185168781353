import { useCopyToClipboard } from 'usehooks-ts';

import { getEmptyFilterParameters, parseValueForFilter } from '@/explore/edit-pipeline/edit-filter';
import { createGroupingFromField } from '@/explore/utils/grouping';
import { Icon } from '@/components/icon';
import { ExplorationContextValue } from '@/explore/exploration/exploration-context';
import { ExplorationCellContextValue } from '@/explore/exploration/exploration-cell-context';
import { AddToastFn } from '@/components/toast';
import { formatPropertyValue } from '@/explore/utils/format';

import { DataTableProperty } from '../types';

export const buildOpenDetailViewMenuItem = (
  property: DataTableProperty,
  link: string,
  closeEditor: ExplorationContextValue['closeEditor'],
  scrollToCell: ExplorationContextValue['scrollToCell'],
) => ({
  label: property.relation?.name !== undefined ? `Open ${property.relation?.name}` : 'Open',
  icon: <Icon name="FileText" size={16} />,
  onClick: () => {
    closeEditor();
    scrollToCell(0);
  },
  href: link,
  type: 'link' as const,
});

export const buildDrilldownMenuItem = (
  property: DataTableProperty,
  row: Record<string, unknown>,
  timezone: string,
  drilldownByProperty: ExplorationCellContextValue['drilldownByProperty'],
) => ({
  label: 'Show drilldown',
  onClick: () => drilldownByProperty(row, property, timezone),
  icon: <Icon name="ExternalLink" size={16} />,
});

export const buildCopyValueMenuItem = (
  property: DataTableProperty,
  value: unknown,
  timezone: string,
  addToast: AddToastFn,
  copyToClipboard: ReturnType<typeof useCopyToClipboard>[1],
) => ({
  label: 'Copy Value',
  icon: <Icon name="Copy" size={16} />,
  onClick: () => {
    copyToClipboard(
      formatPropertyValue(value, property?.type, {
        precision: property.precision,
        timezone: timezone,
      }),
    );
    addToast({
      title: 'Value copied!',
      content: () => 'Value copied to clipboard',
      kind: 'success',
    });
  },
});

export const buildLinkMenuItem = (value: unknown) => ({
  label: 'Open URL',
  icon: <Icon name="ExternalLink" size={16} />,
  href: String(value),
  external: true,
  type: 'link' as const,
});

export const buildFilterMenuItem = (
  cellIndex: number,
  openEditor: ExplorationContextValue['openEditor'],
  openAddForm: ExplorationContextValue['openAddForm'],
  property: DataTableProperty,
  value?: unknown,
  timezone?: string,
) => ({
  label: 'Filter by',
  icon: <Icon name="Filter" size={16} />,
  onClick: () => {
    openEditor({ cellIndex });
    openAddForm({
      index: -1,
      formId: 'addFilter',
      parameters:
        value === null
          ? { key: property.key, operator: 'isnull' }
          : getEmptyFilterParameters(
              property,
              parseValueForFilter(value, property.type, timezone ?? 'UTC'),
            ),
    });
  },
});

export const buildGroupByMenuItem = (
  cellIndex: number,
  openEditor: ExplorationContextValue['openEditor'],
  openAddForm: ExplorationContextValue['openAddForm'],
  property: DataTableProperty,
) => ({
  label: 'Group by',
  icon: <Icon name="Summarise" size={16} />,
  onClick: () => {
    openEditor({ cellIndex });
    openAddForm({
      index: -1,
      formId: 'addGroupAggregate',
      parameters: {
        groups: [createGroupingFromField(property)],
        aggregations: [],
      },
    });
  },
});
