import { useMemo, useState } from 'react';
import { first, isEqual, omit } from 'lodash';

import { common } from '@gosupersimple/types';

import { Form } from '@/components/form';
import { Button } from '@/components/button';
import { TagSelect } from '@/components/form/tag-select';
import { Toggle } from '@/components/form/toggle';

import { Aggregation, Fields, GroupAggregateOperation, Grouping, MetricV2 } from '../types';
import { nameToKey } from './utils';
import { GroupingsEditor } from './groupings-editor';
import { AggregationsEditor } from './aggregations-editor';
import { metricV2AggregationOptions, metricV2ById } from '../metrics/utils';
import {
  AggregationOptions,
  getAggregationOptionsForFields,
  getDefaultAggregation,
} from '../utils/aggregation';
import { SlicingEditor } from './slicing-editor';
import { getDefaultSlice } from '../utils/slicing';

import { useDirtyContext } from '../dirty-context';

import panelStyles from '@/components/panel/panel.module.scss';
import form from '@/components/form/form.module.scss';

interface GroupAggregateFormProps {
  operation?: GroupAggregateOperation;
  fields: Fields;
  metrics: MetricV2[];
  setOperation(operation: GroupAggregateOperation): void;
  onClose(): void;
}

const DefaultGroups: Grouping[] = [];
const DefaultAggregations: Aggregation[] = [];

export const GroupAggregateForm = (props: GroupAggregateFormProps) => {
  const { fields, operation, metrics } = props;

  const initialParameters = useMemo(
    () => ({
      groups: operation?.parameters.groups ?? DefaultGroups,
      aggregations: operation?.parameters.aggregations ?? DefaultAggregations,
      slice: operation?.parameters.slice ?? getDefaultSlice(fields),
    }),
    [operation?.parameters, fields],
  );
  const [parameters, setParameters] =
    useState<GroupAggregateOperation['parameters']>(initialParameters);

  const initialSlicingEnabled = operation?.parameters.slice !== undefined;
  const [isSlicingEnabled, setIsSlicingEnabled] = useState(initialSlicingEnabled);

  const { setDirty } = useDirtyContext();

  const handleChange = (
    parameters: GroupAggregateOperation['parameters'],
    isSlicingEnabled: boolean,
  ) => {
    setParameters(parameters);
    setIsSlicingEnabled(isSlicingEnabled);
    const isDirty =
      !isEqual(parameters, initialParameters) || isSlicingEnabled !== initialSlicingEnabled;
    setDirty(isDirty);
  };

  const handleGroupsChange = (groups: Grouping[]) => {
    handleChange({ ...parameters, groups }, isSlicingEnabled);
  };

  const handleAggregationsChange = (aggregations: Aggregation[]) => {
    handleChange({ ...parameters, aggregations }, isSlicingEnabled);
  };

  const handleSliceChange = (slice: common.Slice) => {
    handleChange({ ...parameters, slice }, isSlicingEnabled);
  };

  const handleSliceToggle = (isSlicingEnabled: boolean) => {
    handleChange(parameters, isSlicingEnabled);
  };

  const handleSubmit = () => {
    setDirty(false);
    props.setOperation({
      operation: 'groupAggregate',
      parameters: isSlicingEnabled ? parameters : omit(parameters, 'slice'),
    });
  };

  const handleCancel = () => {
    setDirty(false);
    props.onClose();
  };

  const allowedAggregationOptions = getAggregationOptionsForFields(fields)
    .filter(({ value }) => value !== 'last' && value !== 'custom')
    .map(({ label, value }) => ({ label, value: value.toString() }))
    .concat(metricV2AggregationOptions(metrics));

  const getDefaultAggregationFromOptionValue = (value: string) => {
    const aggregationOption = AggregationOptions.find((option) => option.value === value);

    if (aggregationOption !== undefined) {
      return getDefaultAggregation(fields, metrics, undefined, [aggregationOption]);
    }

    const metric = metricV2ById(metrics, value);
    if (metric !== undefined) {
      return {
        type: 'metric' as const,
        property: { key: nameToKey(metric.name), name: metric.name },
        metricId: metric.metricId,
      };
    }

    throw new Error(`Could not find default aggregation for value ${value}`);
  };

  const { aggregations, groups, slice } = parameters;
  const isSubmitDisabled = groups.length === 0 && aggregations.length === 0 && !isSlicingEnabled;

  return (
    <Form onSubmit={handleSubmit} disabled={isSubmitDisabled}>
      <AggregationsEditor
        aggregations={aggregations}
        setAggregations={handleAggregationsChange}
        fields={fields}
        metrics={metrics}
        autoFocus
        empty={(addAggregation) => (
          <>
            <span className={form.helpText}>
              Use summarization to show a one line summary instead of all rows.
            </span>
            <TagSelect
              options={allowedAggregationOptions}
              value={[]}
              onChange={(values) => {
                return addAggregation(getDefaultAggregationFromOptionValue(first(values)!));
              }}
            />
          </>
        )}
        excludeAggregationTypes={['last', 'custom']}
      />

      <hr className={panelStyles.fullWidth} />
      <h2 className={panelStyles.title}>Group</h2>

      <GroupingsEditor
        fields={fields}
        groups={groups}
        setGroups={(groups) => {
          handleGroupsChange(
            groups.map((group) => ({ ...group, fill: group.precision === 'day_of_week' })),
          );
        }}
        autoFocus
      />

      <hr className={panelStyles.fullWidth} />

      <div className={form.formHorizontal}>
        <Toggle checked={isSlicingEnabled} onChange={handleSliceToggle} size="small">
          Slice
        </Toggle>
        {isSlicingEnabled && (
          <SlicingEditor fields={fields} slice={slice!} setSlice={handleSliceChange} />
        )}
      </div>

      <div className={form.formControls}>
        <Button size="small" htmlType="submit" disabled={isSubmitDisabled}>
          {props.operation ? 'Save' : 'Summarize'}
        </Button>
        <Button size="small" type="outlined" onClick={handleCancel}>
          {props.operation ? 'Cancel' : 'Back'}
        </Button>
      </div>
    </Form>
  );
};
