import { MouseEventHandler } from 'react';
import classNames from 'classnames';

import { Icon, IconProps } from '../icon';
import { FormInputSize } from '../form/types';
import { Loader } from '../loader';

import styles from './button.module.scss';

type InlineButtonProps = {
  children: string | React.ReactNode;
  onClick?: MouseEventHandler;
  title?: string;
  style?: React.CSSProperties;
  className?: classNames.Argument;
  disabled?: boolean;
  color?: 'white' | 'green';
  size?: FormInputSize;
};

export const InlineButton = ({
  children,
  onClick,
  className,
  disabled,
  color,
  style,
  title,
  size = 'regular',
}: InlineButtonProps) => (
  <button
    style={style}
    className={classNames(styles.inlineTextBtn, className, {
      [styles.white]: color === 'white',
      [styles.green]: color === 'green',
      [styles.sizeSmall]: size === 'small',
      [styles.sizeMedium]: size === 'regular',
    })}
    type="button"
    title={title}
    onClick={onClick}
    disabled={disabled}>
    {children}
  </button>
);

type ButtonProps = {
  children: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: classNames.Argument;
  disabled?: boolean;
  loading?: boolean;
  htmlType?: 'submit' | 'button';
  icon?: React.ReactNode;
  type?: 'primary' | 'secondary' | 'outlined' | 'gray';
  color?: 'default' | 'warning';
  size?: 'small' | 'compact' | 'regular' | 'large';
};

export const Button = ({
  children,
  icon,
  onClick,
  className,
  disabled = false,
  loading = false,
  type = 'primary',
  htmlType = 'button',
  size = 'regular',
  color = 'default',
}: ButtonProps) => (
  <button
    type={htmlType}
    className={classNames(styles.button, className, {
      [styles.primary]: type === 'primary',
      [styles.secondary]: type === 'secondary',
      [styles.outlined]: type === 'outlined',
      [styles.styleGray]: type === 'gray',
      [styles.sizeSmall]: size === 'small',
      [styles.sizeCompact]: size === 'compact',
      [styles.sizeMedium]: size === 'regular',
      [styles.sizeLarge]: size === 'large',
      [styles.warning]: color === 'warning',
      [styles.loading]: loading,
    })}
    onClick={onClick}
    disabled={disabled || loading}>
    {icon}
    <span>{children}</span>
    {loading && <Loader size="small" className={styles.loader} />}
  </button>
);

type IconButtonProps = {
  icon: IconProps['name'];
  title: string;
  iconSize?: 'small' | 'regular' | 'large';
  type?: 'regular' | 'outlined' | 'gray';
  onClick?: MouseEventHandler;
  disabled?: boolean;
  className?: classNames.Argument;
  style?: React.CSSProperties;
  size?: FormInputSize;
  tabIndex?: number;
};

export const IconButton = ({
  icon,
  size = 'small',
  iconSize,
  type,
  onClick,
  disabled,
  className,
  style,
  title,
  tabIndex,
}: IconButtonProps) => (
  <button
    style={style}
    className={classNames(styles.iconButton, className, {
      [styles.styleOutlined]: type === 'outlined',
      [styles.styleGray]: type === 'gray',
      [styles.sizeSmall]: size === 'small',
      [styles.sizeMedium]: size === 'regular',
      [styles.sizeLarge]: size === 'large',
    })}
    type="button"
    title={title}
    onClick={onClick}
    disabled={disabled}
    tabIndex={tabIndex}>
    <Icon name={icon} size={iconSize === 'small' ? 12 : iconSize === 'large' ? 24 : 16} />
  </button>
);

type CloseButtonProps = {
  iconSize?: FormInputSize;
  className?: classNames.Argument;
  onClick?: MouseEventHandler;
  tabIndex?: number;
};

export const CloseButton = ({ iconSize, className, onClick, tabIndex }: CloseButtonProps) => (
  <IconButton
    icon="X"
    title="Close"
    iconSize={iconSize}
    className={className}
    onClick={onClick}
    tabIndex={tabIndex}
  />
);

type ScrollToButtonProps = {
  label: string;
  direction: 'up' | 'down';
  className?: classNames.Argument;
  onClick: MouseEventHandler;
};

export const ScrollToButton = ({ label, direction, className, onClick }: ScrollToButtonProps) => (
  <button className={classNames(styles.scrollToButton, className)} type="button" onClick={onClick}>
    <Icon name={direction === 'up' ? 'ArrowUp' : 'ArrowDown'} size={16} />
    {label}
  </button>
);

interface DropDownButtonProps {
  children: string;
  icon?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: classNames.Argument;
}

export const DropDownButton = (props: DropDownButtonProps) => (
  <button
    className={classNames(styles.dropDownButton, styles.sizeMedium, props.className)}
    onClick={props.onClick}>
    {props.icon}
    <span>{props.children}</span>
    <div className={styles.chevron}>
      <svg
        focusable="false"
        preserveAspectRatio="xMidYMid meet"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        aria-hidden="true">
        <path d="M8 11L3 6 3.7 5.3 8 9.6 12.3 5.3 13 6z" />
      </svg>
    </div>
  </button>
);
