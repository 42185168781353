import { ScaleTime } from 'd3-scale';

import { timeInterval, timeMonday, timeMonth, timeHour, timeYear, timeDay } from 'd3-time';

import { timeFormat } from 'd3-time-format';

import { TimeAggregationPeriod } from '../../../types';

const MS_IN_DAY = 1000 * 60 * 60 * 24;
const HOURS_IN_DAY = 24;
const DAYS_IN_MONTH = 30;
const MAX_DAYS_IN_MONTH = 31;
const DAYS_IN_WEEK = 7;
const MONTHS_IN_YEAR = 12;
const MIN_TICK_SPACING = 45;

// Allowed steps chosen so that they line up with parent intervals
// eg. an hour tick every 6 hours, will line up with a day tick
const ALLOWED_MONTH_STEPS = [1, 3, 6, 12] as const;
const ALLOWED_DAY_STEPS = [1, 4, 8, 16, 32] as const;
const ALLOWED_HOUR_STEPS = [1, 3, 6, 12, 24] as const;

const formatYear = timeFormat('%Y');
const formatQuarter = timeFormat('Q%q');
const formatMonth = timeFormat('%b');
const formatWeek = timeFormat('W%V');
const formatDate = timeFormat('%d');
const formatDateMonth = timeFormat('%d %b');
const formatHour = timeFormat('%H:%M');

/**
 * Custom interval that acts like a day interval but anchors to the first day of each month.
 * This means 1st day of each month will be a tick, and the ticks are not perfectly evened out.
 * Depending on the tick size, the last tick of each month might be slightly narrower.
 * ALLOWED_DAY_STEPS helps to reduce weirdness.
 */
const getCustomDayInterval = (daysInStep: number) =>
  timeInterval(
    (date: Date) => {
      date.setDate(Math.floor(date.getDate() / daysInStep) * daysInStep + 1);
      date.setHours(0, 0, 0, 0);
    },
    (date: Date, steps: number) => {
      date.setDate(date.getDate() + steps * daysInStep);
    },
    (start: Date, end: Date) => {
      return (end.getTime() - start.getTime()) / MS_IN_DAY / daysInStep;
    },
  );

const getCustomMonthStep = (monthsPerMinWidth: number) => {
  return ALLOWED_MONTH_STEPS.find((step) => monthsPerMinWidth <= step) ?? MONTHS_IN_YEAR;
};

const getCustomDayStep = (daysPerMinWidth: number) => {
  return ALLOWED_DAY_STEPS.find((step) => daysPerMinWidth <= step) ?? MAX_DAYS_IN_MONTH;
};

const getCustomHourStep = (hoursPerMinWidth: number) => {
  return ALLOWED_HOUR_STEPS.find((step) => hoursPerMinWidth <= step) ?? HOURS_IN_DAY;
};

const ensureDateParam = (fn: (d: Date) => string) => {
  return (d: Date | { valueOf: () => number }) => {
    return fn(d instanceof Date ? d : new Date(d.valueOf()));
  };
};

export const getTickValues = (
  aggPeriod: TimeAggregationPeriod,
  scale: ScaleTime<number, number>,
) => {
  const rangeSize = Math.max(...scale.range()) - Math.min(...scale.range());
  const domain = scale.domain().map((d) => d.getTime());
  const domainSizeInDays = (Math.max(...domain) - Math.min(...domain)) / MS_IN_DAY;
  const dayWidthInPx = rangeSize / domainSizeInDays;
  const daysPerMinWidth = MIN_TICK_SPACING / dayWidthInPx;
  const monthsPerMinWidth = Math.ceil(daysPerMinWidth / DAYS_IN_MONTH);
  const weeksPerMinWidth = Math.ceil(daysPerMinWidth / DAYS_IN_WEEK);
  const hoursPerMinWidth = daysPerMinWidth * HOURS_IN_DAY;
  switch (aggPeriod) {
    case 'month':
      return scale.ticks(timeMonth.every(getCustomMonthStep(monthsPerMinWidth)) ?? timeMonth);
    case 'week':
      return scale.ticks(timeMonday.every(weeksPerMinWidth) ?? timeMonday);
    case 'day':
      return scale.ticks(getCustomDayInterval(getCustomDayStep(daysPerMinWidth)));
    case 'hour':
      return scale.ticks(timeHour.every(getCustomHourStep(hoursPerMinWidth)) ?? timeHour);
    default:
      return scale.ticks(); // Automatic ticks
  }
};

export const getHighlightedTickValues = (
  aggPeriod: TimeAggregationPeriod,
  scale: ScaleTime<number, number>,
) => {
  switch (aggPeriod) {
    case 'month':
      return scale.ticks(timeYear);
    case 'day':
      return scale.ticks(timeMonth);
    case 'hour':
      return scale.ticks(timeDay);
    default:
      return [];
  }
};

export const getTickFormatFn = (aggPeriod: TimeAggregationPeriod) => {
  switch (aggPeriod) {
    case 'year':
    case 'month':
      return ensureDateParam((d: Date) => {
        return d.getDate() !== 1 ? '' : d.getMonth() === 0 ? formatYear(d) : formatMonth(d);
      });
    case 'quarter':
      return ensureDateParam((d: Date) => {
        return d.getDate() !== 1 ? '' : d.getMonth() === 0 ? formatYear(d) : formatQuarter(d);
      });
    case 'week':
      return ensureDateParam((d: Date) => (d.getDay() !== 1 ? '' : formatWeek(d)));
    case 'day':
      return ensureDateParam((d: Date) =>
        d.getMonth() === 0 && d.getDate() === 1
          ? formatYear(d)
          : d.getDate() === 1
            ? formatMonth(d)
            : formatDate(d),
      );
    case 'hour':
      return ensureDateParam((d: Date) =>
        d.getHours() === 0 && d.getMinutes() === 0 ? formatDateMonth(d) : formatHour(d),
      );
  }
};
