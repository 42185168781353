import { ScaleOrdinal } from 'd3-scale';

import styles from './legend.module.scss';

interface LegendProps {
  domain: string[];
  color: ScaleOrdinal<string, string>;
  left: number;
}

/**
 * @deprecated Use new ColoredLegend component from this same module
 */
export const Legend = ({ domain, color, left }: LegendProps) => (
  <div className={styles.legend} style={{ paddingLeft: `${left}px` }}>
    {domain.map((item, i) => (
      <div className={styles.legendItem} key={i} title={item}>
        <div className={styles.legendColor} style={{ backgroundColor: color(item) }} />
        <div className={styles.legendLabel}>{item}</div>
      </div>
    ))}
  </div>
);

type ColoredLegendProps = { items: { label: string; color: string }[] };

export const ColoredLegend = ({ items }: ColoredLegendProps) => (
  <div className={styles.legend}>
    {items.map(({ label, color }, idx) => (
      <div className={styles.legendItem} key={idx} title={label}>
        <div className={styles.legendColor} style={{ backgroundColor: color }} />
        <div className={styles.legendLabel}>{label}</div>
      </div>
    ))}
  </div>
);
