import classNames from 'classnames';

import { Icon } from '@/components/icon';

import { Exploration, JoinPipelineOperation, Model, Pipeline } from '../../types';
import { getPipelineTitle } from '../../utils/format';

import * as style from './pipeline-preview.module.scss';

interface CombineHeaderProps {
  pipeline: Pipeline;
  operation: JoinPipelineOperation;
  models: Model[];
  exploration: Exploration;
}

export const CombineHeader = (props: CombineHeaderProps) => {
  const { pipeline, operation, models, exploration } = props;

  const leftTitle = getPipelineTitle(models, exploration, pipeline);
  const rightTitle = getPipelineTitle(models, exploration, operation.parameters.pipeline);

  return (
    <div className={classNames(style.operation, style.combinedHeader)}>
      <div className={style.operationIcon}>
        <Icon name="Model" />
      </div>
      <div className={style.operationTitle}>{leftTitle}</div>
      <div className={style.operationIcon}>
        <Icon name="Plus" size={16} />
      </div>
      <div className={style.operationIcon}>
        <Icon name="Model" />
      </div>
      <div className={style.operationTitle}>{rightTitle}</div>
    </div>
  );
};
