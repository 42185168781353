import { initializeApp, getApps } from 'firebase/app';
import { getAuth } from 'firebase/auth';

if (getApps().length === 0) {
  const env = import.meta.env;

  initializeApp({
    apiKey: env.VITE_GOOGLE_API_KEY ?? 'AIzaSyDHol-8y_Jcw0ry4IrATzqLSwPBZl4ekFo',
    authDomain: env.VITE_GOOGLE_AUTH_DOMAIN ?? 'supersimple-80aef.firebaseapp.com',
    databaseURL: env.VITE_GOOGLE_DATABASE_URL ?? 'https://supersimple-80aef.firebaseio.com',
    projectId: env.VITE_GOOGLE_PROJECT ?? 'supersimple-80aef',
    storageBucket: env.VITE_GOOGLE_STORAGE_BUCKET ?? 'supersimple-80aef.appspot.com',
    messagingSenderId: env.VITE_GOOGLE_MESSAGING_SENDER_ID ?? '458442894201',
    appId: env.VITE_GOOGLE_APP_ID ?? '1:458442894201:web:65abfdabbe14ef20079da8',
  });
}

export const auth = getAuth();
