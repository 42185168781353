import { first } from 'lodash';
import { common } from '@gosupersimple/types';

import { CohortOperation, Exploration, Field, Fields, Model, Pipeline, Relation } from '../types';
import { EditCohortOptions, EditCohortState, timeKeys } from './types';
import { getCellByPipelineIdOrThrow } from '../exploration/utils';
import { dereferencePipeline, FieldGroup } from '../pipeline/utils';
import { getFinalState, PipelineStateContext } from '../pipeline/state';
import { getJoinKeyToSemanticModel, getRelationToSemanticModel } from '../model/utils';
import { fieldToOptionGrouped, fieldToOption } from '../edit-pipeline/utils';
import { getValidRelationTypesForOperation } from '../edit-pipeline/utils/relation';

export const getCohortOperation = (pipeline: Pipeline): CohortOperation | undefined => {
  const operation = first(pipeline.operations);

  if (operation?.operation !== 'cohort') {
    return undefined;
  }

  return operation;
};

export const getDefaultIdFieldKey = (fields: Fields, model: Model, models: Model[]) => {
  return (
    fields.find((field) => field.key === getJoinKeyToSemanticModel(model, models, 'User'))?.key ??
    fields.find((field) => field.key === getJoinKeyToSemanticModel(model, models, 'Account'))
      ?.key ??
    fields.find((field) => field.pk)?.key ??
    first(fields.filter((field) => field.key.toLowerCase().endsWith('id')))?.key ??
    first(fields)?.key
  );
};

export const getDefaultEventTimeKey = (fields: Fields, model: Model) =>
  fields.find((field) => field.key === model.semantics?.properties?.createdAt)?.key ??
  fields.find((field) => field.type === 'Date')?.key ??
  first(fields)?.key;

export const getDefaultCohortTimeKey = (model: Model, models: Model[]) => {
  const validRelationTypes = getValidRelationTypesForOperation('addRelatedColumn');
  const userRelation = getRelationToSemanticModel(model, models, 'User', validRelationTypes);
  const accountRelation = getRelationToSemanticModel(model, models, 'Account', validRelationTypes);
  const userModel = models.find((model) => model.modelId === userRelation?.modelId);
  const accountModel = models.find((model) => model.modelId === accountRelation?.modelId);
  const userCreatedAtField = userModel?.properties.find(
    (property) => property.key === userModel.semantics?.properties?.createdAt,
  );
  const accountCreatedAtField = accountModel?.properties.find(
    (property) => property.key === accountModel.semantics?.properties?.createdAt,
  );
  const userCreatedAtFieldOptionKey =
    userRelation === null || userCreatedAtField === undefined
      ? null
      : generateRelationTimeKey(userRelation?.key, userCreatedAtField?.key);
  const accountCreatedAtFieldOptionKey =
    accountRelation === null || accountCreatedAtField === undefined
      ? null
      : generateRelationTimeKey(accountRelation?.key, accountCreatedAtField?.key);
  return (
    userCreatedAtFieldOptionKey ??
    accountCreatedAtFieldOptionKey ??
    model.properties.find((property) => property.type === 'Date')?.key ??
    first(model.properties)?.key
  );
};

export const getIdFields = (fields: Fields) => fields.filter(({ type }) => type !== 'Date');
export const getTimeKeyFields = (fields: Fields) => fields.filter(({ type }) => type === 'Date');

const RelationTimeKeyPrefix = '__';
const RelationTimeKeySeparator = '__';

export const generateRelationTimeKey = (relationKey: string, relationKeyFieldKey: string) =>
  `${RelationTimeKeyPrefix}${relationKey}${RelationTimeKeySeparator}${relationKeyFieldKey}`;

const isRelationTimeKey = (timeKey: string) => timeKey.startsWith(RelationTimeKeyPrefix);

/**
 * Split relation time key in a way that allows the relationKey to contain the separator.
 */
const splitRelationTimeKey = (value: string) => {
  const [key, ...relationKeyParts] = value
    .slice(RelationTimeKeyPrefix.length)
    .split(RelationTimeKeySeparator)
    .reverse();

  return [relationKeyParts.reverse().join(RelationTimeKeySeparator), key];
};

const getTimeKeyFieldsFromRelation = (relation: Relation, getModel: (modelId: string) => Model) => {
  const timeKeyFields = getTimeKeyFields(getModel(relation.modelId).properties);
  if (timeKeyFields.length === 0) {
    return [];
  }
  return timeKeyFields.map((field) => ({
    ...field,
    name: field.name,
    key: generateRelationTimeKey(relation.key, field.key),
  }));
};

export const getCohortPipelineState = (
  pipeline: Pipeline | undefined,
  exploration: Exploration,
  ctx: PipelineStateContext,
) => {
  if (pipeline === undefined) {
    return undefined;
  }
  const { baseModelId, operations } = dereferencePipeline(pipeline, exploration);
  const parentOperations = operations.slice(0, operations.length - pipeline.operations.length);
  return getFinalState(baseModelId, parentOperations, ctx);
};

export const getAllTimeKeyFields = (
  fields: Fields,
  pipeline: Pipeline | undefined,
  model: Model | undefined,
  getModel: (modelId: string) => Model,
): (Field | FieldGroup)[] => {
  if (pipeline === undefined || model === undefined) {
    return [];
  }

  return [
    ...getTimeKeyFields(fields),
    ...model.relations
      .filter((relation) =>
        getValidRelationTypesForOperation('addRelatedColumn').includes(relation.type),
      )
      .map((relation) => ({
        name: `on ${relation.name}`,
        fields: getTimeKeyFieldsFromRelation(relation, getModel),
      })),
  ];
};

export const buildOptions = (
  idFields: Fields,
  cohortTimeKeyFields: (Field | FieldGroup)[],
  eventTimeKeyFields: (Field | FieldGroup)[],
  pipeline: Pipeline | undefined,
  exploration: Exploration,
  model: Model | undefined,
): EditCohortOptions | undefined => {
  if (pipeline === undefined || model === undefined) {
    return;
  }

  if ('baseModelId' in pipeline) {
    return {
      title: model.name,
      idFields: idFields.map(fieldToOption),
      cohortTimeKeyFields: cohortTimeKeyFields.map(fieldToOptionGrouped),
      eventTimeKeyFields: eventTimeKeyFields.map(fieldToOptionGrouped),
    };
  }

  if ('parentId' in pipeline) {
    const parentCell = getCellByPipelineIdOrThrow(pipeline.parentId, exploration);

    return {
      title: ('title' in parentCell ? parentCell.title : '') ?? model.name,
      idFields: idFields.map(fieldToOption),
      cohortTimeKeyFields: cohortTimeKeyFields.map(fieldToOptionGrouped),
      eventTimeKeyFields: eventTimeKeyFields.map(fieldToOptionGrouped),
    };
  }
};

export const ensureRelatedColumnsExist = (state: EditCohortState): EditCohortState => {
  if (state.pipeline === undefined) {
    return state;
  }

  const cleanState = removeUnusedTimeKeyRelatedColumns(state);

  return timeKeys.reduce((state, timeKey): EditCohortState => {
    const value = state[timeKey] ?? '';
    if (!isRelationTimeKey(value)) {
      return state;
    }

    const [relationKey, key] = splitRelationTimeKey(value);

    if (timeKeyRelatedColumnsExists(state, value)) {
      return state;
    }

    return {
      ...cleanState,
      [timeKey]: value,
      pipeline: {
        ...cleanState.pipeline,
        operations: [
          ...(cleanState.pipeline?.operations ?? []),
          {
            operation: 'addRelatedColumn',
            parameters: {
              relation: { key: relationKey }, // TODO: Provide modelId
              columns: [
                {
                  key,
                  property: {
                    key: value,
                    name: timeKey,
                  },
                },
              ],
            },
          },
        ],
      } as Pipeline,
    };
  }, cleanState);
};

const timeKeyRelatedColumnsExists = (state: EditCohortState, key: string) => {
  const existingOperation = state.pipeline?.operations.find((operation) => {
    return (
      operation.operation === 'addRelatedColumn' &&
      operation.parameters.columns.some((column) => column.property.key === key)
    );
  });

  return existingOperation !== undefined;
};

const removeUnusedTimeKeyRelatedColumns = (state: EditCohortState): EditCohortState => {
  if (state.pipeline === undefined) {
    return state;
  }

  const timeKeys = [state.cohortTimeKey, state.eventTimeKey];

  return {
    ...state,
    pipeline: {
      ...state.pipeline,
      operations: state.pipeline.operations.filter(
        (operation) =>
          operation.operation !== 'addRelatedColumn' ||
          operation.parameters.columns.some((column) => timeKeys.includes(column.property.key)),
      ),
    },
  };
};

const cohortTimeIntervalLabels: { [key in common.CohortTimeInterval]: string } = {
  year: 'Year',
  month: 'Month',
  week: 'Week',
  day: 'Day',
};

export const cohortTimeIntervalOptions = Object.entries(cohortTimeIntervalLabels).map(
  ([value, label]) => ({
    value,
    label,
  }),
);

export const isStateReady = (state: EditCohortState): state is Required<EditCohortState> =>
  state.pipeline !== undefined &&
  state.cohortId !== undefined &&
  state.cohortTimeKey !== undefined &&
  state.cohortTimeInterval !== undefined &&
  state.eventTimeKey !== undefined &&
  state.eventTimeInterval !== undefined;
