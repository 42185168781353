import { last, uniq } from 'lodash';

import type {
  AddRelatedColumnOperation,
  DereferencedPipeline,
  Exploration,
  Field,
  Fields,
  FunnelOperation,
  FunnelStep,
  Model,
  Pipeline,
  PipelineState,
  Relation,
} from '../types';
import { getCellByPipelineIdOrThrow } from '../exploration/utils';
import { dereferencePipeline } from '../pipeline/utils';
import { getFinalState, PipelineStateContext } from '../pipeline/state';
import { getJoinKeyToSemanticModel, getModelOrThrow } from '../model/utils';

const getStepModel = (step: FunnelStep, models: Model[], exploration: Exploration) =>
  getModelOrThrow(models, getDereferencedStepPipeline(step, exploration).baseModelId);

export const getStepName = (
  step: FunnelStep,
  models: Model[],
  exploration: Exploration,
): string => {
  if ('parentId' in step.pipeline) {
    const cell = getCellByPipelineIdOrThrow(step.pipeline.parentId, exploration);
    return 'title' in cell
      ? (cell.title ?? getStepModel(step, models, exploration).name)
      : getStepModel(step, models, exploration).name;
  }
  return getStepModel(step, models, exploration).name;
};

export const getDereferencedStepPipeline = (
  step: FunnelStep,
  exploration: Exploration,
): DereferencedPipeline => dereferencePipeline(step.pipeline, exploration);

export const getStepPipelineState = (
  step: FunnelStep,
  exploration: Exploration,
  ctx: PipelineStateContext,
) => {
  const { baseModelId, operations } = getDereferencedStepPipeline(step, exploration);
  const stepOperations = 'pipeline' in step ? step.pipeline.operations : [];
  const parentOperations = operations.slice(0, operations.length - stepOperations.length);
  const pipelineState = getFinalState(baseModelId, parentOperations, ctx);
  return pipelineState;
};

const RelatedFieldKeyPrefix = '__';
const RelatedFieldKeySeparator = '__';

export const generateRelatedFieldKey = (relationKey: string, relatedFieldKey: string) =>
  `${RelatedFieldKeyPrefix}${relationKey}${RelatedFieldKeySeparator}${relatedFieldKey}`;

export const isRelatedFieldKey = (key: string) => key.startsWith(RelatedFieldKeyPrefix);

/**
 * Split relation time key in a way that allows the relationKey to contain the separator.
 */
const splitRelatedFieldKey = (value: string) => {
  const [key, ...relationKeyParts] = value
    .slice(RelatedFieldKeyPrefix.length)
    .split(RelatedFieldKeySeparator)
    .reverse();

  return [relationKeyParts.reverse().join(RelatedFieldKeySeparator), key];
};

/**
 * Return the final pipeline states of the funnel steps
 */
export const getFunnelPipelineStates = (
  steps: FunnelStep[],
  exploration: Exploration,
  ctx: PipelineStateContext,
) =>
  steps
    .filter((step) => !isEmptyStep(step))
    .map((step) => getStepPipelineState(step, exploration, ctx));

/**
 * Return the intersection of pipeline fields and the fields of the intersection of related models of all steps.
 */
export const getGroupableFields = (
  steps: FunnelStep[],
  exploration: Exploration,
  ctx: PipelineStateContext,
) => {
  const pipelineStates = getFunnelPipelineStates(steps, exploration, ctx);
  const countingFields = uniq(steps.flatMap((step) => step.fields));
  const sharedFields = getSharedFields(pipelineStates);
  const sharedRelations = getSharedRelations(pipelineStates);

  const isGroupableField = (field: Field) => !countingFields.some((key) => key === field.key);

  const sharedRelatedFields = sharedRelations.map((relation) => {
    const model = getModelOrThrow(ctx.models, relation.modelId);
    return {
      label: `on ${relation.name}`,
      fields: model.properties.filter(isGroupableField).map((property) => ({
        ...property,
        name: property.name,
        key: generateRelatedFieldKey(relation.key, property.key),
      })),
    };
  });

  return [
    ...sharedFields.filter(isGroupableField),
    ...sharedRelatedFields.filter((group) => group.fields.length > 0),
  ];
};

export const getSharedFields = (pipelineStates: PipelineState[]) => {
  if (pipelineStates.length === 0) {
    return [];
  }

  const fields = pipelineStates.map((state) => state.fields);
  if (fields.length === 1) {
    return fields[0];
  }

  const [firstFields, ...restFields] = fields;

  return firstFields.reduce<Fields>((acc, field) => {
    if (restFields.every((f) => f.find(({ key }) => key === field.key))) {
      return [...acc, field];
    }

    return acc;
  }, []);
};

export const getSharedRelations = (pipelineStates: PipelineState[]) => {
  if (pipelineStates.length === 0) {
    return [];
  }

  const relations = pipelineStates.map((state) => state.model.relations);
  if (relations.length === 1) {
    return relations[0];
  }

  const [firstRelations, ...restRelations] = relations;

  const sharedRelations = firstRelations.reduce<Relation[]>((acc, relation) => {
    if (restRelations.every((r) => r.find(({ key }) => key === relation.key))) {
      return [...acc, relation];
    }

    return acc;
  }, []);

  return sharedRelations;
};

const filterAddedRelatedColumns = (operation: AddRelatedColumnOperation, keys: string[]) => ({
  ...operation,
  parameters: {
    ...operation.parameters,
    columns: operation.parameters.columns.filter((column) => keys.includes(column.property.key)),
  },
});

const removeUnusedRelatedColumns = (funnelOperation: FunnelOperation): FunnelOperation => {
  const funnelGroupKeys = funnelOperation.parameters.groups?.map((group) => group.key) ?? [];
  return {
    ...funnelOperation,
    parameters: {
      ...funnelOperation.parameters,
      steps: funnelOperation.parameters.steps.map((step) => ({
        ...step,
        pipeline: {
          ...step.pipeline,
          operations: step.pipeline.operations
            .map((operation) =>
              operation.operation !== 'addRelatedColumn'
                ? operation
                : filterAddedRelatedColumns(operation, funnelGroupKeys),
            )
            .filter(
              (operation) =>
                operation.operation !== 'addRelatedColumn' ||
                operation.parameters.columns.length > 0,
            ),
        },
      })),
    },
  };
};

const addRelatedColumnOperationExists = (
  pipeline: Pipeline,
  operation: AddRelatedColumnOperation,
) =>
  operation.parameters.columns.every((column) =>
    pipeline.operations.some(
      (operation) =>
        operation.operation === 'addRelatedColumn' &&
        operation.parameters.columns.some(
          (existingColumn) => existingColumn.property.key === column.property.key,
        ),
    ),
  );

const createAddRelatedColumnOperationsForGroupKeys = (
  groupKeys: string[],
): AddRelatedColumnOperation[] => {
  return groupKeys.filter(isRelatedFieldKey).map((groupKey) => {
    const [relationKey, key] = splitRelatedFieldKey(groupKey);
    return {
      operation: 'addRelatedColumn',
      parameters: {
        relation: { key: relationKey }, // TODO: Provide modelId
        columns: [
          {
            key,
            property: {
              key: generateRelatedFieldKey(relationKey, key),
              name: generateRelatedFieldKey(relationKey, key),
            },
          },
        ],
      },
    };
  });
};

export const ensureRelatedColumnsExist = (funnelOperation: FunnelOperation): FunnelOperation => {
  funnelOperation = removeUnusedRelatedColumns(funnelOperation);

  const addRelatedColumnOperations = createAddRelatedColumnOperationsForGroupKeys(
    funnelOperation.parameters.groups?.map((group) => group.key) ?? [],
  );

  if (addRelatedColumnOperations.length === 0) {
    return funnelOperation;
  }

  return {
    ...funnelOperation,
    parameters: {
      ...funnelOperation.parameters,
      steps: funnelOperation.parameters.steps.map((step) => {
        return {
          ...step,
          pipeline: {
            ...step.pipeline,
            operations: [
              ...step.pipeline.operations,
              ...addRelatedColumnOperations.filter(
                (operation) => !addRelatedColumnOperationExists(step.pipeline, operation),
              ),
            ],
          },
        };
      }),
    },
  };
};

const getFirstIdField = (fields: Fields) =>
  fields.find((field) => field.pk === true || field.key.endsWith('_id'));

export const getDefaultCountingFieldKey = (fields: Fields, model: Model, models: Model[]) => {
  return (
    fields.find((field) => field.key === getJoinKeyToSemanticModel(model, models, 'User'))?.key ??
    fields.find((field) => field.key === getJoinKeyToSemanticModel(model, models, 'Account'))
      ?.key ??
    getFirstIdField(fields)?.key ??
    fields[0].key
  );
};

export const getDefaultSortFieldKey = (fields: Fields, model: Model) =>
  fields.find((field) => field.key === model.semantics?.properties?.createdAt)?.key ??
  fields.find((field) => field.type === 'Date')?.key ??
  fields[0].key;

export const getEmptyFunnelStep = (): FunnelStep => ({
  sortKey: '',
  fields: [],
  pipeline: {
    baseModelId: '',
    operations: [],
  },
});

export const isEmptyStep = (step: FunnelStep) =>
  !('baseModelId' in step.pipeline && step.pipeline.baseModelId !== '') &&
  !('parentId' in step.pipeline && step.pipeline.parentId !== '');

export const getFunnelOperation = (pipeline: Pipeline) => {
  const operation = last(pipeline.operations);

  if (operation?.operation !== 'funnel') {
    throw new Error('Failed to find funnel operation');
  }

  return removeEmptyFunnelSteps(operation);
};

/**
 * Used to remove empty steps before validation so we don't constantly toggle between
 * funnels and errors while the user is building the funnel.
 */
export const removeEmptyFunnelSteps = (operation: FunnelOperation) => {
  return {
    ...operation,
    parameters: {
      ...operation.parameters,
      steps: operation.parameters.steps.filter((step) => !isEmptyStep(step)),
    },
  };
};
