import { useState } from 'react';

import type { Exploration, Fields, MetricV2, Model, Relation, VariableDefinition } from '../types';
import { DeriveFieldForm } from './edit-derive-field';
import { RelationColumnForm } from './edit-relation-columns';

import form from '../../components/form/form.module.scss';

interface NewColumnFormProps {
  fields: Fields;
  model: Model;
  exploration: Exploration;
  models: Model[];
  metrics: MetricV2[];
  setOperation(operation: any): void;
  onClose(): void;
  variables: VariableDefinition[];
  relations: Relation[];
}

export const NewColumnForm = (props: NewColumnFormProps) => {
  const modelHasRelations = props.model.relations.length > 0;
  const [isCustomFormula, setIsCustomFormula] = useState(!modelHasRelations);

  return (
    <div className={form.formHorizontal}>
      {isCustomFormula ? (
        <DeriveFieldForm
          fields={props.fields}
          setOperation={props.setOperation}
          onClose={props.onClose}
          onSwitchToRelatedModel={() => setIsCustomFormula(false)}
        />
      ) : (
        <RelationColumnForm
          fields={props.fields}
          models={props.models}
          model={props.model}
          relations={props.relations}
          exploration={props.exploration}
          onSubmit={props.setOperation}
          onClose={props.onClose}
          onSwitchToCustomFormula={() => setIsCustomFormula(true)}
          variables={props.variables}
        />
      )}
    </div>
  );
};
