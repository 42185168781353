import { first, omit } from 'lodash';
import { common } from '@gosupersimple/types';

import { Dropdown, DropdownMenuItem } from '@/components/dropdown';

import {
  DereferencedPipeline,
  Visualisation,
  VisualisationViewOptions,
  isAggregatedVisualisation,
} from '../types';
import { GroupingsEditor } from '../edit-pipeline/groupings-editor';
import { AggregationsEditor } from '../edit-pipeline/aggregations-editor';
import { Toggle } from '../../components/form/toggle';
import {
  getBigNumberFields,
  getChartType,
  getQueryForVisualisation,
  isBigNumberToggleable,
  isDateField,
  isNumericField,
  setBigNumberOptions,
  setSecondaryAxisKey,
  toggleVisualisationAggregation,
  isBigNumberWithComparison,
  getBigNumberComparisonDefaults,
  setVisualisationAggregations,
  setVisualisationValueKeys,
} from '../components/visualisation/utils';
import { Select } from '../../components/form/select';
import { getDereferencedPipelineFields } from '../pipeline/state';
import { useMetadataContext } from '../metadata-context';
import { metricV2sByModelId } from '../metrics/utils';
import { fieldToOption } from '../edit-pipeline/utils';
import { useExplorationContext } from '../exploration/exploration-context';
import { SearchInput } from '../../components/form/search-input';
import { SortingEditor } from '../edit-pipeline/sorting-editor';
import { visualisationKeysFromAggregation } from '../components/visualisation/utils';
import { IconButton, InlineButton } from '../../components/button';
import { Icon } from '../../components/icon';

import form from '../../components/form/form.module.scss';

interface EditVisualisationProps {
  visualisation: Visualisation;
  pipeline: DereferencedPipeline;
  onChange: (visualisation: Visualisation) => void;
}

export const EditVisualisation = (props: EditVisualisationProps) => {
  const { visualisation, pipeline, onChange } = props;

  const { models, metricsV2 } = useMetadataContext();
  const { getVariables } = useExplorationContext();

  const metrics = metricV2sByModelId(metricsV2, pipeline.baseModelId);
  const variables = getVariables();
  const stateContext = { models, variables, metrics: metricsV2 };
  const fields = getDereferencedPipelineFields(pipeline, stateContext);
  const isAggregated = isAggregatedVisualisation(visualisation);
  const options = visualisation.viewOptions ?? {};
  const bigNumberOptions = options.bigNumber;
  const bigNumberFieldKey = bigNumberOptions?.key;
  const sorting = bigNumberOptions?.sort;
  const bigNumberComparisonKey =
    bigNumberOptions && 'comparisonKey' in bigNumberOptions
      ? bigNumberOptions?.comparisonKey
      : undefined;
  const visualisationPipeline = getQueryForVisualisation(pipeline, fields, visualisation).pipeline;
  const visualisationFields = getDereferencedPipelineFields(visualisationPipeline, stateContext);
  const chartType = getChartType(fields, visualisation);

  const handleBigNumberToggleChange = (checked: boolean) => {
    const key = first(getBigNumberFields(visualisationFields))?.key;
    handleBigNumberChange(key !== undefined && checked ? { key } : undefined);
  };

  const handleBigNumberComparisonToggleChange = (checked: boolean) => {
    if (bigNumberOptions?.key === undefined) {
      throw new Error('Invalid big number state');
    }

    handleBigNumberChange(
      !checked
        ? omit(bigNumberOptions, 'comparisonKey')
        : {
            ...bigNumberOptions,
            ...getBigNumberComparisonDefaults(bigNumberOptions.key),
          },
    );
  };

  const handleBigNumberChange = (newOptions: VisualisationViewOptions['bigNumber']) => {
    onChange({
      ...visualisation,
      viewOptions: setBigNumberOptions({ bigNumber: newOptions }, undefined, {
        mainAxisKey: visualisation.mainAxisKey,
      }),
    });
  };

  const handleBigNumberSortingChange = (sort: common.Sorting[]) => {
    if (bigNumberOptions?.key === undefined) {
      throw new Error('Invalid big number state');
    }
    handleBigNumberChange({ ...bigNumberOptions, sort });
  };

  const handleBigNumberComparisonChange = (
    newOptions: Partial<VisualisationViewOptions['bigNumber']>,
  ) => {
    if (!isBigNumberWithComparison(options?.bigNumber)) {
      return;
    }

    const optionsToUpdate: VisualisationViewOptions['bigNumber'] = {
      ...options.bigNumber,
      ...newOptions,
    };

    return handleBigNumberChange(optionsToUpdate);
  };

  const handleRemoveValueKey = (key: string) => {
    onChange({
      ...visualisation,
      valueKeys: visualisation.valueKeys.filter((k) => k !== key),
    });
  };

  const handleSecondaryAxisChange = (key: string, checked: boolean) => {
    onChange({
      ...visualisation,
      viewOptions: setSecondaryAxisKey(key, checked, visualisation.viewOptions),
    });
  };

  return (
    <div className={form.formHorizontal}>
      <Toggle
        checked={isAggregated}
        disabled={!fields.some(isNumericField)}
        onChange={(checked) => {
          onChange(toggleVisualisationAggregation(checked, visualisation, fields));
        }}
        size="small">
        Aggregate
      </Toggle>
      {isAggregated ? (
        <>
          <AggregationsEditor
            aggregations={visualisation.aggregation.aggregations}
            setAggregations={(aggregations) => {
              onChange(setVisualisationAggregations(visualisation, aggregations, fields));
            }}
            fields={fields}
            metrics={metrics}
            autoFocus
            excludeAggregationTypes={['first', 'last', 'earliest', 'latest', 'custom']}
            getMenuOptions={(aggregation) => {
              const options: DropdownMenuItem[] = [];
              if (chartType === 'grouped-timeseries') {
                options.push({
                  type: 'checkbox' as const,
                  label: 'Use Secondary axis',
                  checked: visualisation.viewOptions?.axes?.right?.keys?.includes(
                    aggregation.property.key,
                  ),
                  onChange: (isChecked) =>
                    handleSecondaryAxisChange(aggregation.property.key, isChecked),
                });
              }
              return options;
            }}
          />
          <hr className={form.dashed} />
          <GroupingsEditor
            fields={fields}
            autoFocus
            groups={visualisation.aggregation.groups}
            setGroups={(groups) => {
              const aggregation = {
                ...visualisation.aggregation,
                groups: groups,
              };
              onChange({
                ...visualisation,
                aggregation,
                ...visualisationKeysFromAggregation(aggregation),
              });
            }}
            preferDateGrouping={visualisation.aggregation.groups.length === 0}
          />
        </>
      ) : (
        <>
          {visualisation.valueKeys.length > 0 &&
            visualisation.valueKeys.map((valueKey, idx) => (
              <div key={idx} className={form.formRow}>
                <div className={form.formLabel}>Field</div>
                <SearchInput
                  options={fields.filter(isNumericField).map(fieldToOption)}
                  value={valueKey}
                  onChange={(key) => {
                    const valueKeys = visualisation.valueKeys.map((valueKey, i) =>
                      i === idx ? key : valueKey,
                    );
                    onChange(setVisualisationValueKeys(visualisation, valueKeys));
                  }}
                />
                {chartType === 'grouped-timeseries' ? (
                  <Dropdown
                    align="right"
                    trigger={(isOpen, setIsOpen) => (
                      <IconButton
                        icon="MoreHorizontal"
                        size="small"
                        title="More..."
                        type="gray"
                        onClick={() => setIsOpen(!isOpen)}
                      />
                    )}
                    items={[
                      {
                        type: 'checkbox' as const,
                        label: 'Use Secondary axis',
                        checked: visualisation.viewOptions?.axes?.right?.keys?.includes(valueKey),
                        onChange: (isChecked) => handleSecondaryAxisChange(valueKey, isChecked),
                      },
                      ...(visualisation.valueKeys.length > 1
                        ? [
                            {
                              label: 'Delete',
                              onClick: () => handleRemoveValueKey(valueKey),
                              icon: <Icon name="Trash2" size={16} />,
                            },
                          ]
                        : []),
                    ]}
                  />
                ) : (
                  visualisation.valueKeys.length > 1 && (
                    <IconButton
                      icon="Trash2"
                      title="Remove field"
                      size="small"
                      onClick={() => handleRemoveValueKey(valueKey)}
                    />
                  )
                )}
              </div>
            ))}

          {fields.some(
            (field) => !visualisation.valueKeys.includes(field.key) && isNumericField(field),
          ) ? (
            <div>
              <InlineButton
                size="small"
                onClick={() => {
                  const field = fields
                    .filter((field) =>
                      visualisation.valueKeys.every((valueKey) => valueKey !== field.key),
                    )
                    .find(isNumericField);

                  if (field === undefined) {
                    return;
                  }

                  onChange({
                    ...visualisation,
                    valueKeys: [...visualisation.valueKeys, field.key],
                  });
                }}>
                <Icon name="Plus" size={15} /> Add field
              </InlineButton>
            </div>
          ) : null}

          {visualisation.mainAxisKey !== undefined ? (
            <div className={form.formRow}>
              <div className={form.formLabel}>Main axis</div>
              <SearchInput
                options={fields.map(fieldToOption)}
                value={visualisation.mainAxisKey}
                onChange={(key) => {
                  onChange({
                    ...visualisation,
                    mainAxisKey: key,
                  });
                }}
              />
              <IconButton
                icon="Trash2"
                title="Remove main axis"
                size="small"
                onClick={() => {
                  onChange({
                    ...visualisation,
                    valueKeys: [],
                    mainAxisKey: undefined,
                  });
                }}
              />
            </div>
          ) : null}
          <div className={form.formRow}>
            {visualisation.mainAxisKey === undefined ? (
              <InlineButton
                size="small"
                onClick={() => {
                  onChange({
                    ...visualisation,
                    mainAxisKey: (fields.find(isDateField) ?? fields[0])?.key,
                  });
                }}>
                <Icon name="Plus" size={15} /> Add main axis
              </InlineButton>
            ) : null}
          </div>
        </>
      )}
      <hr className={form.dashed} />
      <Toggle
        checked={bigNumberFieldKey !== undefined}
        disabled={!isBigNumberToggleable(visualisation, visualisationFields)}
        onChange={handleBigNumberToggleChange}
        size="small">
        Show first row as big number
      </Toggle>
      {bigNumberFieldKey !== undefined ? (
        <div className={form.formRow}>
          {sorting !== undefined && <label className={form.formLabel}>Show</label>}
          <Select
            value={bigNumberFieldKey}
            options={getBigNumberFields(visualisationFields).map(fieldToOption)}
            onChange={(key) => handleBigNumberChange({ key })}
          />
        </div>
      ) : null}
      {sorting !== undefined && (
        <div className={form.formRow}>
          <label className={form.formLabel}>sorted by</label>
          <SortingEditor
            fields={fields}
            sorting={sorting ?? []}
            setSorting={(sort) => {
              handleBigNumberSortingChange(sort);
            }}
          />
        </div>
      )}

      {bigNumberFieldKey !== undefined ? (
        <div className={form.formHorizontal}>
          <Toggle
            checked={bigNumberComparisonKey !== undefined}
            onChange={handleBigNumberComparisonToggleChange}
            size="small">
            Compare with
          </Toggle>
          {isBigNumberWithComparison(options?.bigNumber) ? (
            <>
              <Select
                value={options.bigNumber.comparisonKey}
                options={[
                  {
                    label: 'Second row of the same column',
                    value: bigNumberFieldKey,
                  },
                  ...getBigNumberFields(visualisationFields)
                    .map(fieldToOption)
                    .filter((field) => field.value !== bigNumberFieldKey),
                ]}
                onChange={(key) => handleBigNumberComparisonChange({ comparisonKey: key })}
              />
              <Toggle
                checked={options.bigNumber.comparisonDirection === 'lower'}
                onChange={(state) =>
                  handleBigNumberComparisonChange({
                    comparisonDirection: state ? 'lower' : 'higher',
                  })
                }
                size="small">
                Lower value is better
              </Toggle>
              <Toggle
                checked={options.bigNumber?.comparisonType === 'percentage'}
                onChange={(state) =>
                  handleBigNumberComparisonChange({
                    comparisonType: state ? 'percentage' : 'absolute',
                  })
                }
                size="small">
                Display comparison as percentage
              </Toggle>
              <Toggle
                checked={options.bigNumber.showComparisonLabel ?? false}
                onChange={(state) =>
                  handleBigNumberComparisonChange({ showComparisonLabel: state })
                }
                size="small">
                Display comparison field label
              </Toggle>
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
