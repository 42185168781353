import { useEffect, useRef, useState } from 'react';

export const useScrollPosition = <T extends HTMLElement>() => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollContainerRef = useRef<T>(null);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer === null) {
      return;
    }
    const onScroll = () => {
      setScrollPosition(scrollContainer.scrollTop);
    };
    scrollContainer.addEventListener('scroll', onScroll);
    return () => scrollContainer.removeEventListener('scroll', onScroll);
  }, [scrollContainerRef]);

  return [scrollPosition, scrollContainerRef] as const;
};
