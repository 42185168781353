import { ReactNode, useRef } from 'react';
import { createPortal } from 'react-dom';

import { ErrorBoundary, GenericFallback } from '@/lib/error';
import { useKeyPress } from '@/lib/hooks/use-key-press';

import { useOverlayContainer } from '../overlay/context';
import { Panel } from '../panel';
import { Button } from '../button';

import styles from './modal.module.scss';

interface ModalProps {
  onClose: () => void;
  children: ReactNode;
  closeOnEsc?: boolean;
  closeOnClickAway?: boolean;
}

export const Modal = (props: ModalProps) => {
  const overlayContainer = useOverlayContainer();
  const shouldCloseOnMouseUp = useRef(false);

  useKeyPress('Escape', () => props.closeOnEsc === true && props.onClose());

  const onMouseDownContent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    shouldCloseOnMouseUp.current = false; // Prevent dragging out from closing the modal
  };
  const onMouseUpContent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation(); // Prevent dragging in from closing the modal
  };

  const onMouseDownOverlay = () => {
    shouldCloseOnMouseUp.current = true;
  };

  const onMouseUpOverlay = () => {
    if (props.closeOnClickAway === true && shouldCloseOnMouseUp.current === true) {
      props.onClose();
    }
  };

  if (overlayContainer === null) {
    return null;
  }

  const errorFallback = (
    <Panel className={styles.errorPanel}>
      <GenericFallback />
      <Button type="outlined" onClick={props.onClose}>
        Click here to close this dialog
      </Button>
    </Panel>
  );

  return createPortal(
    <div className={styles.overlay} onMouseUp={onMouseUpOverlay} onMouseDown={onMouseDownOverlay}>
      <div
        className={styles.modal}
        onMouseDown={onMouseDownContent}
        onMouseUp={onMouseUpContent}
        aria-modal>
        <ErrorBoundary fallback={errorFallback}>{props.children}</ErrorBoundary>
      </div>
    </div>,
    overlayContainer,
  );
};
