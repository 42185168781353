import { useDeleteExplorationMutation, useUpsertExplorationMutation } from '@/graphql';
import { useSelectedAccount } from '@/lib/accounts/context';

import { exportExploration } from '../output';
import { Exploration } from '../types';

export const useExplorationUpsert = () => {
  const account = useSelectedAccount();
  const [mutation, { loading, error }] = useUpsertExplorationMutation();

  const upsertExploration = async (exploration: Exploration) => {
    const explorationInput = exportExploration(exploration);

    await mutation({
      variables: { accountId: account.accountId, input: explorationInput },
      refetchQueries: ['Explorations', 'Navigation'],
    });

    if (error !== undefined) {
      throw new Error(error.message);
    }
  };

  return { upsertExploration, loading, error };
};

export const useExplorationDelete = () => {
  const account = useSelectedAccount();
  const [mutation, { loading, error }] = useDeleteExplorationMutation();

  const deleteExploration = async (explorationId: string) => {
    await mutation({
      variables: { accountId: account.accountId, explorationId },
      refetchQueries: ['Explorations'],
    });

    if (error !== undefined) {
      throw new Error(error.message);
    }
  };

  return { deleteExploration, loading, error };
};
