import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { ErrorBanner } from '@/components/banner';

import styles from './generic-fallback.module.scss';

interface GenericFallbackProps {
  fullscreen?: boolean;
  resetError?: () => void;
  resetOnNavigate?: boolean;
}

export const GenericFallback = (props: GenericFallbackProps) => {
  const { resetOnNavigate, resetError } = props;
  const location = useLocation();
  const lastLocation = useRef(location);

  useEffect(() => {
    if (resetOnNavigate === true && lastLocation.current !== location) {
      resetError?.();
    }
    lastLocation.current = location;
  }, [location, resetError, resetOnNavigate]);

  return (
    <div className={classNames({ [styles.fullscreen]: props.fullscreen })}>
      <ErrorBanner
        actions={resetError !== undefined ? [{ label: 'Try Again', onClick: resetError }] : []}
      />
    </div>
  );
};
